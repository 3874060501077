import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Table } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { getInventoryStock } from '../../Rest/api-inventori'; // Adjust the import path
import { useNavigate } from 'react-router-dom';
import './InventoryPage.css'; // Import custom CSS for styling

// Fungsi untuk mengelompokkan data berdasarkan gudang dan produk
const groupByWarehouseAndProduct = (inventories) => {
  return inventories.reduce((acc, inventory) => {
    const warehouseName = inventory?.warehouse?.name;
    const productName = inventory?.variant?.product?.name;

    if (!warehouseName || !productName) {
      return acc;
    }

    if (!acc[warehouseName]) {
      acc[warehouseName] = {};
    }

    if (!acc[warehouseName][productName]) {
      acc[warehouseName][productName] = [];
    }

    acc[warehouseName][productName].push(inventory);
    return acc;
  }, {});
};

const InventoryPage = () => {
  const navigate = useNavigate();
  const [inventories, setInventories] = useState([]);

  const goToAddInventory = () => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const layoutURL = urlParts[urlParts.length - 1];
    navigate(`${process.env.PUBLIC_URL}/inventory/add/${layoutURL}`);
  };

  const goToKurangInventory = () => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const layoutURL = urlParts[urlParts.length - 1];
    navigate(`${process.env.PUBLIC_URL}/inventory/reduce/${layoutURL}`);
  };

  const goToFlowInventory = () => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const layoutURL = urlParts[urlParts.length - 1];
    navigate(`${process.env.PUBLIC_URL}/inventory/flow/${layoutURL}`);
  };

  // Fetch inventories on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getInventoryStock(); 
        setInventories(data);
      } catch (error) {
        console.error('Error fetching inventories:', error);
      }
    };

    fetchData();
  }, []);

  // Group data by warehouse and product
  const groupedData = groupByWarehouseAndProduct(inventories);

  return (
    <Fragment>
      {/* Breadcrumbs */}
      <Breadcrumbs mainTitle="Inventory" parent="Management" title="Inventory Page" />

      <Container fluid={true}>
      <Row className="mb-3">
        <Col className="d-flex justify-content-between">
        <div>
            <Button className="custom-btn tambah-inventory mr-3" onClick={goToAddInventory}>
            Tambah Inventori
            </Button>
            <Button className="custom-btn kurang-inventory mr-3" onClick={goToKurangInventory}>
            Kurangi Inventori
            </Button>
        </div>
        <div className="ml-auto">
            <Button className="custom-btn mutasi-inventory" onClick={goToFlowInventory}>
            Mutasi Stok
            </Button>
        </div>
        </Col>
    </Row>




        {/* Inventory Cards */}
        {Object.keys(groupedData).map((warehouseName, warehouseIndex) => (
          <Row key={warehouseIndex}>
            <Col sm="12">
              <Card className="mb-2">
                <CardBody className="p-3">
                  <h6 className="font-weight-bold">{warehouseName} Warehouse</h6>

                  {Object.keys(groupedData[warehouseName]).map((productName, productIndex) => (
                    <div key={productIndex} className="mb-4 product-group">
                      <h6 className="product-title">
                        <strong>{productName}</strong>
                        <span className="text-muted"> </span>
                      </h6>

                      <Table responsive className="mb-0">
                        <thead>
                          <tr>
                            <th>Variant</th>
                            <th className="text-center">Tersedia</th>
                            <th className="text-center">Diproses</th>
                            <th className="text-center">RTS</th>
                            <th className="text-center">Rejected</th>
                            <th className="text-center">Total Gudang</th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupedData[warehouseName][productName].map((inventory, index) => (
                            <tr key={index}>
                              <td>{inventory?.variant?.option1_value}</td>
                              <td className="text-center">{inventory?.available_qty}</td>
                              <td className="text-center">{inventory?.processed_qty}</td>
                              <td className="text-center">{inventory?.rts_qty}</td>
                              <td className="text-center">{inventory?.rejected_qty}</td>
                              <td className="text-center">{inventory?.total_qty}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        ))}
      </Container>
    </Fragment>
  );
};

export default InventoryPage;
