import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const ImageDetailModal = ({ isOpen, toggle, imageSrc }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
        <img 
          src={imageSrc} 
          alt="Product Detail" 
          style={{ 
            width: '100%', 
            maxHeight: '100vh', 
            objectFit: 'contain' 
          }} 
        />
      </ModalBody>
    </Modal>
  );
};

export default ImageDetailModal;
