import React, { useState } from 'react';
import { Container, Row, Col, Input, FormGroup, Label, Button } from 'reactstrap';

const SearchAndFilter = ({ onSearch, onFilter, categories, locations, toggleConfig }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    onSearch(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    onFilter(e.target.value, selectedLocation);
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
    onFilter(selectedCategory, e.target.value);
  };

  return (
    <Container fluid={true}>
      <Row className="d-flex align-items-end">
        <Col md={3}>
          <FormGroup>
            <Label for="search">Search by Name or SKU</Label>
            <Input
              type="text"
              name="search"
              id="search"
              placeholder="Enter product name or SKU"
              value={searchText}
              onChange={handleSearchChange}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="category">Category</Label>
            <Input
              type="select"
              name="category"
              id="category"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="location">Location</Label>
            <Input
              type="select"
              name="location"
              id="location"
              value={selectedLocation}
              onChange={handleLocationChange}
            >
              <option value="">All Locations</option>
              {locations.map((location) => (
                <option key={location.id} value={location.name}>
                  {location.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={3} className="d-flex align-items-end" style={{ marginBottom: '17px' }}>
          <Button color="primary" onClick={toggleConfig} className="w-100">Configure Columns</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchAndFilter;
