import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
import { getMenuItems, createMenuItem, updateMenuItem, deleteMenuItem, getMenuChildren, createMenuChild, updateMenuChild, deleteMenuChild } from '../../Rest/api-menu';

const MenuManager = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [menuChildren, setMenuChildren] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [selectedMenuChild, setSelectedMenuChild] = useState(null);
  const [formData, setFormData] = useState({ title: '', icon: '', type: 'sub', badge: '', badgetxt: '', active: true, parent_id: null, menu_order: 0 });
  const [childFormData, setChildFormData] = useState({ path: '', title: '', type: 'link', child_order: 0 });

  useEffect(() => {
    fetchMenuItems();
  }, []);

  const fetchMenuItems = async () => {
    try {
      const data = await getMenuItems();
      setMenuItems(data);
    } catch (error) {
      console.error('Failed to fetch menu items:', error);
    }
  };

  const fetchMenuChildren = async (menuItemId) => {
    try {
      const data = await getMenuChildren(menuItemId);
      setMenuChildren(data);
    } catch (error) {
      console.error('Failed to fetch menu children:', error);
    }
  };

  const handleMenuItemSelect = (menuItem) => {
    setSelectedMenuItem(menuItem);
    fetchMenuChildren(menuItem.id);
  };

  const handleMenuChildSelect = (child) => {
    setSelectedMenuChild(child);
    setChildFormData({ path: child.path, title: child.title, type: child.type, child_order: child.child_order });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChildInputChange = (e) => {
    setChildFormData({ ...childFormData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedMenuItem) {
        await updateMenuItem(selectedMenuItem.id, formData);
      } else {
        await createMenuItem(formData);
      }
      fetchMenuItems();
      setFormData({ title: '', icon: '', type: 'sub', badge: '', badgetxt: '', active: true, parent_id: null, menu_order: 0 });
      setSelectedMenuItem(null);
    } catch (error) {
      console.error('Failed to save menu item:', error);
    }
  };

  const handleChildSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedMenuChild) {
        await updateMenuChild(selectedMenuItem.id, selectedMenuChild.id, childFormData);
      } else {
        await createMenuChild(selectedMenuItem.id, childFormData);
      }
      fetchMenuChildren(selectedMenuItem.id);
      setChildFormData({ path: '', title: '', type: 'link', child_order: 0 });
      setSelectedMenuChild(null);
    } catch (error) {
      console.error('Failed to save menu child:', error);
    }
  };

  const handleDelete = async (menuItemId) => {
    try {
      await deleteMenuItem(menuItemId);
      fetchMenuItems();
    } catch (error) {
      console.error('Failed to delete menu item:', error);
    }
  };

  const handleDeleteChild = async (childId) => {
    try {
      await deleteMenuChild(selectedMenuItem.id, childId);
      fetchMenuChildren(selectedMenuItem.id);
    } catch (error) {
      console.error('Failed to delete menu child:', error);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Manage Menu" parent="Pages" title="Manage Menu" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Manage Menu Items and Children" span1="Create, update, and delete menu items and their children" />
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="title">Title</Label>
                    <Input type="text" name="title" id="title" value={formData.title} onChange={handleInputChange} required />
                  </FormGroup>
                  <FormGroup>
                    <Label for="icon">Icon</Label>
                    <Input type="text" name="icon" id="icon" value={formData.icon} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="type">Type</Label>
                    <Input type="select" name="type" id="type" value={formData.type} onChange={handleInputChange}>
                      <option value="sub">Sub</option>
                      <option value="link">Link</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="badge">Badge</Label>
                    <Input type="text" name="badge" id="badge" value={formData.badge} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="badgetxt">Badge Text</Label>
                    <Input type="text" name="badgetxt" id="badgetxt" value={formData.badgetxt} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="menu_order">Order</Label>
                    <Input type="number" name="menu_order" id="menu_order" value={formData.menu_order} onChange={handleInputChange} required />
                  </FormGroup>
                  <Button type="submit" color="primary">Save Menu Item</Button>
                </Form>

                <h5 className="mt-5">Menu Items</h5>
                <Table striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menuItems.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td>{item.type}</td>
                        <td>
                          <Button color="info" size="sm" onClick={() => handleMenuItemSelect(item)}>Edit</Button>{' '}
                          <Button color="danger" size="sm" onClick={() => handleDelete(item.id)}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {selectedMenuItem && (
                  <div>
                    <h5 className="mt-5">Children of {selectedMenuItem.title}</h5>
                    <Form onSubmit={handleChildSubmit}>
                      <FormGroup>
                        <Label for="path">Path</Label>
                        <Input type="text" name="path" id="path" value={childFormData.path} onChange={handleChildInputChange} required />
                      </FormGroup>
                      <FormGroup>
                        <Label for="child_title">Title</Label>
                        <Input type="text" name="title" id="child_title" value={childFormData.title} onChange={handleChildInputChange} required />
                      </FormGroup>
                      <FormGroup>
                        <Label for="child_type">Type</Label>
                        <Input type="select" name="type" id="child_type" value={childFormData.type} onChange={handleChildInputChange}>
                          <option value="link">Link</option>
                          <option value="sub">Sub</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="child_order">Order</Label>
                        <Input type="number" name="child_order" id="child_order" value={childFormData.child_order} onChange={handleChildInputChange} required />
                      </FormGroup>
                      <Button type="submit" color="primary">Save Menu Child</Button>
                    </Form>

                    <Table striped className="mt-4">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Title</th>
                          <th>Type</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {menuChildren.map((child, index) => (
                          <tr key={child.id}>
                            <td>{index + 1}</td>
                            <td>{child.title}</td>
                            <td>{child.type}</td>
                            <td>
                              <Button color="info" size="sm" onClick={() => handleMenuChildSelect(child)}>Edit</Button>{' '}
                              <Button color="danger" size="sm" onClick={() => handleDeleteChild(child.id)}>Delete</Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MenuManager;
