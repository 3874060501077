import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardBody, Col, Row, Input, Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, ModalHeader } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { Breadcrumbs } from '../../AbstractElements'; // Ensure the import path is correct
import SidebarMenu from './SidebarMenu'; // Import the SidebarMenu component
import { getPengguna, updateUser, uploadImage } from '../../Rest/api-pengguna'; // API calls

const Members = () => {
  const [pengguna, setPengguna] = useState([]);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false); // State for delete confirmation modal
  const [selectedUser, setSelectedUser] = useState(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('USER');
  const [profileImage, setProfileImage] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to fetch all users
  const fetchPengguna = async () => {
    const response = await getPengguna();
    if (response.status === 'success') {
      const activeUsers = response.data.filter((user) => user.active === true);
      setPengguna(activeUsers);
    }
  };

  // Fetch users when the component mounts
  useEffect(() => {
    fetchPengguna();
  }, []);

  const toggle = () => setModal(!modal);

  const toggleDeleteModal = () => setDeleteModal(!deleteModal); // Toggle delete modal

  const handleEdit = (user) => {
    if (!user || !user.id) {
      console.error('Invalid user selected for editing.');
      return;
    }

    setSelectedUser(user);
    setFullName(user.full_name);
    setEmail(user.email);
    setPhoneNumber(user.phone_number);
    setRole(user.role);
    setProfileImage(user.profile_image);
    toggle();
  };

  const handleDelete = (user) => {
    setSelectedUser(user); // Set the selected user for deletion
    toggleDeleteModal(); // Open the delete modal
  };

  const confirmDelete = async () => {
    if (selectedUser) {
      console.log(`Deleting user: ${selectedUser.full_name}`);
      // Add the API call for deleting user here
      // await deleteUser(selectedUser.id);

      toggleDeleteModal(); // Close the modal after deletion
      setSelectedUser(null); // Clear the selected user after deletion
      fetchPengguna(); // Refetch the user list
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let profileImageUrl = profileImage;

      // Check if the user has uploaded a new image
      if (file) {
        const formData = new FormData();
        formData.append('file', file);  // Append the actual file

        // Upload the image to the server or Cloudinary
        const uploadResponse = await uploadImage(formData);
        profileImageUrl = uploadResponse.photoUrl; // Ensure this is correct based on your backend response
      }

      if (!selectedUser || !selectedUser.id) {
        console.error('No user selected for update.');
        return;
      }

      // Prepare the updated user payload
      const updatedUser = {
        id: selectedUser.id,
        full_name: fullName,
        email: email,
        phone_number: phoneNumber,
        role: role,
        active: selectedUser.active, // Retain the existing active status
        profile_image: profileImageUrl, // Use the correct image URL
      };

      // Call the updateUser function to send the updated data to the server
      await updateUser(updatedUser);

      // After successfully updating the user, refetch the updated user list
      await fetchPengguna();

      // Reset state and close the modal
      setSelectedUser(null);
      toggle();
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      setLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(Object.assign(selectedFile, { preview: URL.createObjectURL(selectedFile) })); // Use createObjectURL for preview
    setProfileImage(URL.createObjectURL(selectedFile)); // Also set the profileImage for immediate display
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  const removeFile = () => {
    setFile(null);
    setProfileImage(null);
  };

  return (
    <Row>
      {/* Breadcrumbs */}
      <Col sm="12">
        <Breadcrumbs mainTitle="Pengaturan Anggota" parent="Settings" title="Members" />
      </Col>

      {/* Sidebar */}
      <Col sm="3">
        <SidebarMenu />
      </Col>

      {/* Main Content */}
      <Col sm="9">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Button color="primary">Daftar Anggota</Button>
              <Button color="link">+ Tambah Anggota</Button>
            </div>

            <div className="d-flex align-items-center mb-3">
              <Input type="search" placeholder="Cari anggota..." />
            </div>

            {/* Loop through active users */}
            {pengguna && pengguna.length > 0 ? (
              pengguna.map((user) => (
                <div
                  key={user.id}
                  className="d-flex align-items-center p-3 mb-2"
                  style={{ backgroundColor: '#f8f9fa', borderRadius: '5px' }}
                >
                  {/* Profile Image */}
                  <div
                    className="avatar rounded-circle text-center mr-3"
                    style={{
                      width: '50px',
                      height: '50px',
                      backgroundImage: `url(${user.profile_image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '20px',
                    }}
                  >
                    {!user.profile_image && (
                      <span style={{ fontSize: '1.5rem', color: '#fff' }}>
                        {user.full_name ? user.full_name.charAt(0) : 'U'}
                      </span>
                    )}
                  </div>

                  {/* User Details */}
                  <div className="flex-grow-1" style={{ marginLeft: '20px' }}>
                    <h6 className="mb-0">
                      {user.full_name}
                      <span
                        className={`badge ml-2 ${user.role === 'ADMIN' ? 'badge-primary' : 'badge-secondary'}`}
                        style={{ fontSize: '0.75rem' }} // Make the role badge smaller
                      >
                        {user.role}
                      </span>
                    </h6>
                    <small>{user.email}</small>
                  </div>

                  {/* Edit Icon */}
                  <div className="ml-3">
                    <span
                      style={{ cursor: 'pointer', marginRight: '15px', fontSize: '1.2rem' }}
                      onClick={() => handleEdit(user)}
                    >
                      ✏️
                    </span>
                  </div>

                  {/* Delete Icon */}
                  <div className="ml-2">
                    <span
                      style={{ cursor: 'pointer', marginRight: '15px', fontSize: '1.2rem', color: 'red' }}
                      onClick={() => handleDelete(user)}
                    >
                      🗑️
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p>No users found.</p>
            )}
          </CardBody>
        </Card>
      </Col>

      {/* Edit User Modal */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Form onSubmit={handleUpdateUser}>
            <FormGroup>
              <Label for="fullName">Full Name</Label>
              <Input
                type="text"
                name="fullName"
                id="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Enter full name"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="phoneNumber">Phone Number</Label>
              <Input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter phone number"
              />
            </FormGroup>
            <FormGroup>
              <Label for="role">Role</Label>
              <Input
                type="select"
                name="role"
                id="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="USER">USER</option>
                <option value="ADMIN">ADMIN</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="profileImage">Profile Image</Label>
              <Card style={{ width: '100%', height: '170px', position: 'relative' }}>
                <CardBody style={{ padding: 0 }}>
                  {!file && !profileImage && (
                    <div
                      {...getRootProps({ className: 'dropzone' })}
                      style={{
                        border: '2px dashed #007bff',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop an image here, or click to select an image</p>
                    </div>
                  )}
                  {(file || profileImage) && (
                    <div style={{ textAlign: 'center', height: '100%', position: 'relative' }}>
                      <img
                        src={file ? file.preview : profileImage} // Ensure the file preview is used
                        alt="Profile"
                        style={{ width: '170px', height: '170px', objectFit: 'cover' }}
                      />
                      <Button
                        color="danger"
                        size="sm"
                        onClick={removeFile}
                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                      >
                        &times;
                      </Button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </FormGroup>
            <ModalFooter>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? 'Updating...' : 'Update User'}
              </Button>{' '}
              <Button
                color="secondary"
                onClick={() => {
                  toggle();
                  setFile(null);
                  setProfileImage(selectedUser ? selectedUser.profile_image : null);
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the user <strong>{selectedUser?.full_name}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmDelete}>
            Delete
          </Button>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default Members;
