import React, { useState } from 'react';
import { Card, CardBody, Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements'; // Make sure this import path is correct
import SidebarMenu from './SidebarMenu'; // Import the SidebarMenu component

const Business = () => {
  const [modal, setModal] = useState(false); // State to handle modal visibility
  const [businessName, setBusinessName] = useState('Bisnis Bayu Pebrianto'); // State to handle the business name

  // Function to toggle the modal visibility
  const toggleModal = () => setModal(!modal);

  // Function to handle input change
  const handleInputChange = (event) => setBusinessName(event.target.value);

  // Function to handle the "Edit" link click and prevent default behavior
  const handleEditClick = (event) => {
    event.preventDefault(); // Prevent the default anchor click behavior
    toggleModal(); // Toggle the modal visibility
  };

  return (
    <Row>
      {/* Breadcrumbs */}
      <Col sm="12">
        <Breadcrumbs mainTitle="Bisnis Saya" parent="Settings" title="Business" />
      </Col>

      {/* Sidebar */}
      <Col sm="3">
        <SidebarMenu /> {/* Use the SidebarMenu component */}
      </Col>

      {/* Main Content */}
      <Col sm="9">
        <Card>
          <CardBody>
            <h4>
              Bisnis Saya <a href="#" className="edit-link" onClick={handleEditClick}>Edit</a>
            </h4>
            <hr />
            <h5>Nama Bisnis</h5>
            <p>{businessName}</p> {/* Display the business name */}
            <h5>Username</h5>
            <p>bayu-pebrianto</p>
            <hr />
          </CardBody>
        </Card>
      </Col>

      {/* Modal for Editing Business Name */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Bisnis</ModalHeader>
        <ModalBody>
          <Label for="businessName">Nama Bisnis</Label>
          <Input
            id="businessName"
            type="text"
            value={businessName}
            onChange={handleInputChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Batal</Button>
          <Button color="primary" onClick={toggleModal}>Simpan</Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default Business;
