export const DataOrderan = {
  productColumns: [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: true,
    },
    {
      name: 'ORDER',
      selector: (row) => row.order,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'Order Number',
      selector: (row) => row.numberOrder,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'Product Name',
      selector: (row) => row.productName,
      sortable: true,
      center: true,
      grow: 2,
      wrap: true,
      visible: true,
    },
    {
      name: 'NAME',
      selector: (row) => `${row.nama}, ${row.nomer}`,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: true,
    },
    {
      name: 'Nama',
      selector: (row) => row.nama,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'Phone Number',
      selector: (row) => row.nomer,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'Address',
      selector: (row) => row.alamat,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'Sub District',
      selector: (row) => row.kecamatan,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'CITY',
      selector: (row) => row.kabupaten,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: true,
    },
    {
      name: 'Province',
      selector: (row) => row.provinsi,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'STATUS',
      selector: (row) => row.status,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: true,
    },
    {
      name: 'Payment Method',
      selector: (row) => row.paymentMethod,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'Courier',
      selector: (row) => row.courier,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'Tracking Number',
      selector: (row) => row.resi,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'NOTES',
      selector: (row) => row.notes,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: true,
    },
    {
      name: 'DATE',
      selector: (row) => row.date,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: true,
    },
    {
      name: 'Shipping Cost',
      selector: (row) => row.ongkir,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'Product Price',
      selector: (row) => row.harga,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: false,
    },
    {
      name: 'GROSS REVENUE',
      selector: (row) => row.gross,
      sortable: true,
      center: true,
      grow: 1,
      wrap: true,
      visible: true,
    },
    {
      name: 'ACTION',
      selector: (row) => row.action,
      sortable: false,
      center: true,
      grow: 1,
      visible: true,
    },
  ],
};
