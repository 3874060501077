import React, { useState, useCallback, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { useDropzone } from 'react-dropzone';

const StatusEditModal = ({ isOpen, toggle, onSave, currentStatus, currentKurir, currentResi, currentBuktiPengiriman, currentBuktiPenerimaan }) => {
  const [status, setStatus] = useState(currentStatus);
  const [kurir, setKurir] = useState('');
  const [resi, setResi] = useState('');
  const [buktiPengiriman, setBuktiPengiriman] = useState(null);
  const [buktiPenerimaan, setBuktiPenerimaan] = useState(null);

  useEffect(() => {
    setStatus(currentStatus);
    setKurir(currentKurir || '');
    setResi(currentResi || '');
    setBuktiPengiriman(currentBuktiPengiriman ? { file: null, preview: currentBuktiPengiriman } : null);
    setBuktiPenerimaan(currentBuktiPenerimaan ? { file: null, preview: currentBuktiPenerimaan } : null);
  }, [currentStatus, currentKurir, currentResi, currentBuktiPengiriman, currentBuktiPenerimaan]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleKurirChange = (e) => {
    setKurir(e.target.value);
  };

  const handleResiChange = (e) => {
    setResi(e.target.value);
  };

  const onDropPengiriman = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setBuktiPengiriman({
      file,
      preview: URL.createObjectURL(file)
    });
  }, []);

  const onDropPenerimaan = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setBuktiPenerimaan({
      file,
      preview: URL.createObjectURL(file)
    });
  }, []);

  const removeFilePengiriman = () => {
    setBuktiPengiriman(null);
  };

  const removeFilePenerimaan = () => {
    setBuktiPenerimaan(null);
  };

  const { getRootProps: getRootPropsPengiriman, getInputProps: getInputPropsPengiriman } = useDropzone({
    onDrop: onDropPengiriman,
    accept: 'image/*',
    maxFiles: 1
  });

  const { getRootProps: getRootPropsPenerimaan, getInputProps: getInputPropsPenerimaan } = useDropzone({
    onDrop: onDropPenerimaan,
    accept: 'image/*',
    maxFiles: 1
  });

  const handleSave = () => {
    const data = {
      status,
      kurir,
      resi,
      buktiPengiriman: buktiPengiriman?.file,
      buktiPenerimaan: buktiPenerimaan?.file
    };
    onSave(data);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Status</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="statusSelect">Status</Label>
            <Input type="select" name="status" id="statusSelect" value={status} onChange={handleStatusChange}>
              <option value="PROSES">PROSES</option>
              <option value="DIKIRIM">DIKIRIM</option>
              <option value="SELESAI">SELESAI</option>
            </Input>
          </FormGroup>
          {status === 'DIKIRIM' && (
            <>
              <FormGroup>
                <Label for="kurir">Kurir</Label>
                <Input type="text" name="kurir" id="kurir" value={kurir} onChange={handleKurirChange} />
              </FormGroup>
              <FormGroup>
                <Label for="resi">Resi</Label>
                <Input type="text" name="resi" id="resi" value={resi} onChange={handleResiChange} />
              </FormGroup>
              <FormGroup>
                <Label for="buktiPengiriman">Bukti Pengiriman</Label>
                <Card style={{ width: '100%', height: '340px', position: 'relative' }}>
                  <CardBody style={{ padding: 0 }}>
                    {!buktiPengiriman ? (
                      <div {...getRootPropsPengiriman({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        <input {...getInputPropsPengiriman()} />
                        <p>Drag 'n' drop an image here, or click to select an image</p>
                      </div>
                    ) : (
                      <div style={{ textAlign: 'center', height: '100%', position: 'relative' }}>
                        <img src={buktiPengiriman.preview} alt="Bukti Pengiriman" style={{ width: '340px', height: '340px', objectFit: 'cover' }} />
                        <Button
                          color="danger"
                          size="sm"
                          onClick={removeFilePengiriman}
                          style={{ position: 'absolute', top: '10px', right: '10px' }}
                        >
                          &times;
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </FormGroup>
            </>
          )}
          {status === 'SELESAI' && (
            <FormGroup>
              <Label for="buktiPenerimaan">Bukti Penerimaan</Label>
              <Card style={{ width: '100%', height: '340px', position: 'relative' }}>
                <CardBody style={{ padding: 0 }}>
                  {!buktiPenerimaan ? (
                    <div {...getRootPropsPenerimaan({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                      <input {...getInputPropsPenerimaan()} />
                      <p>Drag 'n' drop an image here, or click to select an image</p>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center', height: '100%', position: 'relative' }}>
                      <img src={buktiPenerimaan.preview} alt="Bukti Penerimaan" style={{ width: '340px', height: '340px', objectFit: 'cover' }} />
                      <Button
                        color="danger"
                        size="sm"
                        onClick={removeFilePenerimaan}
                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                      >
                        &times;
                      </Button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </FormGroup>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>Save</Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default StatusEditModal;
