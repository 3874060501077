import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'reactstrap';
import moment from 'moment';

const DetailModal = ({ isOpen, toggle, pembelian }) => {

  const formatRupiah = (number) => {
    if (!number) return 'Rp 0';
    const formattedNumber = Number(number).toLocaleString('id-ID', {
      style: 'currency',
      currency: 'IDR',
    });
    return formattedNumber.replace(',00', '');
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Order Details</ModalHeader>
      <ModalBody>
        {pembelian && (
          <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <div>
                <h5>{pembelian.seller.seller}</h5>
                <p>
                  <strong>Akun Pembeli : {pembelian.user.full_name}</strong><br />
                  <i className="fa fa-phone" aria-hidden="true"></i> No Pembeli : {pembelian.user.phone_number}<br />
                  {pembelian.catatan && (<><i className="fa fa-sticky-note" aria-hidden="true"></i> {pembelian.catatan}<br /></>)}
                </p>
              </div>
              <div style={{ textAlign: 'right' }}>
                <p>
                  <strong>Invoice</strong>: #INV{pembelian.id}<br />
                  <strong>Status</strong>: {pembelian.status_pesanan}<br />
                  <strong>Date</strong>: {moment(pembelian.tanggal).format('DD-MM-YYYY')}<br />
                  <strong>Payment Method</strong>: {pembelian.metode_pembelian}
                </p>
              </div>
            </div>
            <div style={{ marginBottom: '20px' }}>
              <strong>Items</strong>
              <Table bordered>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>Item Name</th>
                    <th style={{ textAlign: 'center' }}>Quantity</th>
                    <th style={{ textAlign: 'center' }}>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {pembelian.details.map(detail => (
                    <tr key={detail.id}>
                      <td style={{ textAlign: 'center' }}>{detail.nama}</td>
                      <td style={{ textAlign: 'center' }}>{detail.jumlah} {detail.satuan}</td>
                      <td style={{ textAlign: 'center' }}>{formatRupiah(detail.harga)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="2" style={{ textAlign: 'right' }}>Jumlah Total</td>
                    <td style={{ textAlign: 'center' }}>{formatRupiah(pembelian.total)}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" style={{ textAlign: 'right' }}>Shipping Cost</td>
                    <td style={{ textAlign: 'center' }}>{formatRupiah(pembelian.ongkir)}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" style={{ textAlign: 'right' }}>Discount</td>
                    <td style={{ textAlign: 'center' }}>{formatRupiah(pembelian.diskon)}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" style={{ textAlign: 'right' }}>Grand Total</td>
                    <td style={{ textAlign: 'center' }}>{formatRupiah(pembelian.harga_total)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <strong>Payment Proof</strong><br />
                {pembelian.bukti_pembayaran ? (
                  <img src={pembelian.bukti_pembayaran} alt="Bukti Pembayaran" style={{ width: '100px', marginRight: '10px' }} />
                ) : 'No Image'}
              </div>
              <div>
                <strong>Purchase Proof</strong><br />
                {pembelian.bukti_pembelian ? (
                  <img src={pembelian.bukti_pembelian} alt="Bukti Pembelian" style={{ width: '100px', marginRight: '10px' }} />
                ) : 'No Image'}
              </div>
              <div>
                <strong>Shipping Proof</strong><br />
                {pembelian.pengirimanDetail?.bukti_pengiriman ? (
                  <img src={pembelian.pengirimanDetail.bukti_pengiriman} alt="Bukti Pengiriman" style={{ width: '100px', marginRight: '10px' }} />
                ) : 'No Image'}
              </div>
              <div>
                <strong>Receipt Proof</strong><br />
                {pembelian.pengirimanDetail?.bukti_penerimaan ? (
                  <img src={pembelian.pengirimanDetail.bukti_penerimaan} alt="Bukti Penerimaan" style={{ width: '100px', marginRight: '10px' }} />
                ) : 'No Image'}
              </div>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DetailModal;
