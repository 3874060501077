import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Button } from 'reactstrap';
import { DataOrderan } from './DataOrderan';
import EditModal from './EditModal';
import ColumnConfigModal from './ColumnConfigModal';
import { getOrders, updateOrder } from '../../Rest/api';
import { toast } from 'react-toastify';


const OrderTable = ({ searchText, startDate, endDate, filterOptions }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [data, setData] = useState([]); // Original data
  const [filteredData, setFilteredData] = useState([]);
  const [modal, setModal] = useState(false);
  const [configModal, setConfigModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [columns, setColumns] = useState([
    { name: '', selector: row => row.checkbox, sortable: false, center: true, grow: 0.1, visible: true },
    ...DataOrderan.productColumns
  ]);

  const toggle = () => setModal(!modal);
  const toggleConfig = () => setConfigModal(!configModal);

  const handleSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        return prevSelectedRows.filter((id) => id !== rowId);
      } else {
        return [...prevSelectedRows, rowId];
      }
    });
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? filteredData.map((item) => item.id) : []);
  };

  const handleSearch = () => {
    if (!searchText && filterOptions.status.length === 0 && filterOptions.paymentMethods.length === 0 && !startDate && !endDate) {
      setFilteredData(data); // Reset to original data
      return;
    }

    const filtered = data.filter((item) => {
      const itemDate = new Date(item.date.split(' - ')[0].split('-').reverse().join('-'));
      itemDate.setHours(0, 0, 0, 0);
      const startDateOnly = new Date(startDate);
      startDateOnly.setHours(0, 0, 0, 0);
      const endDateOnly = new Date(endDate);
      endDateOnly.setHours(23, 59, 59, 999);

      return (
        (filterOptions.status.length === 0 || filterOptions.status.includes(item.status)) &&
        (filterOptions.paymentMethods.length === 0 || filterOptions.paymentMethods.includes(item.paymentMethod.toLowerCase())) &&
        (item.id.toLowerCase().includes(searchText.toLowerCase()) ||
        item.productName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.nama.toLowerCase().includes(searchText.toLowerCase()) ||
        item.kabupaten.toLowerCase().includes(searchText.toLowerCase()) ||
        item.notes.toLowerCase().includes(searchText.toLowerCase()) ||
        item.gross.toLowerCase().includes(searchText.toLowerCase())) &&
        itemDate >= startDateOnly && itemDate <= endDateOnly
      );
    });
    setFilteredData(filtered);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    toggle();
  };

  const handleSave = async (updatedItem) => {
    console.log('Updated item:', updatedItem);
    try {
      const updatedData = await updateOrder(updatedItem.id, updatedItem);
      // Update local data state to reflect changes
      setFilteredData((prevData) => prevData.map((item) => item.id === updatedItem.id ? updatedItem : item));
      setData((prevData) =>
        prevData.map((item) => item.id === updatedItem.id ? { ...item, ...updatedData } : item)
      );
      toast.success('Order updated successfully!');
    } catch (error) {
      console.error('Failed to update order:', error);
      toast.error('Failed to update the order. Please try again.');
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchText, filterOptions, startDate, endDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getOrders();
        const data = response.data.map(order => {
          // Handle multiple product details
          const productNames = order.details.map(detail => detail.name).join(', ');
          const productQuantities = order.details.map(detail => detail.jumlah).join(', ');
        
          return {
            id: order.order_id,
            order: order.marketplace.name,
            numberOrder: order.order_number,
            productName: productNames,
            jumlah: productQuantities,
            nama: order.customer.name,
            nomer: order.customer.phone_number,
            alamat: order.address.address,
            kecamatan: order.address.sub_district,
            kabupaten: order.address.district,
            provinsi: order.address.province,
            status: order.status,
            courier: order.courier,
            paymentMethod: order.payment_method,
            resi: order.tracking_number,
            notes: order.notes,
            date: order.order_date,
            ongkir: parseFloat(order.shipping_cost).toLocaleString('id-ID'),
            harga: parseFloat(order.product_price).toLocaleString('id-ID'),
            gross: `Rp${parseFloat(order.gross_amount).toLocaleString('id-ID')}`,
            action: 'View/Edit'
          };
        });
        setData(data);
        setFilteredData(data); // Set initial filtered data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const updatedData = {
    productRow: filteredData.map((item) => ({
      ...item,
      checkbox: (
        <input
          type="checkbox"
          checked={selectedRows.includes(item.id)}
          onChange={() => handleSelectRow(item.id)}
          style={{
            width: '16px',
            height: '16px',
            margin: '0 auto',
            display: 'block',
          }}
        />
      ),
      action: (
        <Button
          onClick={() => handleEdit(item)}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Edit
        </Button>
      ),
    })),
  };

  const visibleColumns = columns.filter(col => col.visible);

  const columnSettings = columns.map(col => ({
    name: col.name,
    selector: col.selector,
    sortable: col.sortable,
    center: col.center,
    grow: col.grow,
    wrap: col.wrap,
    visible: col.visible,
  }));

  const tableColumns = [
    {
      name: (
        <input
          type="checkbox"
          checked={selectAll}
          onChange={handleSelectAll}
          style={{
            width: '16px',
            height: '16px',
            margin: '0 auto',
            display: 'block'
          }}
        />
      ),
      selector: row => row.checkbox,
      sortable: false,
      center: true,
      grow: 0.1,
    },
    ...visibleColumns.filter(col => col.name !== '')
  ];

  return (
    <Container fluid={true}>
      <Button color="primary" onClick={toggleConfig}>Configure Columns</Button>
      <div className='table-responsive table-bordernone orderHistoryTable product-table'>
        <DataTable
          pagination
          columns={tableColumns}
          data={updatedData.productRow}
          highlightOnHover
          striped
          responsive
        />
      </div>
      <EditModal isOpen={modal} toggle={toggle} item={currentItem} onSave={handleSave} />
      <ColumnConfigModal
        isOpen={configModal}
        toggle={toggleConfig}
        columns={columnSettings}
        onSave={setColumns}
      />
      <style jsx>{`
        .table-responsive table th,
        .table-responsive table td {
          text-align: center;
          vertical-align: middle;
        }
      `}</style>
    </Container>
  );
};

export default OrderTable;
