import React, { useState } from 'react';
import { Container, Row, Col, Input, FormGroup, Label, Button } from 'reactstrap';

const SearchAndFilter = ({ onSearch, toggleConfig, toggleAddUser }) => {
  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <Container fluid={true}>
      <Row className="d-flex align-items-end">
        <Col md={6}>
          <FormGroup>
            <Label for="search">Search by Name or Email</Label>
            <Input
              type="text"
              name="search"
              id="search"
              placeholder="Enter full name or email"
              value={searchText}
              onChange={handleSearchChange}
            />
          </FormGroup>
        </Col>
        <Col md={3} className="d-flex align-items-end" style={{ marginBottom: '17px' }}>
          <Button color="primary" onClick={toggleConfig} className="w-100">Configure Columns</Button>
        </Col>
        <Col md={3} className="d-flex align-items-end" style={{ marginBottom: '17px' }}>
          <Button color="success" onClick={toggleAddUser} className="w-100">Add User</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchAndFilter;
