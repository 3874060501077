import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { getOrders, deleteOrder } from '../../Rest/api'; // Ensure deleteOrder is correctly imported
import { FaEdit, FaTrash } from 'react-icons/fa';
import ColumnConfigModal from './ColumnConfigModal';
import SearchAndFilter from './SearchAndFilter';
import Dashboard from './Dashboard'; // Import the Dashboard component
import moment from 'moment';
import DeleteConfirmationModal from './DeleteConfirmationModal'; // Import the delete confirmation modal

const OrderanTable = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [dashboardOrders, setDashboardOrders] = useState([]); // State for dashboard orders
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState(''); // State to store the selected order number
  const [searchText, setSearchText] = useState(''); // Declare searchText in the state
  const [columns, setColumns] = useState([
    { name: 'No', selector: (row, index) => index + 1, sortable: false, center: true, grow: 0.1, visible: true },
    { name: 'Order ID', selector: row => row.order_id, sortable: true, visible: false },
    { name: 'Order', selector: row => row.order, sortable: true, visible: false },
    { name: 'Order Number', selector: row => row.numberOrder, sortable: true, visible: true },
    { name: 'Product Name', selector: row => (
      <div dangerouslySetInnerHTML={{ __html: row.productName }} />
    ), sortable: true, visible: true, wrap: true, minWidth: '300px' },
    { name: 'Total Quantity', selector: row => row.jumlah, sortable: true, visible: false },
    { name: 'Customer', selector: row => row.nama, sortable: true, visible: false },
    { name: 'Pembeli', selector: row => `${row.nama} (${row.nomer})`, sortable: true, visible: true, wrap: true },
    { name: 'Phone Number', selector: row => row.nomer, sortable: true, visible: false },
    { name: 'Address', selector: row => row.alamat, sortable: true, visible: false },
    { name: 'Sub District', selector: row => row.kecamatan, sortable: true, visible: false },
    { name: 'District', selector: row => row.kabupaten, sortable: true, visible: false },
    { name: 'Province', selector: row => row.provinsi, sortable: true, visible: false },
    { name: 'Status', selector: row => row.status, sortable: true, visible: true },
    { name: 'Courier', selector: row => row.courier, sortable: true, visible: false },
    { name: 'Payment Method', selector: row => row.paymentMethod, sortable: true, visible: false },
    { name: 'Tracking Number', selector: row => row.resi, sortable: true, visible: false },
    { name: 'Resi', selector: row => `${row.courier} (${row.resi})`, sortable: true, visible: true, wrap: true },
    { name: 'Notes', selector: row => row.notes, sortable: true, visible: false },
    { name: 'Order Date', selector: row => moment(row.date, 'DD-MM-YYYY - HH:mm').format('DD-MM-YYYY'), sortable: true, visible: true },
    { name: 'Shipping Cost', selector: row => row.ongkir, sortable: true, visible: false },
    { name: 'Product Price', selector: row => row.harga, sortable: true, visible: false },
    { name: 'Gross Amount', selector: row => row.gross, sortable: true, visible: true },
    { 
      name: 'Actions', 
      cell: row => (
        <div>
          <FaEdit style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEdit(row.id)} />
          <FaTrash style={{ cursor: 'pointer', color: 'red' }} onClick={() => confirmDelete(row.id, row.numberOrder)} />
        </div>
      ), 
      sortable: false, 
      center: true, 
      grow: 0.1,
      visible: true
    },
  ]);

  const handleColumnConfig = () => setModalOpen(true);

  const handleSaveColumns = (updatedColumns) => {
    setColumns(updatedColumns);
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText); // Update the searchText state
    const filtered = orders.filter(order =>
      order.order.toLowerCase().includes(searchText.toLowerCase()) ||
      order.nama.toLowerCase().includes(searchText.toLowerCase()) ||
      order.productName.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOrders(filtered);
    setDashboardOrders(filtered); // Update the dashboard orders
  };

  const handleFilter = (startDate, endDate, statuses, types, banks) => {
    const filtered = orders.filter(order => {
      const matchesSearchText = searchText ? 
        order.order.toLowerCase().includes(searchText.toLowerCase()) ||
        order.nama.toLowerCase().includes(searchText.toLowerCase()) ||
        order.productName.toLowerCase().includes(searchText.toLowerCase()) : true;
      const matchesDateRange = startDate && endDate ? moment(order.date, 'DD-MM-YYYY - HH:mm').isBetween(moment(startDate), moment(endDate), 'day', '[]') : true;
      const matchesStatus = statuses.length === 0 || statuses.includes(order.status);
      const matchesType = types.length === 0 || types.includes(order.type);
      const matchesBank = banks.length === 0 || banks.includes(order.bank);

      return matchesSearchText && matchesDateRange && matchesStatus && matchesType && matchesBank;
    });
    setFilteredOrders(filtered);
    setDashboardOrders(filtered); // Update the dashboard orders
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getOrders();
        const data = response.data.map(order => {
          const productNames = order.details.map(detail => detail.name).join('<br />');
          const totalQuantity = order.details.reduce((total, detail) => total + detail.jumlah, 0);

          return {
            id: order.id,
            order_id: order.order_id,
            order: order.marketplace.name,
            numberOrder: order.order_number,
            productName: productNames,
            jumlah: totalQuantity,
            nama: order.customer.name,
            nomer: order.customer.phone_number,
            alamat: order.address.address,
            kecamatan: order.address.sub_district,
            kabupaten: order.address.district,
            provinsi: order.address.province,
            status: order.status,
            courier: order.courier,
            paymentMethod: order.payment_method,
            resi: order.tracking_number,
            notes: order.notes,
            date: order.order_date, // Format the date here
            ongkir: parseFloat(order.shipping_cost).toLocaleString('id-ID'),
            harga: parseFloat(order.product_price).toLocaleString('id-ID'),
            gross: `Rp${parseFloat(order.gross_amount).toLocaleString('id-ID')}`,
          };
        });
        setOrders(data);
        setFilteredOrders(data);
        setDashboardOrders(data); // Set initial dashboard orders
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit-order/${id}`);
  };

  const confirmDelete = (id, orderNumber) => {
    setSelectedOrderId(id);
    setSelectedOrderNumber(orderNumber); // Set the selected order number
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteOrder(selectedOrderId); // Call the delete API with the order ID
      setOrders(orders.filter(order => order.id !== selectedOrderId)); // Update the orders state
      setFilteredOrders(filteredOrders.filter(order => order.id !== selectedOrderId)); // Update the filtered orders state
      setDashboardOrders(dashboardOrders.filter(order => order.id !== selectedOrderId)); // Update the dashboard orders state
      setDeleteModalOpen(false); // Close the modal
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Dashboard orders={dashboardOrders} /> {/* Pass the filtered dashboard orders */}
      <Card>
        <CardBody>
          <SearchAndFilter
            onSearch={handleSearch}
            onFilter={handleFilter}
            toggleConfig={handleColumnConfig}
          />
          <div className='table-responsive table-bordernone orderHistoryTable product-table' style={{ flex: 1 }}>
            <DataTable
              pagination
              columns={columns.filter(col => col.visible)}
              data={filteredOrders}
              highlightOnHover
              striped
              responsive
            />
          </div>
          <ColumnConfigModal
            isOpen={modalOpen}
            toggle={() => setModalOpen(false)}
            columns={columns}
            onSave={handleSaveColumns}
          />
          <DeleteConfirmationModal
            isOpen={deleteModalOpen}
            toggle={() => setDeleteModalOpen(false)}
            onDelete={handleDelete}
            orderNumber={selectedOrderNumber} // Pass the order number to the modal
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default OrderanTable;
