import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import { getSellers, addSeller, deleteSeller } from '../../Rest/api-pembelian';
import { FaCheck, FaTrash } from 'react-icons/fa';
import './SellerModal.css'; // Import the CSS file

const SellerModal = ({ isOpen, toggle, onSelectSeller }) => {
  const [sellers, setSellers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [newSellerName, setNewSellerName] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetchSellers();
    }
  }, [isOpen]);

  const fetchSellers = async () => {
    try {
      const response = await getSellers();
      console.log('Fetched sellers:', response); // Debugging log
      if (response) {
        setSellers(response);
      } else {
        setSellers([]); // Ensure sellers is initialized as an empty array if no data
      }
    } catch (error) {
      console.error('Error fetching sellers:', error);
      setSellers([]); // Ensure sellers is initialized as an empty array in case of error
    }
  };

  const handleAddSeller = async () => {
    try {
      await addSeller({ seller: newSellerName });
      setNewSellerName('');
      fetchSellers();
    } catch (error) {
      console.error('Error adding seller:', error);
    }
  };

  const handleDeleteSeller = async (id) => {
    try {
      await deleteSeller(id);
      fetchSellers();
    } catch (error) {
      console.error('Error deleting seller:', error);
    }
  };

  const filteredSellers = sellers.filter(seller =>
    seller.seller.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Select Seller</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="newSellerName">Add New Seller</Label>
          <Input
            type="text"
            id="newSellerName"
            value={newSellerName}
            onChange={(e) => setNewSellerName(e.target.value)}
            required
          />
          <Button color="primary" onClick={handleAddSeller} style={{ marginTop: '10px' }}>Add Seller</Button>
        </FormGroup>
        <FormGroup>
          <Label for="searchSeller">Search Seller</Label>
          <Input
            type="text"
            id="searchSeller"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </FormGroup>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th className="actions-column">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredSellers.length === 0 ? (
              <tr>
                <td colSpan="2">No sellers found.</td>
              </tr>
            ) : (
              filteredSellers.slice(0, 5).map(seller => (
                <tr key={seller.id}>
                  <td>{seller.seller}</td>
                  <td className="actions-column">
                    <Button color="primary" size="sm" onClick={() => onSelectSeller(seller.id, seller.seller)}>
                      <FaCheck />
                    </Button>
                    <Button color="danger" size="sm" onClick={() => handleDeleteSeller(seller.id)} style={{ marginLeft: '5px' }}>
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default SellerModal;
