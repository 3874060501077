import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import OrderTable from './OrderTable';
import FilterDropdown from './FilterDropdown';
import SearchBar from './SearchBar';
import { subDays } from 'date-fns';

const OrderTableCard = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());
  const [filterOptions, setFilterOptions] = useState({
    status: [],
    paymentMethods: [],
  });

  const toggleDropdown = () => {
    setDropdownOpen(prevOpen => !prevOpen);
  };

  const handleStatusChange = (status) => {
    setFilterOptions(prevFilterOptions => ({
      ...prevFilterOptions,
      status: prevFilterOptions.status.includes(status)
        ? prevFilterOptions.status.filter(s => s !== status)
        : [...prevFilterOptions.status, status],
    }));
  };
  
  const handlePaymentMethodChange = (method) => {
    setFilterOptions(prevFilterOptions => ({
      ...prevFilterOptions,
      paymentMethods: prevFilterOptions.paymentMethods.includes(method)
        ? prevFilterOptions.paymentMethods.filter(m => m !== method)
        : [...prevFilterOptions.paymentMethods, method],
    }));
  };

  const paymentOptions = [
    { value: 'bank transfer', label: 'Bank Transfer' },
    { value: 'cod', label: 'Cash on Delivery' },
  ];

  return (
    <Col>
          <Row className="mb-3">
            <Col md={3}>
              <FilterDropdown
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                filterOptions={filterOptions}
                handleStatusChange={handleStatusChange}
                handlePaymentMethodChange={handlePaymentMethodChange}
                paymentOptions={paymentOptions}
              />
            </Col>
            <Col md={3}>
            </Col>
            <SearchBar
              searchText={searchText}
              setSearchText={setSearchText}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Row>
      <Card>
        <CardBody>
          <OrderTable
            searchText={searchText}
            startDate={startDate}
            endDate={endDate}
            filterOptions={filterOptions}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default OrderTableCard;
