import React from 'react';
import { Col, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const SearchBar = ({ searchText, setSearchText, startDate, endDate, setStartDate, setEndDate }) => {
  return (
    <>
      <Col md={3}>
        <DatePicker
          selected={startDate}
          onChange={(dates) => {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          placeholderText="Select date range"
          className="form-control"
        />
      </Col>
      <Col md={3}>
        <Input
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search..."
        />
      </Col>
    </>
  );
};

export default SearchBar;
