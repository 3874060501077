import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Button, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { deleteCategory, updateCategory } from '../../../Rest/api-keuangan';  // Ensure this import path is correct

const CategoryTable = ({ categories, fetchData }) => {
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryData, setCategoryData] = useState({ tipe: '', nama: '' });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let filteredData = categories;

    // Filter by type
    if (filterType) {
      filteredData = filteredData.filter(category => category.type === filterType);
    }

    // Search by name
    if (searchTerm) {
      filteredData = filteredData.filter(category => 
        category.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredCategories(filteredData);
  }, [categories, searchTerm, filterType]);

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleEditModal = () => setEditModal(!editModal);

  const handleDelete = async (id) => {
    try {
      await deleteCategory(id);
      toast.success('Category deleted successfully!');
      fetchData(); // Refresh the data after deletion
      toggleDeleteModal();
    } catch (error) {
      console.error('Error deleting category:', error);
      toast.error('Error deleting category');
    }
  };

  const handleEdit = (category) => {
    setSelectedCategory(category);
    setCategoryData({ tipe: category.type, nama: category.name });
    toggleEditModal();
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      await updateCategory(selectedCategory.id, categoryData);
      toast.success('Category updated successfully!');
      fetchData(); // Refresh the data after update
      toggleEditModal();
    } catch (error) {
      console.error('Error updating category:', error);
      toast.error('Error updating category');
    }
  };

  const confirmDelete = (category) => {
    setSelectedCategory(category);
    toggleDeleteModal();
  };

  const columns = [
    { name: 'No', selector: row => row.no, sortable: true, maxWidth: "100px" },
    { name: 'Type', selector: row => row.type, sortable: true },
    { name: 'Name', selector: row => row.name, sortable: true, wrap: true },
    {
      name: 'Actions',
      button: true,
      cell: (row) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
          <FaEdit style={{ cursor: 'pointer' }} onClick={() => handleEdit(row)} />
          <FaTrash style={{ cursor: 'pointer', color: 'black' }} onClick={() => confirmDelete(row)} />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      right: true,
      width: '100px',
    }
  ];

  return (
    <Container fluid={true}>
      <Row className="mb-3">
        <Col sm="6">
          <Input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col sm="6">
          <Input
            type="select"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="">All Types</option>
            <option value="PEMASUKAN">PEMASUKAN</option>
            <option value="PENGELUARAN">PENGELUARAN</option>
          </Input>
        </Col>
      </Row>
      <div className='table-responsive table-bordernone category-table'>
        <DataTable
          pagination
          columns={columns}
          data={filteredCategories}
          highlightOnHover
          striped
          responsive
        />
      </div>

      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the category "{selectedCategory?.name}"?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => handleDelete(selectedCategory.id)}>Delete</Button>
          <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={editModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit Category</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleUpdate}>
            <FormGroup>
              <Label for="type">Type</Label>
              <Input 
                type="select" 
                name="type" 
                id="type" 
                value={categoryData.tipe} 
                onChange={(e) => setCategoryData({ ...categoryData, tipe: e.target.value })} 
                required
              >
                <option value="">Select Type</option>
                <option value="PEMASUKAN">PEMASUKAN</option>
                <option value="PENGELUARAN">PENGELUARAN</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input 
                type="text" 
                name="name" 
                id="name" 
                placeholder="Enter category name" 
                value={categoryData.nama} 
                onChange={(e) => setCategoryData({ ...categoryData, nama: e.target.value })} 
                required 
              />
            </FormGroup>
            <Button type="submit" color="primary">Update Category</Button>
          </Form>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default CategoryTable;
