import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Correct import statement
import { getCookie } from '../Utils/cookies';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility function to get user_id from token
const getUserIdFromToken = () => {
  const token = getCookie('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  }
  return null;
};

export const getProductCategories = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/productcategories/user/${user_id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch product categories:', error);
    throw error;
  }
};

export const getProducts = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/product/user/${user_id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch products:', error);
    throw error;
  }
};

// Seller API calls
export const getSellers = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/sellers/user/${user_id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch sellers:', error);
    throw error;
  }
};

export const addSeller = async (sellerData) => {
  try {
    const response = await api.post('/sellers', sellerData);
    return response.data;
  } catch (error) {
    console.error('Error creating seller:', error);
    throw error;
  }
};

export const deleteSeller = async (sellerId) => {
  try {
    const response = await api.delete(`/sellers/${sellerId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting seller ${sellerId}:`, error);
    throw error;
  }
};

// Pembelian API calls
export const getPembelian = async () => {
  try {
    const response = await api.get('/pembelian');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch pembelian:', error);
    throw error;
  }
};

export const createPembelian = async (pembelianData) => {
  try {
    const response = await api.post('/pembelian', pembelianData);
    return response.data;
  } catch (error) {
    console.error('Error creating pembelian:', error);
    throw error;
  }
};

export const uploadImage = async (description, imageFile) => {
  const user_id = getUserIdFromToken();
  if (!user_id) throw new Error('User ID not found');
  const formData = new FormData();
  formData.append('userId', user_id);
  formData.append('description', description);
  formData.append('file', imageFile);

  try {
    const response = await api.post('/upload/pembelian', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const updatePembelian = async (pembelianId, pembelianData) => {
  try {
    const response = await api.put(`/pembelian/${pembelianId}`, pembelianData);
    return response.data;
  } catch (error) {
    console.error(`Error updating pembelian ${pembelianId}:`, error);
    throw error;
  }
};

export const deletePembelian = async (pembelianId) => {
  try {
    const response = await api.delete(`/pembelian/${pembelianId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting pembelian ${pembelianId}:`, error);
    throw error;
  }
};

export const updateStatusPembelian = async (pembelianId, statusData) => {
  try {
    const response = await api.put(`/pembelian/${pembelianId}/status`, statusData);
    return response.data;
  } catch (error) {
    console.error(`Error updating status for pembelian ${pembelianId}:`, error);
    throw error;
  }
};

// Add more API calls as needed
export default api;
