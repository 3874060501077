import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useDropzone } from 'react-dropzone';
import { uploadImage, createInventory, getProductCategories, getUserWarehouses } from '../../../Rest/api-inventory';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const ManageInventory = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [formData, setFormData] = useState({
    product_category_id: '',
    sku: '',
    name: '',
    hpp: '',
    product_photo: '',
    quantity: 1,
    satuan: 'Pcs',
    description: '',
    location_id: '',
    selling_price: '',
    inventory_value: '',
    active: true
  });
  const [formattedHpp, setFormattedHpp] = useState('');
  const [formattedSellingPrice, setFormattedSellingPrice] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch categories
    getProductCategories().then(response => {
      setCategories(response);
    }).catch(error => {
      console.error('Error fetching categories:', error);
    });

    // Fetch warehouses
    getUserWarehouses().then(response => {
      setWarehouses(response);
    }).catch(error => {
      console.error('Error fetching warehouses:', error);
    });
  }, []);

  const formatNumber = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'hpp') {
      // Remove non-numeric characters
      const numericValue = value.replace(/[^\d]/g, '');
      setFormData({
        ...formData,
        [name]: numericValue,
      });
      setFormattedHpp(formatNumber(numericValue));
    } else if (name === 'selling_price') {
      // Remove non-numeric characters
      const numericValue = value.replace(/[^\d]/g, '');
      setFormData({
        ...formData,
        [name]: numericValue,
      });
      setFormattedSellingPrice(formatNumber(numericValue));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let productPhotoUrl = formData.product_photo;

      if (file) {
        const uploadResponse = await uploadImage(formData.user_id, formData.description, file);
        productPhotoUrl = uploadResponse.photoUrl;
      }

      // Remove dots from hpp and selling_price before submitting
      const hpp = formData.hpp.replace(/\./g, '');
      const selling_price = formData.selling_price.replace(/\./g, '');
      const inventoryValue = hpp * formData.quantity;

      const inventoryData = {
        ...formData,
        hpp,
        selling_price,
        product_photo: productPhotoUrl,
        inventory_value: inventoryValue
      };

      const response = await createInventory(inventoryData);
      console.log('Inventory created successfully:', response);

      // Extract the layout URL parameter from the current URL
      const currentURL = window.location.href;
      const urlParts = currentURL.split('/');
      const layoutURL = urlParts[urlParts.length - 1];

      // Navigate to the desired URL
      navigate(`${process.env.PUBLIC_URL}/products/${layoutURL}`);
    } catch (error) {
      console.error('Error creating inventory:', error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`Failed to save inventory: ${error.response.data.error}`);
      } else {
        toast.error('Failed to save inventory. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1
  });

  useEffect(() => () => {
    if (file) {
      URL.revokeObjectURL(file.preview);
    }
  }, [file]);

  const removeFile = () => {
    setFile(null);
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Manage Inventory" parent="Dashboard" title="Inventory" />
      <Container fluid={true} className="general-widget">
        <ToastContainer />
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="product_photo">Product Photo</Label>
                <Card style={{ width: '100%', height: '340px', position: 'relative' }}>
                  <CardBody style={{ padding: 0 }}>
                    {!file && (
                      <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop an image here, or click to select an image</p>
                      </div>
                    )}
                    {file && (
                      <div style={{ textAlign: 'center', height: '100%', position: 'relative' }}>
                        <img src={file.preview} alt={file.name} style={{ width: '340px', height: '340px', objectFit: 'cover' }} />
                        <Button
                          color="danger"
                          size="sm"
                          onClick={removeFile}
                          style={{ position: 'absolute', top: '10px', right: '10px' }}
                        >
                          &times;
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </FormGroup>
              <FormGroup>
                <Label for="product_category_id">Category</Label>
                <Input type="select" name="product_category_id" id="product_category_id" value={formData.product_category_id} onChange={handleChange} required>
                  <option value="">Select Category</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" name="name" id="name" value={formData.name} onChange={handleChange} required />
              </FormGroup>
              <FormGroup>
                <Label for="sku">SKU</Label>
                <Input type="text" name="sku" id="sku" value={formData.sku} onChange={handleChange} required />
              </FormGroup>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="quantity">Quantity</Label>
                    <Input type="number" name="quantity" id="quantity" value={formData.quantity} onChange={handleChange} required />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="satuan">Satuan</Label>
                    <Input type="text" name="satuan" id="satuan" value={formData.satuan} onChange={handleChange} />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="hpp">HPP</Label>
                <Input
                  type="text"
                  name="hpp"
                  id="hpp"
                  value={formattedHpp}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="selling_price">Selling Price</Label>
                <Input
                  type="text"
                  name="selling_price"
                  id="selling_price"
                  value={formattedSellingPrice}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="location_id">Location</Label>
                <Input type="select" name="location_id" id="location_id" value={formData.location_id} onChange={handleChange} required>
                  <option value="">Select Location</option>
                  {warehouses.map(warehouse => (
                    <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input type="textarea" name="description" id="description" value={formData.description} onChange={handleChange} />
          </FormGroup>
          <Button type="submit" color="primary" disabled={loading}>
            {loading ? <Spinner size="sm" /> : 'Save Inventory Item'}
          </Button>
        </Form>
      </Container>
    </Fragment>
  );
};

export default ManageInventory;
