import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import { getPembelian, deletePembelian, getProductCategories, updateStatusPembelian, uploadImage } from '../../Rest/api-pembelian';
import { FaTrash, FaEdit, FaInfoCircle } from 'react-icons/fa';
import moment from 'moment';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ColumnConfigModal from './ColumnConfigModal';
import SearchAndFilter from './SearchAndFilter';
import FilterModal from './FilterModal';
import ImageModal from './ImageModal'; // Import the ImageModal component
import StatusEditModal from './StatusEditModal'; // Import the StatusEditModal component
import DetailModal from './DetailModal'; // Import the DetailModal component

const formatRupiah = (number) => {
  if (!number) return 'Rp 0';
  const formattedNumber = Number(number).toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR',
  });
  return formattedNumber.replace(',00', '');
};

const PembelianTable = () => {
  const [pembelian, setPembelian] = useState([]);
  const [filteredPembelian, setFilteredPembelian] = useState([]);
  const [currentPembelian, setCurrentPembelian] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [configModal, setConfigModal] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [statusEditModalOpen, setStatusEditModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false); // State for detail modal
  const [searchText, setSearchText] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedMethods, setSelectedMethods] = useState([]);
  const [categories, setCategories] = useState([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const [columns, setColumns] = useState([
    { name: 'No', selector: (row, index) => index + 1, sortable: false, center: true, grow: 0.1, visible: true },
    { name: 'Tanggal', selector: row => moment(row.tanggal).format('DD-MM-YYYY'), sortable: true, visible: true },
    { 
      name: 'Details', 
      selector: row => row.details.map(detail => (
        <div key={detail.id}>
          {detail.nama}, ({detail.jumlah} {detail.satuan})
        </div>
      )), 
      sortable: false, 
      visible: true,
      wrap: true 
    },
    { name: 'Pembelian', selector: row => row.metode_pembelian, sortable: true, visible: true, wrap: true },
    { name: 'Pembayaran', selector: row => row.pembayaran, sortable: true, visible: false },
    { name: 'Ongkir', selector: row => formatRupiah(row.ongkir), sortable: true, visible: false },
    { name: 'Diskon', selector: row => formatRupiah(row.diskon), sortable: true, visible: false },
    { name: 'Total', selector: row => formatRupiah(row.total), sortable: true, visible: false },
    { name: 'Harga Total', selector: row => formatRupiah(row.harga_total), sortable: true, visible: true },
    { 
      name: 'Bukti Pembayaran', 
      selector: row => row.bukti_pembayaran ? (
        <img 
          src={row.bukti_pembayaran} 
          alt="Bukti Pembayaran" 
          style={{ width: '50px', height: '50px', cursor: 'pointer' }} 
          onClick={() => { setImageUrl(row.bukti_pembayaran); setImageModalOpen(true); }} // Open modal on click
        />
      ) : 'No Image', 
      sortable: false,
      wrap: true, 
      visible: true 
    },
    { 
      name: 'Bukti Pembelian', 
      selector: row => row.bukti_pembelian ? (
        <img 
          src={row.bukti_pembelian} 
          alt="Bukti Pembelian" 
          style={{ width: '50px', height: '50px', cursor: 'pointer' }} 
          onClick={() => { setImageUrl(row.bukti_pembelian); setImageModalOpen(true); }} // Open modal on click
        />
      ) : 'No Image', 
      sortable: false, 
      wrap: true, 
      visible: true 
    },
    { 
      name: 'Bukti Pengiriman', 
      selector: row => row.pengirimanDetail?.bukti_pengiriman ? (
        <img 
          src={row.pengirimanDetail.bukti_pengiriman} 
          alt="Bukti Pengiriman" 
          style={{ width: '50px', height: '50px', cursor: 'pointer' }} 
          onClick={() => { setImageUrl(row.pengirimanDetail.bukti_pengiriman); setImageModalOpen(true); }} // Open modal on click
        />
      ) : 'No Image', 
      sortable: false, 
      wrap: true, 
      visible: true 
    },
    { 
      name: 'Bukti Penerimaan', 
      selector: row => row.pengirimanDetail?.bukti_penerimaan ? (
        <img 
          src={row.pengirimanDetail.bukti_penerimaan} 
          alt="Bukti Penerimaan" 
          style={{ width: '50px', height: '50px', cursor: 'pointer' }} 
          onClick={() => { setImageUrl(row.pengirimanDetail.bukti_penerimaan); setImageModalOpen(true); }} // Open modal on click
        />
      ) : 'No Image', 
      sortable: false, 
      wrap: true, 
      visible: true 
    },
    { name: 'Kurir', selector: row => row.pengirimanDetail?.kurir || 'N/A', sortable: true, visible: false },
    { name: 'Resi', selector: row => row.pengirimanDetail?.resi || 'N/A', sortable: true, visible: false },
    { 
      name: 'Status', 
      selector: row => (
        <div>
          {row.status_pesanan} <FaEdit style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => { 
            setCurrentPembelian({
              ...row,
              kurir: row.pengirimanDetail?.kurir,
              resi: row.pengirimanDetail?.resi,
              buktiPengiriman: row.pengirimanDetail?.bukti_pengiriman,
              buktiPenerimaan: row.pengirimanDetail?.bukti_penerimaan
            }); 
            setStatusEditModalOpen(true); 
          }} />
        </div>
      ), 
      sortable: true, 
      visible: true 
    },
    { name: 'Catatan', selector: row => row.catatan, sortable: true, visible: false },
    { name: 'User', selector: row => row.user.full_name, sortable: true, visible: false },
    { name: 'Seller', selector: row => row.seller.seller, sortable: true, visible: false },
    { name: 'Kategori Produk', selector: row => row.productCategory.name, sortable: true, visible: false },
    { 
      name: 'Actions', 
      cell: row => (
        <div>
          <FaInfoCircle style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => { setCurrentPembelian(row); setDetailModalOpen(true); }} />
          <FaTrash style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setCurrentPembelian(row); toggleDeleteModal(); }} />
        </div>
      ), 
      sortable: false, 
      center: true, 
      grow: 0.1, 
      visible: true 
    },
  ]);

  // Function to fetch purchase data and categories from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPembelian();
        if (!response || response.length === 0) {
          setPembelian([]);
          setFilteredPembelian([]);
        } else {
          setPembelian(response);
          setFilteredPembelian(response);
        }
      } catch (error) {
        console.error('Error fetching purchases:', error);
        toast.error('Failed to fetch purchase data. Please try again.');
      }

      try {
        const categoriesResponse = await getProductCategories();
        if (categoriesResponse) {
          setCategories(categoriesResponse);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchData();
  }, []);

  // Function to handle deleting a purchase
  const handleDeletePembelian = async () => {
    try {
      await deletePembelian(currentPembelian.id); // Adjust as per your API function
      setPembelian(pembelian.filter(tx => tx.id !== currentPembelian.id));
      setFilteredPembelian(filteredPembelian.filter(tx => tx.id !== currentPembelian.id));
      toast.success('Purchase deleted successfully');
      toggleDeleteModal();
    } catch (error) {
      console.error('Failed to delete purchase:', error);
      toast.error('Failed to delete purchase. Please try again.');
    }
  };

  // Function to handle status update with image upload
  const handleUpdatePembelianStatus = async (data) => {
    const { status, buktiPengiriman, buktiPenerimaan, kurir, resi } = data;
    try {
      let buktiPengirimanUrl = '';
      let buktiPenerimaanUrl = '';

      if (buktiPengiriman) {
        const uploadResponse = await uploadImage('Bukti Pengiriman', buktiPengiriman);
        buktiPengirimanUrl = uploadResponse.photoUrl;
      }

      if (buktiPenerimaan) {
        const uploadResponse = await uploadImage('Bukti Penerimaan', buktiPenerimaan);
        buktiPenerimaanUrl = uploadResponse.photoUrl;
      }

      const updatedStatusData = {
        status,
        buktiPengiriman: buktiPengirimanUrl,
        buktiPenerimaan: buktiPenerimaanUrl,
        kurir,
        resi
      };

      await updateStatusPembelian(currentPembelian.id, updatedStatusData);
      const updatedPembelian = await getPembelian();
      setPembelian(updatedPembelian);
      setFilteredPembelian(updatedPembelian);
      toast.success('Status updated successfully');
      setStatusEditModalOpen(false);
    } catch (error) {
      console.error('Failed to update status:', error);
      toast.error('Failed to update status. Please try again.');
    }
  };

  // Function to toggle delete confirmation modal
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  // Function to toggle column configuration modal
  const toggleConfigModal = () => setConfigModal(!configModal);

  // Function to toggle filter modal
  const toggleFilterModal = () => setFilterModalOpen(!filterModalOpen);

  // Function to handle column configuration change
  const handleConfigChange = (updatedColumns) => {
    setColumns(updatedColumns);
  };

  // Function to handle search and filter
  const handleSearch = (text) => {
    setSearchText(text);
    applyFilters(text, selectedDateRange.startDate, selectedDateRange.endDate, selectedCategories, selectedStatuses, selectedMethods);
  };

  const handleFilter = (categories = [], statuses = [], methods = [], dateRange = { startDate: null, endDate: null }) => {
    setSelectedCategories(categories);
    setSelectedStatuses(statuses);
    setSelectedMethods(methods);
    setSelectedDateRange(dateRange);
    applyFilters(searchText, dateRange.startDate, dateRange.endDate, categories, statuses, methods);
  };

  const applyFilters = (searchText, startDate, endDate, categories, statuses, methods) => {
    const filtered = pembelian.filter(item => {
      const matchesSearchText = item.details.some(detail =>
        detail.nama.toLowerCase().includes(searchText.toLowerCase())
      );
      const matchesDateRange = startDate && endDate ? moment(item.tanggal).isBetween(startDate, endDate, 'day', '[]') : true;
      const matchesCategory = categories.length > 0 ? categories.includes(item.productCategory?.name) : true;
      const matchesStatus = statuses.length > 0 ? statuses.includes(item.status_pesanan) : true;
      const matchesMethod = methods.length > 0 ? methods.includes(item.pembayaran) : true;
      return matchesSearchText && matchesDateRange && matchesCategory && matchesStatus && matchesMethod;
    });
    setFilteredPembelian(filtered);
  };

  const visibleColumns = columns.filter(column => column.visible);

  return (
    <Container fluid={true}>
      <ToastContainer />
      <SearchAndFilter onSearch={handleSearch} onFilter={handleFilter} toggleConfig={toggleConfigModal} toggleFilter={toggleFilterModal} />
      <div className='table-responsive table-bordernone orderHistoryTable'>
        <DataTable
          pagination
          columns={visibleColumns}
          data={filteredPembelian}
          highlightOnHover
          striped
          responsive
        />
      </div>
      <DeleteConfirmationModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        onConfirm={handleDeletePembelian}
        itemName={currentPembelian ? currentPembelian.details.map(detail => detail.nama).join(', ') : ''}
      />
      <ColumnConfigModal
        isOpen={configModal}
        toggle={toggleConfigModal}
        columns={columns}
        onSave={handleConfigChange}
      />
      <FilterModal
        isOpen={filterModalOpen}
        toggle={toggleFilterModal}
        onApply={handleFilter}
        categories={categories}
        selectedCategories={selectedCategories}
        selectedStatuses={selectedStatuses}
        selectedMethods={selectedMethods}
      />
      <ImageModal 
        isOpen={imageModalOpen} 
        toggle={() => setImageModalOpen(!imageModalOpen)} 
        imageUrl={imageUrl} 
      />
      <StatusEditModal
        isOpen={statusEditModalOpen}
        toggle={() => setStatusEditModalOpen(!statusEditModalOpen)}
        onSave={handleUpdatePembelianStatus}
        currentStatus={currentPembelian ? currentPembelian.status_pesanan : ''}
        currentKurir={currentPembelian ? currentPembelian.kurir : ''}
        currentResi={currentPembelian ? currentPembelian.resi : ''}
        currentBuktiPengiriman={currentPembelian ? currentPembelian.buktiPengiriman : ''}
        currentBuktiPenerimaan={currentPembelian ? currentPembelian.buktiPenerimaan : ''}
      />
      <DetailModal
        isOpen={detailModalOpen}
        toggle={() => setDetailModalOpen(!detailModalOpen)}
        pembelian={currentPembelian}
      />
      <style jsx>{`
        .table-responsive table th,
        .table-responsive table td {
          text-align: center;
          vertical-align: middle;
        }
      `}</style>
    </Container>
  );
};

export default PembelianTable;
