import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ColumnConfigModal = ({ isOpen, toggle, columns, onSave }) => {
  const [localColumns, setLocalColumns] = useState(columns);

  useEffect(() => {
    setLocalColumns(columns);
  }, [columns]);

  const handleSave = () => {
    onSave(localColumns);
    toggle();
  };

  const handleCheckboxChange = (index) => {
    const updatedColumns = [...localColumns];
    updatedColumns[index].visible = !updatedColumns[index].visible;
    setLocalColumns(updatedColumns);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedColumns = Array.from(localColumns);
    const [removed] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, removed);
    setLocalColumns(reorderedColumns);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Configure Columns</ModalHeader>
      <ModalBody>
        <Form>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="columns">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {localColumns.map((col, index) => (
                    <Draggable key={col.name || `checkbox-${index}`} draggableId={col.name || `checkbox-${index}`} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            marginBottom: '5px',
                            padding: '5px',
                            border: '1px solid lightgray',
                            borderRadius: '4px',
                            backgroundColor: 'white',
                          }}
                        >
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={col.visible}
                                onChange={() => handleCheckboxChange(index)}
                                style={{ backgroundColor: 'lightcoral', width: '15px', height: '15px', marginRight: '10px' }}
                              />
                              {col.name || 'Select'}
                            </Label>
                          </FormGroup>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>Save</Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ColumnConfigModal;
