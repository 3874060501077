import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getCookie } from "../Utils/cookies"; // Import getCookie

const PrivateRoute = () => {


  // Safely parse cookies
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isAuthenticated = getCookie("authenticated");
    const isLoggedIn = getCookie("login");

    console.log("authenticated:", isAuthenticated);
    console.log("login:", isLoggedIn);

    if (isAuthenticated === "true" && isLoggedIn === "true") {
      setAuthenticated(true);
    }

    setIsLoading(false); // Set loading to false after checking cookies
  }, []);

  if (isLoading) {
    return <div> </div>; // Show loading indicator while checking cookies
  }

  return authenticated ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/login`} />;
};

export default PrivateRoute;
