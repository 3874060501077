import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FaHome } from 'react-icons/fa'; // Import an icon library like FontAwesome
import './SidebarMenu.css'; // Import the CSS file for styling

const SidebarMenu = () => {
  const { layout } = useParams(); // Extract the layout parameter from the URL
  const location = useLocation(); // Use useLocation to get the current path

  return (
    <Card className="sidebar-card">
      <CardBody className="p-0">
        <ul className="nav flex-column sidebar-nav">
          <li className="nav-item">
            <Link
              to={`/setting/business/${layout}`}
              className={`nav-link sidebar-link ${
                location.pathname.includes('/setting/business') ? 'active' : ''
              }`}
            >
              
              Bisnis
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              to={`/setting/members/${layout}`}
              className={`nav-link sidebar-link ${
                location.pathname.includes('/setting/members') ? 'active' : ''
              }`}
            >
              
              Anggota
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/setting/permissions/${layout}`}
              className={`nav-link sidebar-link ${
                location.pathname.includes('/setting/permissions') ? 'active' : ''
              }`}
            >
              
              Permissions
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              to={`/setting/warehouse/${layout}`}
              className={`nav-link sidebar-link ${
                location.pathname.includes('/setting/warehouse') ? 'active' : ''
              }`}
            >
             
              Gudang
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              to={`/setting/menu/${layout}`}
              className={`nav-link sidebar-link ${
                location.pathname.includes('/setting/menu') ? 'active' : ''
              }`}
            >
             
              Menu
            </Link>
          </li> */}
          {/* Add more sidebar items as needed */}
        </ul>
      </CardBody>
    </Card>
  );
};

export default SidebarMenu;
