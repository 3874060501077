import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Row, Col,Container, Card, CardBody, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { uploadImage, getInventories, createStokGudang } from '../../Rest/api-gudang';

const CreateStokGudang = () => {
  const navigate = useNavigate();
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    jenis_transaksi: '',
    inventory_id: '',
    jumlah: '',
    nomor_resi_surat_jalan: '',
    bukti_foto: '',
    tanggal: getCurrentDate(),
    catatan: '' // Add this line
  });

  const [formattedJumlah, setFormattedJumlah] = useState('');
  const [inventories, setInventories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const inventoryData = await getInventories();
        const formattedInventories = inventoryData.map((inventory) => ({
          value: inventory.id,
          label: `${inventory.productCategory.name} - ${inventory.name}`, // Combine name and category
        }));
        setInventories(formattedInventories);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, []);

  const formatNumber = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'jumlah') {
      const numericValue = value.replace(/[^\d]/g, '');
      setFormData({
        ...formData,
        [name]: numericValue,
      });
      setFormattedJumlah(formatNumber(numericValue));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (selectedOption, field) => {
    setFormData({ ...formData, [field.name]: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let productPhotoUrl = formData.bukti_foto;

      if (file) {
        const uploadResponse = await uploadImage(formData.nomor_resi_surat_jalan, file);
        productPhotoUrl = uploadResponse.photoUrl;
      }

      const stokGudangData = {
        ...formData,
        jumlah: formData.jumlah.replace(/\./g, ''), // Remove dots before submitting
        bukti_foto: productPhotoUrl,
      };

      // Extract the layout URL parameter from the current URL
      const currentURL = window.location.href;
      const urlParts = currentURL.split('/');
      const layoutURL = urlParts[urlParts.length - 1];

      await createStokGudang(stokGudangData);
      toast.success('Stok Gudang berhasil dibuat!');
      navigate(`${process.env.PUBLIC_URL}/stok/${layoutURL}`);
    } catch (error) {
      console.error('Error creating Stok Gudang:', error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`Gagal membuat Stok Gudang: ${error.response.data.error}`);
      } else {
        toast.error('Gagal membuat Stok Gudang. Silakan coba lagi.');
      }
    } finally {
      setLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(Object.assign(file, { preview: URL.createObjectURL(file) }));
    setFormData({ ...formData, bukti_foto: file });
  }, [formData]);

  const removeFile = () => {
    setFile(null);
    setFormData({ ...formData, bukti_foto: null });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false
  });

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Create In/Out Stock" parent="Dashboard" title="Stock" />
      <Container fluid={true} className="general-widget">
        <ToastContainer />
        <Form onSubmit={handleSubmit}>
  <Row>
    <Col md={6}>
      <FormGroup>
        <Label for="tanggal">Tanggal</Label>
        <Input
          type="date"
          name="tanggal"
          id="tanggal"
          value={formData.tanggal}
          onChange={handleChange}
          required
        />
      </FormGroup>
    </Col>
    <Col md={6}>
      <FormGroup>
        <Label for="jenis_transaksi">Jenis Transaksi</Label>
        <Input
          type="select"
          name="jenis_transaksi"
          id="jenis_transaksi"
          value={formData.jenis_transaksi}
          onChange={handleChange}
          required
        >
          <option value="">Pilih Jenis Transaksi</option>
          <option value="masuk">Masuk</option>
          <option value="keluar">Keluar</option>
        </Input>
      </FormGroup>
    </Col>
  </Row>

  <FormGroup>
    <Label for="inventory_id">Inventory</Label>
    <Select
      name="inventory_id"
      id="inventory_id"
      options={inventories}
      onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'inventory_id' })}
      required
    />
  </FormGroup>

  <Row>
    <Col md={6}>
      <FormGroup>
        <Label for="nomor_resi_surat_jalan">Nomor Resi / Surat Jalan</Label>
        <Input
          type="text"
          name="nomor_resi_surat_jalan"
          id="nomor_resi_surat_jalan"
          value={formData.nomor_resi_surat_jalan}
          onChange={handleChange}
          required
        />
      </FormGroup>
    </Col>
    <Col md={6}>
      <FormGroup>
        <Label for="jumlah">Jumlah</Label>
        <Input
          type="text"
          name="jumlah"
          id="jumlah"
          value={formattedJumlah}
          onChange={handleChange}
          required
        />
      </FormGroup>
    </Col>
  </Row>

  

  <Row>
  <Col md={6}>
    <FormGroup>
      <Label for="catatan">Catatan</Label>
      <Input
        type="textarea"
        name="catatan"
        id="catatan"
        value={formData.catatan}
        onChange={handleChange}
      />
    </FormGroup>
  </Col>

  <Col md={6}>
    <FormGroup>
      <Label for="bukti_foto">Bukti Foto</Label>
      <Card style={{ width: '100%', height: '340px', position: 'relative' }}>
        <CardBody style={{ padding: 0 }}>
          {!file && (
            <div
              {...getRootProps({ className: 'dropzone' })}
              style={{
                border: '2px dashed #007bff',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <input {...getInputProps()} />
              <p>Drag 'n' drop an image here, or click to select an image</p>
            </div>
          )}
          {file && (
            <div style={{ textAlign: 'center', height: '100%', position: 'relative' }}>
              <img 
  src={file.preview} 
  alt={file.name} 
  style={{ 
    maxWidth: '100%', 
    height: 'auto', 
    maxHeight: '340px', 
    objectFit: 'contain' 
  }} 
/>
              <Button
                color="danger"
                size="sm"
                onClick={removeFile}
                style={{ position: 'absolute', top: '10px', right: '10px' }}
              >
                &times;
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </FormGroup>
  </Col>
</Row>


  <div className="d-flex justify-content-end">
    <Button type="submit" color="primary" disabled={loading}>
      {loading ? <Spinner size="sm" /> : 'Create Stok Gudang'}
    </Button>
  </div>
</Form>
      </Container>
    </Fragment>
  );
};

export default CreateStokGudang;
