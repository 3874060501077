import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import SidebarMenu from './SidebarMenu';
import { getUserMenus, createPermission, deletePermission, getPengguna } from '../../Rest/api-usermenu'; 
import { getMenuItems } from '../../Rest/api-menu'; // Assuming this fetches available menu items

const Permissions = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userMenus, setUserMenus] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [formData, setFormData] = useState({ menu_item_id: '', can_access: true });

  // Fetch users and menu items on component load
  useEffect(() => {
    fetchUsers();
    fetchMenuItems();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await getPengguna();
      if (response && response.data) {
        const activeUsers = response.data.filter(user => user.active === true); // Filter active users
        setUsers(activeUsers);
      }
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const fetchMenuItems = async () => {
    try {
      const response = await getMenuItems();
      if (response) {
        setMenuItems(response);
      }
    } catch (error) {
      console.error('Failed to fetch menu items:', error);
    }
  };

  const fetchUserMenus = async (userId) => {
    try {
      const response = await getUserMenus(userId);
      setUserMenus(response);
    } catch (error) {
      console.error('Failed to fetch user menus:', error);
    }
  };

  const handleUserSelect = (e) => {
    const userId = e.target.value;
    const user = users.find((u) => u.id === parseInt(userId, 10));
    setSelectedUser(user);
    fetchUserMenus(userId);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddPermission = async () => {
    if (!selectedUser) {
      alert("Please select a user.");
      return;
    }
    
    try {
      const permissionData = { user_id: selectedUser.id, menu_item_id: formData.menu_item_id, can_access: formData.can_access };
      await createPermission(permissionData);
      fetchUserMenus(selectedUser.id); // Refresh user menus after adding permission
    } catch (error) {
      console.error('Failed to add permission:', error);
    }
  };

  const handleDeletePermission = async (menuItemId) => {
    if (!selectedUser) {
      alert("Please select a user.");
      return;
    }

    try {
      await deletePermission(selectedUser.id, menuItemId);
      fetchUserMenus(selectedUser.id); // Refresh user menus after deletion
    } catch (error) {
      console.error('Failed to delete permission:', error);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Manage User Menu Permissions" parent="Settings" title="Permissions" />
      <Container fluid={true}>
        <Row>
          <Col sm="3">
            <SidebarMenu />
          </Col>

          <Col sm="9">
            <Card>
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label for="userSelect">Select User</Label>
                    <Input
                      type="select"
                      name="userSelect"
                      id="userSelect"
                      onChange={handleUserSelect}
                    >
                      <option value="">Select User</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.full_name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Form>

                {selectedUser && (
                  <>
                    <h5 className="mt-4">Add Permission for {selectedUser.full_name}</h5>
                    <Form>
                      <FormGroup>
                        <Label for="menu_item_id">Select Menu Item</Label>
                        <Input
                          type="select"
                          name="menu_item_id"
                          id="menu_item_id"
                          value={formData.menu_item_id}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select Menu Item</option>
                          {menuItems.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="can_access">Can Access</Label>
                        <Input
                          type="select"
                          name="can_access"
                          id="can_access"
                          value={formData.can_access}
                          onChange={handleInputChange}
                          required
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </Input>
                      </FormGroup>
                      <Button color="primary" onClick={handleAddPermission}>
                        Add Permission
                      </Button>
                    </Form>

                    <h5 className="mt-5">Permissions for {selectedUser.full_name}</h5>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Menu Title</th>
                          <th>Path</th>
                          <th>Sub-menus</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userMenus.map((menu, index) => (
                          <tr key={menu.id}>
                            <td>{index + 1}</td>
                            <td>{menu.title}</td>
                            <td>{menu.path || 'N/A'}</td>
                            <td>
                              {menu.children && menu.children.length > 0 ? (
                                <ul>
                                  {menu.children.map((child) => (
                                    <li key={child.id}>{child.title} - {child.path}</li>
                                  ))}
                                </ul>
                              ) : (
                                'No sub-menu'
                              )}
                            </td>
                            <td>
                              <Button color="danger" size="sm" onClick={() => handleDeletePermission(menu.id)}>
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Permissions;
