import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import StokTable from './StokTable';

const Stok = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="List Stok" parent="Dashboard" title="List Stok" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <StokTable />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Stok;
