import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Table, Pagination, PaginationItem, PaginationLink, Input, FormGroup, Tooltip } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import { getTransaction } from '../../../Rest/api-inventori'; // Adjust the import path
import './InventoryPage.css'; // Import custom CSS for styling
import { FiInfo } from 'react-icons/fi'; // Import the info icon from react-icons



const InventoryFlowPage = () => {
  const navigate = useNavigate();
  const [inventories, setInventories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [itemsPerPage, setItemsPerPage] = useState(10); // Items per page state
  const [tooltipOpen, setTooltipOpen] = useState({}); // Tooltip state for multiple icons

  const goToAddInventory = () => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const layoutURL = urlParts[urlParts.length - 1];
    navigate(`${process.env.PUBLIC_URL}/inventory/add/${layoutURL}`);
  };

  const goToKurangInventory = () => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const layoutURL = urlParts[urlParts.length - 1];
    navigate(`${process.env.PUBLIC_URL}/inventory/reduce/${layoutURL}`);
  };

  const goToFlowInventory = () => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const layoutURL = urlParts[urlParts.length - 1];
    navigate(`${process.env.PUBLIC_URL}/inventory/flow/${layoutURL}`);
  };

  // Fetch inventories on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTransaction(); // Call API to get transaction data
        // Sort inventories by createdAt in descending order (newest first)
        const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setInventories(sortedData); // Store the sorted data in state
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchData();
  }, []);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  // Toggle the tooltip
  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // Get current items for the page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = inventories.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(inventories.length / itemsPerPage);

  return (
    <Fragment>
      {/* Breadcrumbs */}
      <Breadcrumbs mainTitle="Flow Inventory" parent="Management" title="Flow Inventory Page" />

      <Container fluid={true}>
        <Row className="mb-3">
          <Col className="d-flex justify-content-between">
            <div>
              <Button className="custom-btn small-btn mr-3" onClick={goToAddInventory}>
                Tambah Inventori
              </Button>
              <Button className="custom-btn small-btn mr-3" onClick={goToKurangInventory}>
                Kurangi Inventori
              </Button>
            </div>
            <div className="ml-auto">
              <Button className="custom-btn small-btn" onClick={goToFlowInventory}>
                Mutasi Stok
              </Button>
            </div>
          </Col>
        </Row>

        {/* Inventory Table */}
        <Row>
          <Col>
            <Table bordered hover responsive className="small-table">
              <thead>
                <tr>
                  <th>Varian Produk</th>
                  <th>Gudang</th>
                  <th>Tanggal Dibuat</th>
                  <th className="text-center">Jumlah Sebelumnya</th>
                  <th className="text-center">Jumlah Berubah</th>
                  <th className="text-center">Jumlah Setelah</th>
                  <th>Penanggung Jawab</th>
                  <th>Kategori</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((inventory, index) => (
                    <tr key={index}>
                      <td>{inventory.variant.product.name} - {inventory.variant.option1_value}</td>
                      <td>{inventory.warehouse.name}</td>
                      <td>{new Date(inventory.createdAt).toLocaleString()}</td>
                      <td className="text-center">{inventory.total_before}</td>
                      <td className="text-center">{inventory.change}</td>
                      <td className="text-center">{inventory.total_after}</td>
                      <td>{inventory.created_by.full_name}</td>
                      <td>
                        {inventory.category}
                        {inventory.notes && (
                          <>
                            <FiInfo
                              id={`tooltip-${index}`}
                              className="ml-2"
                              style={{ color: '#17a2b8', cursor: 'pointer' }}
                            />
                            <Tooltip
                              isOpen={tooltipOpen[`tooltip-${index}`]}
                              toggle={() => toggleTooltip(`tooltip-${index}`)}
                              target={`tooltip-${index}`}
                            >
                              {inventory.notes}
                            </Tooltip>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No data available.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>

        {/* Pagination and Items Per Page */}
        <Row className="d-flex justify-content-between align-items-center">
          <Col md="6">
            <Pagination>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink first onClick={() => handlePageChange(1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
              </PaginationItem>
              {Array.from({ length: totalPages }, (_, index) => (
                <PaginationItem active={currentPage === index + 1} key={index}>
                  <PaginationLink onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink last onClick={() => handlePageChange(totalPages)} />
              </PaginationItem>
            </Pagination>
          </Col>
          <Col md="auto">
            <FormGroup className="d-flex align-items-center">
              <Input
                type="select"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className="mr-2"
                style={{ width: '80px' }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Input>
              <span>/ Halaman</span>
            </FormGroup>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default InventoryFlowPage;
