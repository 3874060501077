// Dashboard.jsx
import React from 'react';
import StatusCard from './StatusCard';
import { FaBox, FaUsers, FaTruck, FaCheckCircle, FaUndoAlt, FaTimesCircle } from 'react-icons/fa';
import './Dashboard.css'; // Import CSS file for styling

const Dashboard = ({ orders = [] }) => {
  const totalOrders = orders.length || 0;
  const belumDiproses = orders.filter(order => order.status === 'Belum Diproses').length || 0;
  const belumDiEkspedisi = orders.filter(order => order.status === 'Belum Di Ekpedisi').length || 0;
  const prosesPengiriman = orders.filter(order => order.status === 'Proses Pengiriman').length || 0;
  const selesai = orders.filter(order => order.status === 'Selesai').length || 0;
  const retur = orders.filter(order => order.status === 'Retur').length || 0;
  const dibatalkan = orders.filter(order => order.status === 'Dibatalkan').length || 0;

  const statusData = [
    { title: 'Semua Paket', count: totalOrders, icon: <FaBox /> },
    { title: 'Belum Diproses', count: belumDiproses, icon: <FaUsers /> },
    { title: 'Belum Di Ekpedisi', count: belumDiEkspedisi, icon: <FaTruck /> },
    { title: 'Proses Pengiriman', count: prosesPengiriman, icon: <FaTruck /> },
    { title: 'Selesai', count: selesai, icon: <FaCheckCircle /> },
    { title: 'Retur', count: retur, icon: <FaUndoAlt /> },
    { title: 'Dibatalkan', count: dibatalkan, icon: <FaTimesCircle /> },
  ];

  return (
    <div className="dashboard">
      {statusData.map((status, index) => (
        <StatusCard key={index} title={status.title} count={status.count} icon={status.icon} />
      ))}
    </div>
  );
};

export default Dashboard;
