import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import HeaderCard from '../../Common/Component/HeaderCard';
import CategoryTable from './CategoryTable'; // Ensure this import path is correct
import AddCategoryForm from './AddCategoryForm'; // Ensure this import path is correct
//import { toast } from 'react-toastify';
import { getCategories } from '../../../Rest/api-keuangan';  // Ensure this import path is correct

const CategoryManagement = () => {
  const [categories, setCategories] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const categories = await getCategories();
      setCategories(categories.map((category, index) => ({
        no: index + 1,
        id: category.id,
        type: category.tipe,
        name: category.nama,
      })));
    } catch (error) {
      //console.error('Error fetching categories:', error);
      //toast.error('Error fetching category data');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Category Management" parent="Dashboard" title="Categories" />
      <Container fluid={true}>
        <Row>
          <Col sm="4">
            <Card>
              <HeaderCard title="Add Category" span1="Add a new product category." />
              <AddCategoryForm fetchData={fetchData} />
            </Card>
          </Col>
          <Col sm="8">
            <Card>
              <HeaderCard title="Category List" span1="Manage all product categories." />
              <CategoryTable categories={categories} fetchData={fetchData} />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CategoryManagement;
