import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPen, FaTrashAlt, FaPlus, FaBox } from 'react-icons/fa';
import { Breadcrumbs } from '../../../AbstractElements';
import { getProductCategories, createProductCategory, updateProductCategory, deleteProductCategory } from '../../../Rest/api-inventory';

const ProductCategoryCard = ({ category, onDelete, onEdit }) => (
  <Card className="mb-3" style={{ minWidth: '250px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', position: 'relative' }}>
    <CardBody className="d-flex align-items-center justify-content-between" style={{ padding: '20px' }}>
      <div>
        <h6 className="mb-1" style={{ fontSize: '0.9rem' }}>Kategori</h6>
        <h5 className="mb-0" style={{ fontWeight: 'bold' }}>{category.name}</h5>
      </div>
      <div className="d-flex align-items-center">
        <FaPen className="mr-3" style={{ cursor: 'pointer', fontSize: '1.2rem', color: '#6c757d' }} onClick={() => onEdit(category)} title="Edit" />
        <div style={{ width: '10px' }}></div> {/* Spacer */}
        <FaTrashAlt style={{ cursor: 'pointer', fontSize: '1.2rem', color: '#dc3545' }} onClick={() => onDelete(category.id)} title="Delete" />
      </div>
    </CardBody>
    <div style={{ position: 'absolute', top: '20px', right: '20px', fontSize: '3rem', color: '#e0e0e0', zIndex: '-1' }}>
      <FaBox />
    </div>
  </Card>
);

const CreateProductCategoryModal = ({ isOpen, toggle, onSave }) => {
  const [categoryName, setCategoryName] = useState('');

  const handleSave = () => {
    if (!categoryName.trim()) {
      toast.error("Category name cannot be empty.");
      return;
    }
    onSave(categoryName);
    setCategoryName('');
    toggle();
  };

  const handleCancel = () => {
    setCategoryName('');
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCancel}>
      <ModalHeader toggle={handleCancel}>Create New Product Category</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="categoryName">Category Name</Label>
          <Input
            id="categoryName"
            name="name"
            placeholder="Enter category name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>Save</Button>
        <Button color="secondary" onClick={handleCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

const EditProductCategoryModal = ({ isOpen, toggle, onSave, initialName }) => {
  const [categoryName, setCategoryName] = useState(initialName);

  useEffect(() => {
    setCategoryName(initialName);
  }, [initialName]);

  const handleSave = () => {
    if (!categoryName.trim()) {
      toast.error("Category name cannot be empty.");
      return;
    }
    onSave(categoryName);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Product Category</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="categoryName">Category Name</Label>
          <Input
            id="categoryName"
            name="name"
            placeholder="Enter new category name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>Save Changes</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

const ConfirmDeleteModal = ({ isOpen, toggle, onConfirmDelete }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirm Deletion</ModalHeader>
      <ModalBody>Are you sure you want to delete this product category?</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onConfirmDelete}>Delete</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

const ProductCategoryList = () => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await getProductCategories();
      setCategories(data);
    } catch (error) {
      //console.error('Failed to fetch categories:', error);
      //toast.error('Failed to fetch product categories.');
    }
  };

  const handleCreateCategory = async (name) => {
    try {
      await createProductCategory(name);
      fetchCategories();
      toast.success("Product category created successfully!");
    } catch (error) {
      console.error('Error creating product category:', error);
      toast.error('Error creating product category.');
    }
    setModal(false);
  };

  const handleEditCategory = async (newName) => {
    try {
      await updateProductCategory(selectedCategory.id, newName);
      fetchCategories();
      toast.success("Product category updated successfully!");
    } catch (error) {
      console.error('Error updating product category:', error);
      toast.error('Error updating product category.');
    }
    setEditModal(false);
  };

  const showEditModal = (category) => {
    setSelectedCategory(category);
    setEditModal(true);
  };

  const showDeleteModal = (categoryId) => {
    setSelectedCategory({ id: categoryId });
    setDeleteModal(true);
  };

  const handleDeleteCategory = async () => {
    try {
      await deleteProductCategory(selectedCategory.id);
      setCategories(categories.filter(category => category.id !== selectedCategory.id)); // Update local state immediately
      toast.success("Product category deleted successfully!");
    } catch (error) {
      console.error('Failed to delete product category:', error);
      toast.error('Failed to delete product category.');
    }
    setDeleteModal(false);
  };

  return (
    <Fragment>
      <ToastContainer />
      <Breadcrumbs mainTitle="Manage Product Categories" parent="Dashboard" title="Product Categories" />
      <Container fluid={true}>
        <Row>
          <Col md="12">
            <Button color="success" className="mb-3" onClick={() => setModal(true)}><FaPlus /> Add Category</Button>
          </Col>
          {categories.map((category) => (
            <Col md="6" lg="4" key={category.id}>
              <ProductCategoryCard category={category} onDelete={showDeleteModal} onEdit={showEditModal} />
            </Col>
          ))}
        </Row>
      </Container>
      <CreateProductCategoryModal isOpen={modal} toggle={() => setModal(false)} onSave={handleCreateCategory} />
      <EditProductCategoryModal isOpen={editModal} toggle={() => setEditModal(false)} onSave={handleEditCategory} initialName={selectedCategory.name || ''} />
      <ConfirmDeleteModal isOpen={deleteModal} toggle={() => setDeleteModal(false)} onConfirmDelete={handleDeleteCategory} />
    </Fragment>
  );
};

export default ProductCategoryList;
