import React, { useState, useEffect } from 'react';
import { Container, Modal, ModalBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import { getProduksiList, deleteProduksi } from '../../Rest/api-produksi';
import { FaTrash, FaInfoCircle } from 'react-icons/fa';
import moment from 'moment';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ColumnConfigModal from './ColumnConfigModal';
import SearchAndFilter from './SearchAndFilter';
import DetailModal from './DetailModal';

const ProduksiTable = () => {
  const [produksi, setProduksi] = useState([]);
  const [filteredProduksi, setFilteredProduksi] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentProduksi, setCurrentProduksi] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [configModal, setConfigModal] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });
  const [columns, setColumns] = useState([
    { name: 'No', selector: (row, index) => index + 1, sortable: false, center: true, grow: 0.1, visible: true },
    { name: 'Kode Produksi', selector: row => row.kode_produksi, sortable: true, visible: true },
    { name: 'Tanggal Produksi', selector: row => moment(row.tanggal_produksi).format('DD-MM-YYYY'), sortable: true, visible: true },
    { 
      name: 'Produk Jadi', 
      selector: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src={row.produkJadi?.images?.[0]?.url || 'default-image-url.jpg'} // Use first image if exists, otherwise fallback
            alt={row.produkJadi?.option1_value || 'Unknown Product'} 
            style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: '5px', cursor: 'pointer' }} 
            onClick={() => {
              if (row.produkJadi?.images?.[0]?.url) {
                setSelectedImage(row.produkJadi.images[0].url); // Set the first image for modal view
                setImageModalOpen(true);
              } else {
                toast.info('No image available');
              }
            }}
          />
          {row.produkJadi?.option1_value || 'Unknown Product'}
        </div>
      ), 
      sortable: true, 
      visible: true, 
      wrap: true 
    },
    { name: 'Jumlah Produk Jadi', selector: row => row.jumlah_produk_jadi, sortable: true, visible: true },
    {
      name: 'Details Bahan',
      selector: row => row.bahanProduksi.map(bahan => (
        <div key={bahan.id}>
          {bahan.bahan?.product?.name || ''} - {bahan.bahan?.option1_value || ''} ({bahan.jumlah_bahan})
        </div>
      )),
      sortable: false,
      visible: true,
      wrap: true
    }
    
    ,
    
    { name: 'Keterangan', selector: row => row.keterangan || 'No description', sortable: true, visible: true },
    { 
      name: 'Actions', 
      cell: row => (
        <div>
          <FaInfoCircle style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => { setCurrentProduksi(row); setDetailModalOpen(true); }} />
          <FaTrash style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setCurrentProduksi(row); toggleDeleteModal(); }} />
        </div>
      ), 
      sortable: false, 
      center: true, 
      grow: 0.1, 
      visible: true 
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProduksiList();
        console.log('Produksi Data:', response); // Debugging
        if (!response || response.length === 0) {
          setProduksi([]);
          setFilteredProduksi([]);
        } else {
          setProduksi(response);
          setFilteredProduksi(response);
          setProducts([...new Set(response.map(item => item.produkJadi))]); // Extract unique products
        }
      } catch (error) {
        console.error('Error fetching productions:', error);
        toast.error('Failed to fetch production data. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleDeleteProduksi = async () => {
    try {
      await deleteProduksi(currentProduksi.id);
      setProduksi(produksi.filter(tx => tx.id !== currentProduksi.id));
      setFilteredProduksi(filteredProduksi.filter(tx => tx.id !== currentProduksi.id));
      toast.success('Production deleted successfully');
      toggleDeleteModal();
    } catch (error) {
      console.error('Failed to delete production:', error);
      toast.error('Failed to delete production. Please try again.');
    }
  };

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleConfigModal = () => setConfigModal(!configModal);
  const toggleImageModal = () => setImageModalOpen(!imageModalOpen);

  const handleConfigChange = (updatedColumns) => {
    setColumns(updatedColumns);
  };

  const handleSearch = (text) => {
    setSearchText(text);
    applyFilters(text, selectedDateRange.startDate, selectedDateRange.endDate);
  };

  const handleFilter = (selectedProduct, startDate, endDate) => {
    applyFilters(searchText, startDate, endDate, selectedProduct);
  };

  const applyFilters = (searchText, startDate, endDate, selectedProduct = '') => {
    const filtered = produksi.filter(item => {
      const matchesSearchText = 
        item.produkJadi?.option1_value?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.kode_produksi.toLowerCase().includes(searchText.toLowerCase()) ||
        (item.keterangan && item.keterangan.toLowerCase().includes(searchText.toLowerCase()));
      const matchesProduct = selectedProduct ? item.produkJadi?.option1_value === selectedProduct : true;
      const matchesDateRange = startDate && endDate ? moment(item.tanggal_produksi).isBetween(startDate, endDate, 'day', '[]') : true;
      return matchesSearchText && matchesProduct && matchesDateRange;
    });
    setFilteredProduksi(filtered);
  };

  const visibleColumns = columns.filter(column => column.visible);

  return (
    <Container fluid={true}>
      <ToastContainer />
      <SearchAndFilter 
        onSearch={handleSearch} 
        onFilter={handleFilter} 
        toggleConfig={toggleConfigModal} 
        products={products} 
      />
      <div className='table-responsive table-bordernone orderHistoryTable'>
        <DataTable
          pagination
          columns={visibleColumns}
          data={filteredProduksi}
          highlightOnHover
          striped
          responsive
        />
      </div>
      <DeleteConfirmationModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        onConfirm={handleDeleteProduksi}
        itemName={currentProduksi ? currentProduksi.produkJadi?.option1_value || 'Unknown' : ''}
      />
      <ColumnConfigModal
        isOpen={configModal}
        toggle={toggleConfigModal}
        columns={columns}
        onSave={handleConfigChange}
      />
      <DetailModal
        isOpen={detailModalOpen}
        toggle={() => setDetailModalOpen(!detailModalOpen)}
        produksi={currentProduksi}
      />
      <Modal isOpen={imageModalOpen} toggle={toggleImageModal} size="lg">
        <ModalBody style={{ textAlign: 'center' }}>
          <img 
            src={selectedImage} 
            alt="Large view" 
            style={{ 
              maxWidth: '100%', 
              maxHeight: '80vh', 
              objectFit: 'contain' 
            }} 
          />
        </ModalBody>
      </Modal>
      <style jsx>{`
        .table-responsive table th,
        .table-responsive table td {
          text-align: center;
          vertical-align: middle;
        }
      `}</style>
    </Container>
  );
};

export default ProduksiTable;
