import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import CustomContext from "../../_helper/Customizer";
import { MENUITEMS, fetchAndAddMenuItems } from "./Menu"; 
import SidebarIcon from "./SidebarIcon";
import SidebarLogo from "./SidebarLogo";
import SidebarMenu from "./SidebarMenu";

const Sidebar = (props) => {
  const customizer = useContext(CustomContext);
  const { toggleIcon } = customizer;
  const id = window.location.pathname.split("/").pop();
  const defaultLayout = Object.keys(customizer.layout);

  const layout = id ? id : defaultLayout;
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [width, setWidth] = useState(0);
  const [loading, setLoading] = useState(true);

  const sidebarRef = useRef(null);

  useEffect(() => {
    const loadMenuItems = async () => {
      setLoading(true); // Start loading

      // Fetch menu items from API and update MENUITEMS
      await fetchAndAddMenuItems();

      // Set the updated MENUITEMS
      setMainMenu(MENUITEMS);

      setLoading(false); // End loading
    };

    loadMenuItems(); // Load menu items after component mounts

    const handleResize = () => {
      setWidth(window.innerWidth - 500);
    };

    const handleScroll = () => {
      if (sidebarRef.current) { // Ensure sidebarRef is not null
        if (window.scrollY > 400) {
          sidebarRef.current.classList.add("hovered");
        } else {
          sidebarRef.current.classList.remove("hovered");
        }
      }
    };

    document.querySelector(".left-arrow")?.classList.add("d-none"); // Optional chaining to avoid null error
    window.addEventListener("resize", handleResize);
    handleResize();

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [layout]);

  const activeClass = () => {
    document.querySelector(".bg-overlay1")?.classList.add("active"); // Optional chaining to avoid null error
  };

  const setNavActive = (item) => {
    MENUITEMS.forEach((menuItem) => {
      menuItem.Items.forEach((menuItem) => {
        if (menuItem !== item) {
          menuItem.active = false;
          document.querySelector(".bg-overlay1")?.classList.remove("active"); // Optional chaining to avoid null error
        }
        if (menuItem.children && menuItem.children.includes(item)) {
          menuItem.active = true;
          document.querySelector(".sidebar-links")?.classList.add("active"); // Optional chaining to avoid null error
        }
        if (menuItem.children) {
          menuItem.children.forEach((submenuItem) => {
            if (submenuItem.children && submenuItem.children.includes(item)) {
              menuItem.active = true;
              submenuItem.active = true;
            }
          });
        }
      });
    });
    item.active = !item.active;
    setMainMenu([...MENUITEMS]);
  };

  const closeOverlay = () => {
    document.querySelector(".bg-overlay1")?.classList.remove("active"); // Optional chaining to avoid null error
    document.querySelector(".sidebar-links")?.classList.remove("active"); // Optional chaining to avoid null error
  };

  if (loading) {
    return null; // Don't render anything until loading is done
  }

  return (
    <Fragment>
      <div className="bg-overlay1" onClick={closeOverlay}></div>
      <div ref={sidebarRef} className={`sidebar-wrapper ${toggleIcon ? "close_icon" : ""}`} sidebar-layout="stroke-svg">
        <SidebarIcon />
        <SidebarLogo />
        <SidebarMenu mainmenu={mainmenu} setMainMenu={setMainMenu} props={props} setNavActive={setNavActive} activeClass={activeClass} width={width} />
      </div>
    </Fragment>
  );
};

export default Sidebar;
