import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";
import { getCookie } from "../Utils/cookies"; // Import getCookie

const Routers = () => {
  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === "compact-wrapper");
  // const layout = getCookie("layout") || Object.keys(defaultLayoutObj).pop();

  // Retrieve the layout from localStorage instead of cookies
  const layout = localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const authStatus = getCookie("authenticated") === "true";
    const loginStatus = getCookie("login") === "true";

    setAuthenticated(authStatus && loginStatus);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={authenticated ? <Navigate to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`} /> : <Signin />} />
          <Route path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
          <Route path="/*" element={<PrivateRoute />}>
            <Route path="*" element={<LayoutRoutes />} />
          </Route>
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={<Component />} key={i} />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
