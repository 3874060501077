import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Correct import statement
import { getCookie } from '../Utils/cookies'; // Adjust the import path as necessary

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility function to get user_id from token
const getUserIdFromToken = () => {
  const token = getCookie('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  }
  return null;
};


export const addUser = async (user) => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const userData = { ...user, id_user: user_id }; // Add userId to the user object
    const response = await api.post('/users', userData);  // Adjust the endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Failed to add user:', error);
    throw error;
  }
};

export const uploadImage = async (formData) => {
  const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
  formData.append('userId', user_id);
  formData.append('description', "UPLOAD IMAGE PROFILE");
  const response = await api.post('/upload/profile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

export const getPengguna = async () => {
  try {
    const response = await api.get('/users');  // Adjust the endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Failed to fetch users:', error);
    throw error;
  }
};


export const updateUser = async (user) => {
  try {
    const response = await api.put(`/users/${user.id}`, user);  // Adjust the endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Failed to update user:', error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await api.delete(`/users/${userId}`);  // Adjust the endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Failed to delete user:', error);
    throw error;
  }
};

export default api;
