import React, { useState, useEffect, Fragment, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from 'reactstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getInventories, getDestination, createOrder } from '../../Rest/api-penjualan';
import { Breadcrumbs } from '../../AbstractElements';
import { useNavigate } from 'react-router-dom';

const formatCurrency = (value) => {
  if (!value) return '';
  return value.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const NewOrder = () => {
   const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_id: '',
    business_id: '1',
    marketplace_id: '',
    order_id: '',
    order_number: '',
    customer: {
      name: '',
      phone_number: '',
      active: true
    },
    details: [
      {
        sku: '',
        name: '',
        jumlah: '1',
        active: true
      }
    ],
    address: {
      address: '',
      sub_district: '',
      district: '',
      province: '',
      active: true
    },
    courier: '',
    status: '',
    payment_method: '',
    tracking_number: '',
    notes: '',
    order_date: getTodayDate(),
    shipping_cost: '',
    product_price: '',
    gross_amount: ''
  });
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [produkJadiOptions, setProdukJadiOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productsResponse, destinationsResponse] = await Promise.all([
          getInventories(),
          getDestination()
        ]);
        setProducts(productsResponse);
        setDestinations(destinationsResponse);
        setProdukJadiOptions(productsResponse);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        toast.error('Failed to fetch data.');
      }
    };

    fetchData();
  }, []);

  const filterDestinations = (inputValue) => {
    return destinations.filter(destination =>
      (destination.province?.toLowerCase().includes(inputValue.toLowerCase())) ||
      (destination.city?.toLowerCase().includes(inputValue.toLowerCase())) ||
      (destination.subdistrict?.toLowerCase().includes(inputValue.toLowerCase()))
    ).map(destination => ({
      value: destination.id,
      label: `${destination.province} - ${destination.city} - ${destination.subdistrict}`,
      ...destination // Include all destination fields in the option object
    }));
  };

  const debouncedFilterDestinations = useCallback(
    debounce((inputValue, callback) => {
      callback(filterDestinations(inputValue));
    }, 300),
    [destinations]
  );

  const loadOptions = (inputValue, callback) => {
    debouncedFilterDestinations(inputValue, callback);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = name === 'shipping_cost' || name === 'product_price' || name === 'gross_amount' ? formatCurrency(value) : value;
    setFormData({
      ...formData,
      [name]: formattedValue
    });
  };

  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      customer: {
        ...formData.customer,
        [name]: value
      }
    });
  };

  const handlePhoneNumberChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
    const formattedValue = numericValue.startsWith('08') ? `62${numericValue.slice(1)}` : numericValue; // Replace leading '08' with '62'
    setFormData({
      ...formData,
      customer: {
        ...formData.customer,
        [name]: formattedValue
      }
    });
  };

  const handleAddressChange = (selectedOption) => {
    setSelectedDestination(selectedOption);
    setFormData({
      ...formData,
      address: {
        ...formData.address,
        destination: selectedOption ? selectedOption.value : '',
        sub_district: selectedOption ? selectedOption.subdistrict : '',
        district: selectedOption ? selectedOption.city : '',
        province: selectedOption ? selectedOption.province : ''
      }
    });
  };

  const handleDetailChange = (e, index) => {
    const { name, value } = e.target;
    const details = [...formData.details];
    details[index][name] = value;
    setFormData({ ...formData, details });
  };

const handleSelectChange = (selectedOption, index) => {
  // Find the product that contains the variant
  let selectedProduct;
  let selectedVariant;
  
  // Loop through the products and find the matching variant by selectedOption.value
  products.forEach(product => {
    const variant = product.variants.find(variant => variant.id === selectedOption.value);
    if (variant) {
      selectedProduct = product;
      selectedVariant = variant;
    }
  });

  if (selectedProduct && selectedVariant) {
    const details = [...formData.details];
    details[index] = {
      sku: selectedVariant.sku,
      name: `${selectedProduct.name} - ${selectedVariant.option1_value}`, // Combine product and variant names
      jumlah: details[index].jumlah,
      active: true
    };
    setFormData({ ...formData, details });
  }
};


  const addDetail = () => {
    setFormData({
      ...formData,
      details: [...formData.details, { sku: '', name: '', jumlah: '', active: true }]
    });
  };

  const removeDetail = (index) => {
    const details = formData.details.filter((_, i) => i !== index);
    setFormData({ ...formData, details });
  };

  const stripCurrencyFormat = (value) => {
    return value.replace(/\./g, '');
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const strippedFormData = {
        ...formData,
        shipping_cost: stripCurrencyFormat(formData.shipping_cost),
        product_price: stripCurrencyFormat(formData.product_price),
        gross_amount: stripCurrencyFormat(formData.gross_amount),
        details: formData.details.map(detail => ({
          ...detail,
          jumlah: stripCurrencyFormat(detail.jumlah)
        }))
      };

      await createOrder(strippedFormData);
      toast.success('Order submitted successfully!');
      // Reset form after successful submission
      setFormData({
        user_id: '1',
        business_id: '1',
        marketplace_id: '',
        order_id: '',
        order_number: '',
        customer: {
          name: '',
          phone_number: '',
          active: true
        },
        details: [
          {
            sku: '',
            name: '',
            jumlah: '',
            active: true
          }
        ],
        address: {
          address: '',
          sub_district: '',
          district: '',
          province: '',
          active: true
        },
        courier: '',
        status: '',
        payment_method: '',
        tracking_number: '',
        notes: '',
        order_date: getTodayDate(),
        shipping_cost: '',
        product_price: '',
        gross_amount: ''
      });

      // Extract the layout URL parameter from the current URL
      const currentURL = window.location.href;
      const urlParts = currentURL.split('/');
      const layoutURL = urlParts[urlParts.length - 1];

      navigate(`${process.env.PUBLIC_URL}/list-penjualan/${layoutURL}`);
    } catch (error) {
      console.error('Submit failed:', error);
      toast.error('Submission failed!');
    } finally {
      setLoading(false);
    }
  };

  const productOptions = products.map(product => ({
    value: product.id,
    label: `${product.name}`
  }));
  
  const produkJadiSelectOptions = produkJadiOptions.flatMap(produkJadi => 
    produkJadi.variants.map(variant => ({
      value: variant.id,
      label: `${produkJadi.name} - ${variant.option1_value}`
    }))
  );

  const marketplaceOptions = [
    { value: '1', label: 'SHOPEE' },
    { value: '2', label: 'TOKOPEDIA' },
    { value: '3', label: 'TIKTOK' }
  ];

  const paymentMethodOptions = [
    { value: 'TRANSFER', label: 'TRANSFER' },
    { value: 'COD', label: 'COD' },
    { value: 'LAINNYA', label: 'LAINNYA' }
  ];

  const statusOptions = [
    { value: 'Belum Diproses', label: 'Belum Diproses' },
    { value: 'Belum Di Ekpedisi', label: 'Belum Di Ekpedisi' },
    { value: 'Proses Pengiriman', label: 'Proses Pengiriman' },
    { value: 'Selesai', label: 'Selesai' },
    { value: 'Retur', label: 'Retur' },
    { value: 'Dibatalkan', label: 'Dibatalkan' }
  ];

  const courierOptions = [
    { value: 'JNT', label: 'JNT' },
    { value: 'JNE', label: 'JNE' },
    { value: 'IDEXPRESS', label: 'IDEXPRESS' },
    { value: 'NINJA', label: 'NINJA' }
  ];

  return (
    <Fragment>
      <Breadcrumbs mainTitle="New Order" parent="Dashboard" title="New Order" />
      <Container fluid={true}>
        <ToastContainer />
        <Row className='g-sm-3'>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Order Details</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="6">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="order_date">Order Date</Label>
                            <Input
                              type="date"
                              name="order_date"
                              id="order_date"
                              value={formData.order_date}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="marketplace_id">Marketplace</Label>
                            <Select
                              options={marketplaceOptions}
                              value={marketplaceOptions.find(option => option.value === formData.marketplace_id)}
                              onChange={(selectedOption) => setFormData({ ...formData, marketplace_id: selectedOption.value })}
                              isClearable
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="courier">Courier</Label>
                            <Select
                              options={courierOptions}
                              value={courierOptions.find(option => option.value === formData.courier)}
                              onChange={(selectedOption) => setFormData({ ...formData, courier: selectedOption.value })}
                              isClearable
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="payment_method">Payment Method</Label>
                            <Select
                              options={paymentMethodOptions}
                              value={paymentMethodOptions.find(option => option.value === formData.payment_method)}
                              onChange={(selectedOption) => setFormData({ ...formData, payment_method: selectedOption.value })}
                              isClearable
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="order_number">Order Number</Label>
                            <Input
                              type="text"
                              name="order_number"
                              id="order_number"
                              value={formData.order_number}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="tracking_number">Tracking Number</Label>
                            <Input
                              type="text"
                              name="tracking_number"
                              id="tracking_number"
                              value={formData.tracking_number}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="customer_name">Customer Name</Label>
                            <Input
                              type="text"
                              name="name"
                              id="customer_name"
                              value={formData.customer.name}
                              onChange={handleCustomerChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="customer_phone">Customer Phone Number</Label>
                            <Input
                              type="text"
                              name="phone_number"
                              id="customer_phone"
                              value={formData.customer.phone_number}
                              onChange={handlePhoneNumberChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="destination">Destination</Label>
                        <AsyncSelect
                          cacheOptions
                          loadOptions={loadOptions}
                          defaultOptions={false}
                          value={selectedDestination}
                          onChange={handleAddressChange}
                          isClearable
                          noOptionsMessage={() => 'ketik kec atau kota'}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="address">Address</Label>
                        <Input
                          type="textarea"
                          name="address"
                          id="address"
                          value={formData.address.address}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <div>
                        <Row>
                          <Col md="12">
                            <Label>Produk</Label>
                          </Col>
                        </Row>
                        {formData.details.map((detail, index) => (
                          <Fragment key={index}>
                            <Row className="mb-2">
                              <Col md="8">
                                <Select
                                key={index}
                                options={produkJadiSelectOptions}
                                value={produkJadiSelectOptions.find(option => option.value === detail.produk_jadi_id)}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
                                isClearable
                              />
                              </Col>
                              <Col md="2">
                                <Input
                                  type="number"
                                  name="jumlah"
                                  placeholder="Jumlah"
                                  value={detail.jumlah}
                                  onChange={(e) => handleDetailChange(e, index)}
                                />
                              </Col>
                              <Col md="2">
                                {index === 0 && (
                                  <Button color="primary" size="sm" onClick={addDetail}>+</Button>
                                )}
                                {index !== 0 && (
                                  <Button color="danger" size="sm" onClick={() => removeDetail(index)}>&times;</Button>
                                )}
                              </Col>
                            </Row>
                          </Fragment>
                        ))}
                      </div>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="shipping_cost">Shipping Cost</Label>
                            <Input
                              type="text"
                              name="shipping_cost"
                              id="shipping_cost"
                              value={formData.shipping_cost}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="product_price">Product Price</Label>
                            <Input
                              type="text"
                              name="product_price"
                              id="product_price"
                              value={formData.product_price}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="gross_amount">Gross Amount</Label>
                            <Input
                              type="text"
                              name="gross_amount"
                              id="gross_amount"
                              value={formData.gross_amount}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="status">Status</Label>
                            <Select
                              options={statusOptions}
                              value={statusOptions.find(option => option.value === formData.status)}
                              onChange={(selectedOption) => setFormData({ ...formData, status: selectedOption.value })}
                              isClearable
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="notes">Notes</Label>
                        <Input
                          type="textarea"
                          name="notes"
                          id="notes"
                          value={formData.notes}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col className="text-right">
                    <Button color="primary" onClick={handleSubmit} disabled={loading}>
                      {loading ? <Spinner size="sm" /> : 'Submit'}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewOrder;
