import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import ProductTable from './ProductTable';
import { useNavigate } from 'react-router-dom';

const Sample = () => {
  const navigate = useNavigate();

  const handleCreateProduct = () => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const layoutURL = urlParts[urlParts.length - 1];
    navigate(`${process.env.PUBLIC_URL}/createproduct/${layoutURL}`);
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Product List" parent="Dashboard" title="Product List" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
          <Col md="12">
            <Button color="success" className="mb-3" onClick={handleCreateProduct}>Create Product</Button>
          </Col>
            <Card>
              <CardBody>
                <ProductTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Sample;
