import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/id'; // Import Indonesian locale for moment

moment.locale('id'); // Set the locale to Indonesian

const DetailModal = ({ isOpen, toggle, produksi }) => {
  if (!produksi) return null;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Detail Produksi</ModalHeader>
      <ModalBody>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ flex: '1', marginRight: '20px', textAlign: 'center' }}>
            {/* Check if produkJadi has images and display the first one */}
            {produksi.produkJadi.images?.length > 0 ? (
              <img 
                src={produksi.produkJadi.images[0]?.url} 
                alt={produksi.produkJadi.name} 
                style={{ width: '50%', borderRadius: '8px', display: 'block', margin: '0 auto' }} 
              />
            ) : (
              <p>Image not available</p>
            )}
            <p style={{ textAlign: 'center', marginTop: '10px', fontWeight: 'bold' }}>
              Produk Jadi: {produksi.produkJadi.product?.name}
            </p>
          </div>
          <div style={{ flex: '1' }}>
            <p><strong>Kode Produksi:</strong> {produksi.kode_produksi}</p>
            <p><strong>Tanggal Produksi:</strong> {moment(produksi.tanggal_produksi).format('DD-MM-YYYY')}</p>
            <p><strong>Jumlah Produk Jadi:</strong> {produksi.jumlah_produk_jadi}</p>
            <p><strong>Keterangan:</strong> {produksi.keterangan || 'Tidak ada keterangan'}</p>

            <p><strong>Bahan Produksi:</strong></p>
            <ul style={{ paddingLeft: '20px' }}>
              {produksi.bahanProduksi.length > 0 ? (
                produksi.bahanProduksi.map(bahan => (
                  <li key={bahan.id} style={{ marginBottom: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {/* Check if bahan has images and display the first one */}
                      {bahan.bahan.images?.length > 0 ? (
                        <img 
                          src={bahan.bahan.images[0]?.url} 
                          alt={bahan.bahan.product?.name || 'Unknown'} 
                          style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius: '5px' }} 
                        />
                      ) : (
                        <p style={{ marginRight: '10px' }}>No image</p>
                      )}
                      {bahan.bahan.product?.name || 'Unknown Product'} - {bahan.bahan.option1_value || 'Unknown Variant'} ({bahan.jumlah_bahan})
                    </div>
                  </li>
                ))
              ) : (
                <p>No materials used</p>
              )}
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Tutup</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DetailModal;
