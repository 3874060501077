import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from '../Utils/cookies';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility function to get user_id from token
const getUserIdFromToken = () => {
  const token = getCookie('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  }
  return null;
};

if (getUserIdFromToken){}
// API calls for UserMenuPermissions

// Get all menus accessible by a user
export const getUserMenus = async (userId) => {
  try {
    const response = await api.get(`/usermenu/users/${userId}/menus`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch user menus:', error);
    throw error;
  }
};

export const getPengguna = async () => {
    try {
      const response = await api.get('/users');  // Adjust the endpoint as necessary
      return response.data;
    } catch (error) {
      console.error('Failed to fetch users:', error);
      throw error;
    }
  };

// Create a new permission
export const createPermission = async (permissionData) => {
  try {
    const response = await api.post('/usermenu/permissions', permissionData);
    return response.data;
  } catch (error) {
    console.error('Failed to create permission:', error);
    throw error;
  }
};

// Update an existing permission
export const updatePermission = async (permissionData) => {
  try {
    const response = await api.put('/usermenu/permissions', permissionData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update permission:', error);
    throw error;
  }
};

// Delete a permission
export const deletePermission = async (userId, menuItemId) => {
  try {
    const response = await api.delete(`/usermenu/permissions/${userId}/${menuItemId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete permission:', error);
    throw error;
  }
};

export default api;
