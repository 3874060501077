import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import { getPengguna, deleteUser } from '../../../Rest/api-pengguna'; // Adjust the import path as needed
import ColumnConfigModal from './ColumnConfigModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';
import SearchAndFilter from './SearchAndFilter';
import { FaEdit, FaTrash } from 'react-icons/fa';

const defaultImage = "https://res.cloudinary.com/dtimsje1o/image/upload/v1720937403/inventory_photos/2024-07-14T06-09-59.691Z-ASDS.jpg.jpg";

const UserTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [configModal, setConfigModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false); // New state for EditUserModal
  const [currentUser, setCurrentUser] = useState(null);

  const [columns, setColumns] = useState([
    { name: 'No', selector: (row, index) => index + 1, sortable: false, center: true, grow: 0.1, visible: true },
    { 
      name: 'User Photo', 
      selector: row => (
        <img 
          src={row.profile_image || defaultImage} 
          onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }}
          alt={row.full_name} 
          width="50" 
          height="50" 
        />
      ), 
      sortable: false, 
      center: true, 
      grow: 0.1, 
      visible: true 
    },
    { name: 'Full Name', selector: row => row.full_name, sortable: true, visible: true },
    { name: 'Email', selector: row => row.email, sortable: true, visible: true },
    { name: 'Phone Number', selector: row => row.phone_number, sortable: true, visible: true },
    { name: 'Role', selector: row => row.role, sortable: true, visible: true },
    { name: 'Active', selector: row => (row.active ? 'Active' : 'Inactive'), sortable: true, visible: true },
    { 
      name: 'Actions', 
      cell: row => (
        <div>
          <FaEdit style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => { setCurrentUser(row); setEditUserModal(true); }} />
          <FaTrash style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setCurrentUser(row); setDeleteModal(true); }} />
        </div>
      ), 
      sortable: false, 
      center: true, 
      grow: 0.1, 
      visible: true 
    },
  ]);

  const toggleConfig = () => setConfigModal(!configModal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleAddUserModal = () => setAddUserModal(!addUserModal);
  const toggleEditUserModal = () => setEditUserModal(!editUserModal); // New function to toggle EditUserModal

  const handleDelete = async () => {
    try {
      await deleteUser(currentUser.id);
      setData(data.filter(user => user.id !== currentUser.id));
      setFilteredData(filteredData.filter(user => user.id !== currentUser.id));
      toast.success('User deleted successfully');
      toggleDeleteModal();
    } catch (error) {
      console.error('Failed to delete user:', error);
      toast.error('Failed to delete user. Please try again.');
    }
  };

  const handleAddUser = (newUser) => {
    setData([newUser, ...data]);
    setFilteredData([newUser, ...data]);
  };

  const handleUpdateUser = (updatedUser) => {
    setData(data.map(user => user.id === updatedUser.id ? updatedUser : user));
    setFilteredData(filteredData.map(user => user.id === updatedUser.id ? updatedUser : user));
  };

  const handleFilter = (status) => {
    if (status === 'all') {
      setFilteredData(data);
    } else {
      const isActive = status === 'active';
      setFilteredData(data.filter(user => user.active === isActive));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPengguna();
        const users = response.data; // Access the data array within the response
        const formattedData = users.map(user => ({
          id: user.id,
          profile_image: user.profile_image || defaultImage,
          full_name: user.full_name,
          email: user.email,
          phone_number: user.phone_number,
          role: user.role,
          active: user.active,
        }));
        setData(formattedData);
        setFilteredData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch user data. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleSearch = (searchText) => {
    setFilteredData(data.filter(item =>
      item.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.email.toLowerCase().includes(searchText.toLowerCase())
    ));
  };

  const tableColumns = columns.filter(col => col.visible);

  return (
    <Container fluid={true}>
      <SearchAndFilter
        onSearch={handleSearch}
        onFilter={handleFilter}
        toggleConfig={toggleConfig}
        toggleAddUser={toggleAddUserModal}
      />
      <div className='table-responsive table-bordernone orderHistoryTable user-table'>
        <DataTable
          pagination
          columns={tableColumns}
          data={filteredData}
          highlightOnHover
          striped
          responsive
        />
      </div>
      <ColumnConfigModal
        isOpen={configModal}
        toggle={toggleConfig}
        columns={columns}
        onSave={setColumns}
      />
      <DeleteConfirmationModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        onConfirm={handleDelete}
        userName={currentUser ? currentUser.full_name : ''}
      />
      <AddUserModal
        isOpen={addUserModal}
        toggle={toggleAddUserModal}
        onAddUser={handleAddUser} // Pass the handleAddUser function as a prop
      />
      <EditUserModal
        isOpen={editUserModal}
        toggle={toggleEditUserModal}
        user={currentUser}
        onUpdateUser={handleUpdateUser} // Pass the handleUpdateUser function as a prop
      />
      <style jsx>{`
        .table-responsive table th,
        .table-responsive table td {
          text-align: center;
          vertical-align: middle;
        }
        .table-responsive table img {
          border-radius: 4px;
        }
      `}</style>
    </Container>
  );
};

export default UserTable;
