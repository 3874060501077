import React, { Fragment } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import PembelianTable from './PembelianTable';



const Pembelian = () => {
  const navigate = useNavigate();

  const handleCreate = () => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const layoutURL = urlParts[urlParts.length - 1];
    navigate(`${process.env.PUBLIC_URL}/new-pembelian/${layoutURL}`);
  }
  return (
    <Fragment>
      <Breadcrumbs mainTitle="List Pembelian" parent="Dashboard" title="List Pembelian" />
      <Container fluid={true}>
        <Row>
 
          <Col sm="12">
            <Button color="success" className="mb-3" onClick={handleCreate} style={{ marginLeft: '10px' }}>
              Buat Pembelian
            </Button>
            
            <PembelianTable />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Pembelian;
