import React, { useState, useEffect, Fragment } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from 'reactstrap';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getInventories, createProduksi, getProdukJadi, getAllLocations } from '../../Rest/api-produksi';
import { Breadcrumbs } from '../../AbstractElements';
import { useNavigate } from 'react-router-dom';

// Helper function to get today's date in YYYY-MM-DD format
const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const NewProduksi = () => {
  const navigate = useNavigate();
  
  // Initialize form data state
  const [formData, setFormData] = useState({
    kode_produksi: '',
    tanggal_produksi: getTodayDate(),
    produk_jadi_id: '',
    jumlah_produk_jadi: '1',
    keterangan: '',
    gudang: '',  // Store selected warehouse
    bahan: [
      {
        bahan_id: '',
        jumlah_bahan: '1',
        satuan: '',
        active: true
      }
    ],
    active: true
  });
  
  // Loading state and options data for dropdowns
  const [loading, setLoading] = useState(false);
  const [bahanOptions, setBahanOptions] = useState([]);
  const [produkJadiOptions, setProdukJadiOptions] = useState([]);
  const [gudangOptions, setGudangOptions] = useState([]);

  // Fetching Bahan Baku and Produk Jadi data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [bahanResponse, produkJadiResponse] = await Promise.all([
          getInventories(),  // Fetch "Bahan Baku"
          getProdukJadi()    // Fetch "Produk Jadi"
        ]);
        setBahanOptions(bahanResponse);
        setProdukJadiOptions(produkJadiResponse);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        toast.error('Failed to fetch data.');
      }
    };
    fetchData();
  }, []);

  // Fetch Gudang (warehouse) options
  useEffect(() => {
    const fetchGudangData = async () => {
      try {
        const locations = await getAllLocations();
        const options = locations.map(location => ({
          value: location.id,
          label: location.name
        }));
        setGudangOptions(options);
      } catch (error) {
        toast.error('Error fetching Gudang data');
        console.error('Error fetching Gudang data:', error);
      }
    };
    fetchGudangData();
  }, []);

  // Handle input changes for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle changes for Bahan (materials) fields
  const handleDetailChange = (e, index) => {
    const { name, value } = e.target;
    const bahan = [...formData.bahan];
    bahan[index][name] = value;
    setFormData({ ...formData, bahan });
  };

  // Handle selection of bahan variants
  const handleSelectChange = (selectedOption, index) => {
    const selectedBahan = bahanOptions.flatMap(bahan =>
      bahan.variants ? bahan.variants.map(variant => ({
        ...bahan,
        variant
      })) : [bahan]
    ).find(bahan => bahan.variant ? bahan.variant.id === selectedOption.value : bahan.id === selectedOption.value);
  
    const bahan = [...formData.bahan];
    bahan[index] = {
      bahan_id: selectedBahan.variant ? selectedBahan.variant.id : selectedBahan.id,
      jumlah_bahan: bahan[index].jumlah_bahan,
      satuan: selectedBahan.satuan || '', // Assuming satuan is available
      active: true
    };
    setFormData({ ...formData, bahan });
  };

  // Add a new material entry
  const addDetail = () => {
    setFormData({
      ...formData,
      bahan: [...formData.bahan, { bahan_id: '', jumlah_bahan: '', satuan: '', active: true }]
    });
  };

  // Remove a material entry
  const removeDetail = (index) => {
    const bahan = formData.bahan.filter((_, i) => i !== index);
    setFormData({ ...formData, bahan });
  };

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await createProduksi(formData);
      toast.success('Produksi submitted successfully!');
      
      // // Reset form after submission
      // setFormData({
      //   kode_produksi: '',
      //   tanggal_produksi: getTodayDate(),
      //   produk_jadi_id: '',
      //   jumlah_produk_jadi: '1',
      //   keterangan: '',
      //   gudang: '',  // Reset gudang field
      //   bahan: [
      //     {
      //       bahan_id: '',
      //       jumlah_bahan: '1',
      //       satuan: '',
      //       active: true
      //     }
      //   ],
      //   active: true
      // });

      // Extract the layout URL parameter from the current URL
      const currentURL = window.location.href;
      const urlParts = currentURL.split('/');
      const layoutURL = urlParts[urlParts.length - 1];

        navigate(`${process.env.PUBLIC_URL}/produksi/${layoutURL}`);
      // Navigate to the production list
      //navigate(`${process.env.PUBLIC_URL}/produksi`);
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Submission failed!';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Options mapping for Bahan and Produk Jadi
  const bahanSelectOptions = bahanOptions.flatMap(bahan =>
    bahan.variants ? bahan.variants.map(variant => ({
      value: variant.id,
      label: `${bahan.name} - ${variant.option1_value}`
    })) : [{
      value: bahan.id,
      label: `${bahan.name}`
    }]
  );

  const produkJadiSelectOptions = produkJadiOptions.flatMap(produkJadi => 
    produkJadi.variants.map(variant => ({
      value: variant.id,
      label: `${produkJadi.name} - ${variant.option1_value}`
    }))
  );

  return (
    <Fragment>
      <Breadcrumbs mainTitle="New Produksi" parent="Dashboard" title="New Produksi" />
      <Container fluid={true}>
        <ToastContainer />
        <Row className='g-sm-3'>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Produksi Details</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="kode_produksi">Kode Produksi</Label>
                        <Input
                          type="text"
                          name="kode_produksi"
                          id="kode_produksi"
                          value={formData.kode_produksi}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="tanggal_produksi">Tanggal Produksi</Label>
                        <Input
                          type="date"
                          name="tanggal_produksi"
                          id="tanggal_produksi"
                          value={formData.tanggal_produksi}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="gudang">Gudang *</Label>
                        <Select
                          options={gudangOptions}
                          value={gudangOptions.find(option => option.value === formData.gudang)}
                          onChange={(selectedOption) => setFormData({ ...formData, gudang: selectedOption.value })}
                          placeholder="Pilih Gudang"
                          isSearchable
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="produk_jadi_id">Produk Jadi</Label>
                        <Select
                          options={produkJadiSelectOptions}
                          value={produkJadiSelectOptions.find(option => option.value === formData.produk_jadi_id)}
                          onChange={(selectedOption) => setFormData({ ...formData, produk_jadi_id: selectedOption.value })}
                          isClearable
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="jumlah_produk_jadi">Jumlah Produk Jadi</Label>
                        <Input
                          type="number"
                          name="jumlah_produk_jadi"
                          id="jumlah_produk_jadi"
                          value={formData.jumlah_produk_jadi}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="keterangan">Keterangan</Label>
                        <Input
                          type="textarea"
                          name="keterangan"
                          id="keterangan"
                          value={formData.keterangan}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <div>
                        <Row>
                          <Col md="12">
                            <Label>Bahan</Label>
                          </Col>
                        </Row>
                        {formData.bahan.map((detail, index) => (
                          <Fragment key={index}>
                            <Row className="mb-2">
                              <Col md="7">
                                <Select
                                  options={bahanSelectOptions}
                                  value={bahanSelectOptions.find(option => option.value === detail.bahan_id)}
                                  onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
                                  isClearable
                                />
                              </Col>
                              <Col md="2">
                                <Input
                                  type="number"
                                  name="jumlah_bahan"
                                  placeholder="Jumlah"
                                  value={detail.jumlah_bahan}
                                  onChange={(e) => handleDetailChange(e, index)}
                                />
                              </Col>
                              <Col md="1" className="d-flex align-items-center">
                                <span>{detail.satuan}</span> {/* Display the satuan */}
                              </Col>
                              <Col md="2" className="mt-2">
                                {index === 0 && (
                                  <Button color="primary" size="sm" onClick={addDetail}>+</Button>
                                )}
                                {index !== 0 && (
                                  <Button color="danger" size="sm" onClick={() => removeDetail(index)}>&times;</Button>
                                )}
                              </Col>
                            </Row>
                          </Fragment>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col className="text-right">
                    <Button color="primary" onClick={handleSubmit} disabled={loading}>
                      {loading ? <Spinner size="sm" /> : 'Submit'}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewProduksi;
