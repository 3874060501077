import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';

const EditBankModal = ({ isOpen, toggle, onEdit, currentBank }) => {
  const [namaBank, setNamaBank] = useState('');
  const [nomorRekening, setNomorRekening] = useState('');
  const [pemilikRekening, setPemilikRekening] = useState('');
  const [saldo, setSaldo] = useState('');

  useEffect(() => {
    if (currentBank) {
      setNamaBank(currentBank.nama_bank);
      setNomorRekening(currentBank.nomor_rekening);
      setPemilikRekening(currentBank.pemilik_rekening);
      setSaldo(currentBank.saldo);
    }
  }, [currentBank]);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const updatedBank = {
      id: currentBank.id,
      nama_bank: namaBank,
      nomor_rekening: nomorRekening,
      pemilik_rekening: pemilikRekening,
      saldo: saldo,
    };

    onEdit(updatedBank);
    toggle();

    // Clear form fields after submission
    setNamaBank('');
    setNomorRekening('');
    setPemilikRekening('');
    setSaldo('');
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Bank Account</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="namaBank">Nama Bank</Label>
            <Input 
              type="text" 
              id="namaBank" 
              value={namaBank} 
              onChange={e => setNamaBank(e.target.value)} 
              required 
            />
          </FormGroup>
          <FormGroup>
            <Label for="nomorRekening">Nomor Rekening</Label>
            <Input 
              type="text" 
              id="nomorRekening" 
              value={nomorRekening} 
              onChange={e => setNomorRekening(e.target.value)} 
              required 
            />
          </FormGroup>
          <FormGroup>
            <Label for="pemilikRekening">Pemilik Rekening</Label>
            <Input 
              type="text" 
              id="pemilikRekening" 
              value={pemilikRekening} 
              onChange={e => setPemilikRekening(e.target.value)} 
              required 
            />
          </FormGroup>
          <FormGroup>
            <Label for="saldo">Saldo</Label>
            <Input 
              type="text" 
              id="saldo" 
              value={saldo} 
              onChange={e => setSaldo(e.target.value)} 
            />
          </FormGroup>
          <ModalFooter>
            <Button color="primary" type="submit">Save Changes</Button>
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EditBankModal;
