import React, { Fragment, useState, useCallback, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { Breadcrumbs, H5 } from '../../AbstractElements';
import { getProducts, createPembelian, uploadImage } from '../../Rest/api-pembelian';
import SellerModal from './SellerModal'; // Import the SellerModal component
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

      
     
const formatCurrency = (value) => {
  if (!value) return '';
  return value.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const NewPembelian = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tanggal: getTodayDate(),
    seller_id: '',
    seller_name: '',
    metode_pembelian: '',
    pembayaran: '',
    ongkir: '',
    diskon: '',
    total: '',
    bukti_pembayaran: null,
    bukti_pembelian: null,
    harga_total: '',
    status_pesanan: '',
    catatan: '',
    active: true,
    details: [
      {
        inventori_id: '',
        harga: '',
        jumlah: '',
        satuan: ''
      }
    ]
  });
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Correctly define errorMessage and setErrorMessage
  const [isSellerModalOpen, setSellerModalOpen] = useState(false);
  const [products, setProducts] = useState([]);

  if(errorMessage){
    
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await getProducts();
      setProducts(response);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const toggleSellerModal = () => {
    setSellerModalOpen(!isSellerModalOpen);
  };

  const handleSelectSeller = (id, name) => {
    setFormData({
      ...formData,
      seller_id: id,
      seller_name: name
    });
    toggleSellerModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = name === 'ongkir' || name === 'diskon' || name === 'total' || name === 'harga_total' ? formatCurrency(value) : value;
    setFormData({
      ...formData,
      [name]: formattedValue
    });
  };

  const handleDetailChange = (e, index) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Format only specific fields as currency
    if (name === 'harga' || name === 'jumlah') {
      formattedValue = formatCurrency(value);
    }

    const details = [...formData.details];
    details[index][name] = formattedValue;
    setFormData({ ...formData, details });
  };

  const handleSelectChange = (selectedOption, index) => {
    const product = products.find(product => product.id === selectedOption.value);
    const details = [...formData.details];
    details[index].inventori_id = selectedOption ? selectedOption.value : '';
    details[index].harga = product ? formatCurrency(product.hpp) : '';
    setFormData({ ...formData, details });
  };

  const addDetail = () => {
    setFormData({
      ...formData,
      details: [...formData.details, { inventori_id: '', harga: '', jumlah: '', satuan: '' }]
    });
  };

  const removeDetail = (index) => {
    const details = formData.details.filter((_, i) => i !== index);
    setFormData({ ...formData, details });
  };

  const resetForm = () => {
    setFormData({
      tanggal: getTodayDate(),
      seller_id: '',
      seller_name: '',
      metode_pembelian: '',
      pembayaran: '',
      ongkir: '',
      diskon: '',
      total: '',
      bukti_pembayaran: null,
      bukti_pembelian: null,
      harga_total: '',
      status_pesanan: '',
      catatan: '',
      active: true,
      details: [
        {
          inventori_id: '',
          harga: '',
          jumlah: '',
          satuan: ''
        }
      ]
    });
    setLoading(false);
    setResponseMessage('');
    setUploadStatus('');
    setErrorMessage(''); // Reset error message
  };

  const handleSubmit = async () => {
    if (!loading) {
      setLoading(true);
      try {
        // Upload bukti pembayaran
        let buktiPembayaranUrl = '';
        if (formData.bukti_pembayaran) {
          const buktiPembayaranResponse = await uploadImage(
            'Bukti Pembayaran',
            formData.bukti_pembayaran.file
          );
          buktiPembayaranUrl = buktiPembayaranResponse.photoUrl;
        }

        // Upload bukti pembelian
        let buktiPembelianUrl = '';
        if (formData.bukti_pembelian) {
          const buktiPembelianResponse = await uploadImage(
            'Bukti Pembelian',
            formData.bukti_pembelian.file
          );
          buktiPembelianUrl = buktiPembelianResponse.photoUrl;
        }

        // Prepare form data with the image URLs
        const pembelianData = {
          ...formData,
          ongkir: formData.ongkir.replace(/\./g, ''),
          diskon: formData.diskon.replace(/\./g, ''),
          total: formData.total.replace(/\./g, ''),
          harga_total: formData.harga_total.replace(/\./g, ''),
          details: formData.details.map(detail => ({
            ...detail,
            harga: detail.harga.replace(/\./g, ''),
            jumlah: detail.jumlah.replace(/\./g, '')
          })),
          bukti_pembayaran: buktiPembayaranUrl,
          bukti_pembelian: buktiPembelianUrl,
        };

        // Extract the layout URL parameter from the current URL
      const currentURL = window.location.href;
      const urlParts = currentURL.split('/');
      const layoutURL = urlParts[urlParts.length - 1];

        // Submit the form data
        const response = await createPembelian(pembelianData);
        toast.success('Submission Successful!');
        navigate(`${process.env.PUBLIC_URL}/pembelian/${layoutURL}`);
        console.log(response);
        resetForm(); // Reset form only on successful submission
      } catch (error) {
        console.error('Submit failed:', error);
        setResponseMessage(error.response?.data.message || 'Unknown error');
        setErrorMessage(error.response?.data.message || 'Unknown error'); // Set the error message
        toast.error(error.response?.data.message || 'Unknown error'); // Show toast notification on error
      } finally {
        setLoading(false);
      }
    }
  };

  const onDropPembayaran = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFormData((prevState) => ({
      ...prevState,
      bukti_pembayaran: {
        file,
        preview: URL.createObjectURL(file)
      }
    }));
  }, []);

  const onDropPembelian = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFormData((prevState) => ({
      ...prevState,
      bukti_pembelian: {
        file,
        preview: URL.createObjectURL(file)
      }
    }));
  }, []);

  const removeFilePembayaran = () => {
    setFormData((prevState) => ({
      ...prevState,
      bukti_pembayaran: null
    }));
  };

  const removeFilePembelian = () => {
    setFormData((prevState) => ({
      ...prevState,
      bukti_pembelian: null
    }));
  };

  const { getRootProps: getRootPropsPembayaran, getInputProps: getInputPropsPembayaran } = useDropzone({
    onDrop: onDropPembayaran,
    accept: 'image/*',
    maxFiles: 1
  });

  const { getRootProps: getRootPropsPembelian, getInputProps: getInputPropsPembelian } = useDropzone({
    onDrop: onDropPembelian,
    accept: 'image/*',
    maxFiles: 1
  });

  const productOptions = products.flatMap(product =>
    product.variants.map(variant => ({
      value: variant.id,
      label: `${product.kategori?.name || 'No Category'} - ${variant.option1_value || 'No Variant'} - ${product.name}`
    }))
  );
  

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Buat Pembelian" parent="Pages" title="Buat Pembelian" />
      <Container fluid={true} className='general-widget'>
        <ToastContainer /> {/* Add ToastContainer here */}
        <Row className='g-sm-3 height-equal-2 widget-charts'>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>Pembelian Details</H5>
              </CardHeader>
              <CardBody>
                {uploadStatus === 'success' || uploadStatus === 'failure' ? (
                  <div>
                    <h5>{uploadStatus === 'success' ? 'Submission Successful!' : 'Submission Failed!'}</h5>
                    <p>{responseMessage}</p>
                    <Button color="primary" onClick={resetForm}>New Submission</Button>
                  </div>
                ) : (
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label for="tanggal">Tanggal</Label>
                          <Input
                            type="date"
                            name="tanggal"
                            id="tanggal"
                            value={formData.tanggal}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="seller_id">Seller</Label>
                          <Input
                            type="text"
                            name="seller_name"
                            id="seller_name"
                            value={formData.seller_name}
                            readOnly
                            onClick={toggleSellerModal}
                            placeholder="Click to select seller"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="metode_pembelian">Metode Pembelian</Label>
                          <Input
                            type="text"
                            name="metode_pembelian"
                            id="metode_pembelian"
                            value={formData.metode_pembelian}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="pembayaran">Pembayaran</Label>
                          <Input
                            type="select"
                            name="pembayaran"
                            id="pembayaran"
                            value={formData.pembayaran}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Payment Method</option>
                            <option value="TRANSFER">TRANSFER</option>
                            <option value="CASH">CASH</option>
                            <option value="COD">COD</option>
                            <option value="LAINNYA">LAINNYA</option>
                          </Input>
                        </FormGroup>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label for="ongkir">Ongkir</Label>
                              <Input
                                type="text"
                                name="ongkir"
                                id="ongkir"
                                value={formData.ongkir}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label for="diskon">Diskon</Label>
                              <Input
                                type="text"
                                name="diskon"
                                id="diskon"
                                value={formData.diskon}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label for="total">Total Jumlah</Label>
                          <Input
                            type="text"
                            name="total"
                            id="total"
                            value={formData.total}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="harga_total">Harga Total</Label>
                          <Input
                            type="text"
                            name="harga_total"
                            id="harga_total"
                            value={formData.harga_total}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="catatan">Catatan</Label>
                          <Input
                            type="text"
                            name="catatan"
                            id="catatan"
                            value={formData.catatan}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <div>
                          <Row>
                            <Col md="12">
                              <Label>Produk</Label>
                            </Col>
                          </Row>
                          {formData.details.map((detail, index) => (
                            <Fragment key={index}>
                              <Row className="mb-2">
                                <Col md="10">
                                  <Select
                                    options={productOptions}
                                    value={productOptions.find(option => option.value === detail.inventori_id)}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
                                    isClearable
                                  />
                                </Col>
                                <Col md="2">
                                  {index === 0 && (
                                    <Button color="primary" size="sm" onClick={addDetail}>+</Button>
                                  )}
                                  {index !== 0 && (
                                    <Button color="danger" size="sm" onClick={() => removeDetail(index)}>&times;</Button>
                                  )}
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col md="4">
                                  <Input
                                    type="text"
                                    name="harga"
                                    placeholder="Harga"
                                    value={detail.harga}
                                    onChange={(e) => handleDetailChange(e, index)}
                                  />
                                </Col>
                                <Col md="4">
                                  <Input
                                    type="text"
                                    name="jumlah"
                                    placeholder="Jumlah"
                                    value={detail.jumlah}
                                    onChange={(e) => handleDetailChange(e, index)}
                                  />
                                </Col>
                                <Col md="4">
                                  <Input
                                    type="text"
                                    name="satuan"
                                    placeholder="Satuan(PCS)"
                                    value={detail.satuan}
                                    onChange={(e) => handleDetailChange(e, index)}
                                  />
                                </Col>
                              </Row>
                            </Fragment>
                          ))}
                        </div>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label for="bukti_pembayaran">Bukti Pembayaran</Label>
                              <Card style={{ width: '100%', height: '340px', position: 'relative' }}>
                                <CardBody style={{ padding: 0 }}>
                                  {!formData.bukti_pembayaran && (
                                    <div {...getRootPropsPembayaran({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                      <input {...getInputPropsPembayaran()} />
                                      <p>Drag 'n' drop an image here, or click to select an image</p>
                                    </div>
                                  )}
                                  {formData.bukti_pembayaran && (
                                    <div style={{ textAlign: 'center', height: '100%', position: 'relative' }}>
                                      <img src={formData.bukti_pembayaran.preview} alt={formData.bukti_pembayaran.file.name} style={{ width: '340px', height: '340px', objectFit: 'cover' }} />
                                      <Button
                                        color="danger"
                                        size="sm"
                                        onClick={removeFilePembayaran}
                                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                                      >
                                        &times;
                                      </Button>
                                    </div>
                                  )}
                                </CardBody>
                              </Card>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label for="bukti_pembelian">Bukti Pembelian</Label>
                              <Card style={{ width: '100%', height: '340px', position: 'relative' }}>
                                <CardBody style={{ padding: 0 }}>
                                  {!formData.bukti_pembelian && (
                                    <div {...getRootPropsPembelian({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                      <input {...getInputPropsPembelian()} />
                                      <p>Drag 'n' drop an image here, or click to select an image</p>
                                    </div>
                                  )}
                                  {formData.bukti_pembelian && (
                                    <div style={{ textAlign: 'center', height: '100%', position: 'relative' }}>
                                      <img src={formData.bukti_pembelian.preview} alt={formData.bukti_pembelian.file.name} style={{ width: '340px', height: '340px', objectFit: 'cover' }} />
                                      <Button
                                        color="danger"
                                        size="sm"
                                        onClick={removeFilePembelian}
                                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                                      >
                                        &times;
                                      </Button>
                                    </div>
                                  )}
                                </CardBody>
                              </Card>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Button color="primary" onClick={handleSubmit} disabled={loading}>
                      {loading ? <Spinner size="sm" /> : 'Submit'}
                    </Button>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <SellerModal isOpen={isSellerModalOpen} toggle={toggleSellerModal} onSelectSeller={handleSelectSeller} />
    </Fragment>
  );
};

export default NewPembelian;