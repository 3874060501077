import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

const ImageModal = ({ isOpen, toggle, imageUrl }) => (
  <Modal isOpen={isOpen} toggle={toggle} size="lg">
    <ModalHeader toggle={toggle}>Image Preview</ModalHeader>
    <ModalBody className="text-center">
      <img src={imageUrl} alt="Preview" style={{ width: '100%', height: 'auto' }} />
    </ModalBody>
    <Button color="secondary" onClick={toggle} style={{ margin: '20px' }}>
      Close
    </Button>
  </Modal>
);

export default ImageModal;
