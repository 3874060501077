import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from '../Utils/cookies';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility function to get user_id from token
const getUserIdFromToken = () => {
  const token = getCookie('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  }
  return null;
};

// API calls
  // Mendapatkan semua lokasi
  export const getAllLocations = async () => {
    try {
      const user_id = getUserIdFromToken();
      const response = await api.get(`/locations/user/${user_id}`); // Pastikan rutenya sesuai dengan backend Anda
      return response.data;
    } catch (error) {
      console.error('Failed to fetch locations:', error);
      throw error;
    }
  };

  export const getProduk  = async () => {
    try {
      const user_id = getUserIdFromToken();
      if (!user_id) throw new Error('User ID not found');
      const response = await api.get(`/product/user/${user_id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch product :', error);
      throw error;
    }
  };

  export const uploadImage = async (userId, description, imageFile) => {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const formData = new FormData();
    formData.append('userId', user_id);
    formData.append('description', description);
    formData.append('photo', imageFile);
  
    try {
      const response = await api.post('/upload/inventory', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  export const getInventoryStock = async () => {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
  
    try {
      const response = await api.get(`/transaction/inventory/user/${user_id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch inventory stock:', error);
      throw error;
    }
  };

  export const getTransaction = async () => {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
  
    try {
      const response = await api.get(`/transaction/user/${user_id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch inventory stock:', error);
      throw error;
    }
  };
  

  export const createTransaction = async (transactionData) => {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
  
    // Tambahkan user_id ke dalam transactionData
    const dataToSend = {
      ...transactionData,
      id_user: user_id, // Tambahkan user_id dari token ke data yang dikirim
      quantity_type: "tambah",
    };
  
    try {
      const response = await api.post('/transaction', dataToSend);
      return response.data;
    } catch (error) {
      console.error('Failed to create transaction:', error);
      throw error;
    }
  };

  export const KurangiStok = async (transactionData) => {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
  
    // Tambahkan user_id ke dalam transactionData
    const dataToSend = {
      ...transactionData,
      id_user: user_id, // Tambahkan user_id dari token ke data yang dikirim
      quantity_type: "kurang",
    };
  
    try {
      const response = await api.post('/transaction', dataToSend);
      return response.data;
    } catch (error) {
      console.error('Failed to create transaction:', error);
      throw error;
    }
  };
  
  

export default api;
