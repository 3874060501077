import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
import { getUserMenus, createPermission, updatePermission, deletePermission , getPengguna} from '../../Rest/api-usermenu';
import { getMenuItems } from '../../Rest/api-menu'; // Import getPengguna function

const UserMenuPermissionManager = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [userMenus, setUserMenus] = useState([]);
  const [formData, setFormData] = useState({ user_id: '', menu_item_id: '', can_access: true });

 

  useEffect(() => {
    // Fetch all users and menu items when the component loads
    fetchUsers();
    fetchMenuItems();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await getPengguna();
      if (response && response.data) {
        setUsers(response.data); // Assuming the users are in response.data
      } else {
        setUsers([]); // Handle case where data is not an array
      }
    } catch (error) {
      console.error('Failed to fetch users:', error);
      setUsers([]); // Set to empty array on error
    }
  };

  const fetchMenuItems = async () => {
    try {
      const data = await getMenuItems();
      setMenuItems(data);
    } catch (error) {
      console.error('Failed to fetch menu items:', error);
    }
  };

  const fetchUserMenus = async (userId) => {
    try {
      const data = await getUserMenus(userId);
      setUserMenus(data);
    } catch (error) {
      console.error('Failed to fetch user menus:', error);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    fetchUserMenus(user.id);
    setFormData({ user_id: user.id, menu_item_id: '', can_access: true });
  };

  if(handleUserSelect){}

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (userMenus.some(menu => menu.id === formData.menu_item_id)) {
        await updatePermission(formData);
      } else {
        await createPermission(formData);
      }
      fetchUserMenus(formData.user_id);
      setFormData({ ...formData, menu_item_id: '' });
    } catch (error) {
      console.error('Failed to save user menu permission:', error);
    }
  };

  const handleDelete = async (menuItemId) => {
    try {
      await deletePermission(selectedUser.id, menuItemId);
      fetchUserMenus(selectedUser.id);
    } catch (error) {
      console.error('Failed to delete user menu permission:', error);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Manage User Menu Permissions" parent="Pages" title="User Menu Permissions" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Manage User Menu Permissions" span1="Assign or revoke menu access to users" />
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="user_id">Select User</Label>
                    <Input type="select" name="user_id" id="user_id" value={formData.user_id} onChange={handleInputChange} required>
                      <option value="" disabled>Select User</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>{user.full_name}</option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="menu_item_id">Select Menu Item</Label>
                    <Input type="select" name="menu_item_id" id="menu_item_id" value={formData.menu_item_id} onChange={handleInputChange} required>
                      <option value="" disabled>Select Menu Item</option>
                      {menuItems.map((item) => (
                        <option key={item.id} value={item.id}>{item.title}</option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="can_access">Can Access</Label>
                    <Input type="select" name="can_access" id="can_access" value={formData.can_access} onChange={handleInputChange}>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Input>
                  </FormGroup>
                  <Button type="submit" color="primary">Save Permission</Button>
                </Form>

                {selectedUser && (
                  <>
                    <h5 className="mt-5">Permissions for {selectedUser.full_name}</h5>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Menu Item</th>
                          <th>Can Access</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userMenus.map((menu, index) => (
                          <tr key={menu.id}>
                            <td>{index + 1}</td>
                            <td>{menu.title}</td>
                            <td>{menu.can_access ? 'Yes' : 'No'}</td>
                            <td>
                              <Button color="danger" size="sm" onClick={() => handleDelete(menu.id)}>Revoke Access</Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserMenuPermissionManager;
