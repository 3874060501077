import React from 'react';
import { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import UserTable from './UserTable'; // Adjust the import path as necessary

const UserManagement = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="User Management" parent="Dashboard" title="User Management" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <UserTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserManagement;
