export const SmallWidgetsDataNew = [
    {
      title: 'Semua Paket',
      color: 'primary',
      total: 2_435,
      icon: 'new-order',
    },
    {
      title: 'Belum Diproses',
      color: 'warning',
      total: 2_908,
      icon: 'customers',
    },
    {
      title: 'Belum Di Ekpedisi',
      color: 'secondary',
      total: 389,
      icon: 'sale',
    },
    {
      title: 'Proses Pengiriman',
      color: 'success',
      total: 3_908,
      icon: 'profit',
    },
    {
      title: 'Selesai',
      color: 'success',
      total: 3_908,
      icon: 'profit',
    },
    {
      title: 'Retur',
      color: 'success',
      total: 3_908,
      icon: 'profit',
    },
    {
      title: 'Dibatalkan',
      color: 'success',
      total: 3_908,
      icon: 'profit',
    },
  ];