import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { uploadImage, getBanks, getCategories, createTransaction } from '../../../Rest/api-keuangan';

const CreateTransaksi = () => {
  const navigate = useNavigate();
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    bank_id: '',
    nomor: '',
    nomor_transaksi: '',
    tanggal: getCurrentDate(),
    jumlah: '',
    kategori_payment_id: '',
    tipe: '',
    catatan: '',
    bukti_transaksi: '',
    status: 'Pending',
    user_id: 1,
  });

  const [formattedJumlah, setFormattedJumlah] = useState('');
  const [banks, setBanks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bankData = await getBanks();
        const categoryData = await getCategories();
        setBanks(bankData);
        setCategories(categoryData);
        setFilteredCategories(categoryData); // Initially show all categories
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (formData.tipe) {
      const filtered = categories.filter(category => 
        (formData.tipe === 'Debit' && category.tipe === 'PEMASUKAN') ||
        (formData.tipe === 'Kredit' && category.tipe === 'PENGELUARAN')
      );
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories(categories); // Show all categories if no tipe is selected
    }
  }, [formData.tipe, categories]);

  const formatNumber = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'jumlah') {
      // Remove non-numeric characters
      const numericValue = value.replace(/[^\d]/g, '');
      setFormData({
        ...formData,
        [name]: numericValue,
      });
      setFormattedJumlah(formatNumber(numericValue));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (selectedOption, field) => {
    setFormData({ ...formData, [field.name]: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let productPhotoUrl = formData.product_photo;

      if (file) {
        const uploadResponse = await uploadImage(formData.user_id, formData.description, file);
        productPhotoUrl = uploadResponse.photoUrl;
      }

      const transactionData = {
        ...formData,
        jumlah: formData.jumlah.replace(/\./g, ''), // Remove dots before submitting
        bukti_transaksi: productPhotoUrl,
      };

      // Extract the layout URL parameter from the current URL
      const currentURL = window.location.href;
      const urlParts = currentURL.split('/');
      const layoutURL = urlParts[urlParts.length - 1];

      await createTransaction(transactionData);
      toast.success('Transaksi berhasil dibuat!');
      navigate(`${process.env.PUBLIC_URL}/riwayat-transaksi/${layoutURL}`);
    } catch (error) {
      console.error('Error creating transaction:', error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`Gagal membuat transaksi: ${error.response.data.error}`);
      } else {
        toast.error('Gagal membuat transaksi. Silakan coba lagi.');
      }
    } finally {
      setLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(Object.assign(file, { preview: URL.createObjectURL(file) }));
    setFormData({ ...formData, bukti_transaksi: file });
  }, [formData]);

  const removeFile = () => {
    setFile(null);
    setFormData({ ...formData, bukti_transaksi: null });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false
  });

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Create Transaksi" parent="Dashboard" title="Transaksi" />
      <Container fluid={true} className="general-widget">
        <ToastContainer />
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col md={6}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="tanggal">Tanggal</Label>
                    <Input type="date" name="tanggal" id="tanggal" value={formData.tanggal} onChange={handleChange} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="tipe">Tipe</Label>
                    <Input type="select" name="tipe" id="tipe" value={formData.tipe} onChange={handleChange}>
                      <option value="">Select Tipe</option>
                      <option value="Debit">Debit (Masuk)</option>
                      <option value="Kredit">Kredit (Keluar)</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label for="bukti_transaksi">Bukti Transaksi</Label>
                <Card style={{ width: '100%', height: '340px', position: 'relative' }}>
                  <CardBody style={{ padding: 0 }}>
                    {!file && (
                      <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop an image here, or click to select an image</p>
                      </div>
                    )}
                    {file && (
                      <div style={{ textAlign: 'center', height: '100%', position: 'relative' }}>
                        <img src={file.preview} alt={file.name} style={{ width: '340px', height: '340px', objectFit: 'cover' }} />
                        <Button
                          color="danger"
                          size="sm"
                          onClick={removeFile}
                          style={{ position: 'absolute', top: '10px', right: '10px' }}
                        >
                          &times;
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="bank_id">Bank</Label>
                <Select
                  name="bank_id"
                  id="bank_id"
                  options={banks.map((bank) => ({ value: bank.id, label: `${bank.nama_bank} (${bank.nomor_rekening}) an ${bank.pemilik_rekening}` }))}
                  onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'bank_id' })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="kategori_payment_id">Kategori Payment</Label>
                <Select
                  name="kategori_payment_id"
                  id="kategori_payment_id"
                  options={filteredCategories.map((category) => ({ value: category.id, label: `${category.tipe} - ${category.nama}` }))}
                  onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'kategori_payment_id' })}
                  isSearchable
                />
              </FormGroup>
              <FormGroup>
                <Label for="nomor">Nomor</Label>
                <Input type="text" name="nomor" id="nomor" value={formData.nomor} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="nomor_transaksi">Nomor Transaksi</Label>
                <Input type="text" name="nomor_transaksi" id="nomor_transaksi" value={formData.nomor_transaksi} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="jumlah">Jumlah</Label>
                <Input
                  type="text"
                  name="jumlah"
                  id="jumlah"
                  value={formattedJumlah}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="catatan">Catatan</Label>
                <Input type="textarea" name="catatan" id="catatan" value={formData.catatan} onChange={handleChange} />
              </FormGroup>
              <div className="d-flex justify-content-end">
                <Button type="submit" color="primary" disabled={loading}>
                  {loading ? <Spinner size="sm" /> : 'Create Transaksi'}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default CreateTransaksi;
