import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';

const ColumnConfigModal = ({ isOpen, toggle, columns, onSave }) => {
  const [localColumns, setLocalColumns] = useState(columns);

  const handleCheckboxChange = (index) => {
    const updatedColumns = [...localColumns];
    updatedColumns[index].visible = !updatedColumns[index].visible;
    setLocalColumns(updatedColumns);
  };

  const handleSave = () => {
    onSave(localColumns);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Configure Columns</ModalHeader>
      <ModalBody>
        {localColumns.map((column, index) => (
          <FormGroup key={index} check className="custom-checkbox">
            <Label check>
              <Input 
                type="checkbox" 
                checked={column.visible} 
                style={{ backgroundColor: 'lightcoral' }}
                onChange={() => handleCheckboxChange(index)} 
              />{' '}
              {column.name}
            </Label>
          </FormGroup>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>Save</Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ColumnConfigModal;
