import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { getTransaksi, deleteTransaksi } from '../../../Rest/api-keuangan'; // Ensure correct import for API functions
import { FaTrash } from 'react-icons/fa';
import moment from 'moment';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ColumnConfigModal from './ColumnConfigModal';
import SearchAndFilter from './SearchAndFiltera';

const formatRupiah = (number) => {
  if (!number) return 'Rp 0';
  const formattedNumber = Number(number).toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR',
  });
  return formattedNumber.replace(',00', '');
};

const RiwayatTransaksiTable = () => {
  const [transaksi, setTransaksi] = useState([]);
  const [filteredTransaksi, setFilteredTransaksi] = useState([]);
  const [currentTransaksi, setCurrentTransaksi] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [configModal, setConfigModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [columns, setColumns] = useState([
    { name: 'No', selector: (row, index) => index + 1, sortable: false, center: true, grow: 0.1, visible: true },
    { name: 'Nomor Transaksi', selector: row => row.nomor_transaksi, sortable: true, visible: true },
    { name: 'Tanggal', selector: row => moment(row.tanggal).format('DD-MM-YYYY'), sortable: true, visible: true },
    { name: 'Jumlah', selector: row => formatRupiah(row.jumlah), sortable: true, visible: true },
    { 
      name: 'Bukti Transaksi', 
      selector: row => row.bukti_transaksi ? <img src={row.bukti_transaksi} alt="Bukti Transaksi" style={{ width: '50px', height: '50px' }} /> : 'No Image', 
      sortable: false, 
      visible: true 
    },
    { name: 'Tipe', selector: row => row.tipe, sortable: true, visible: true },
    { name: 'Catatan', selector: row => row.catatan, sortable: true, visible: false },
    { name: 'Status', selector: row => row.status, sortable: true, visible: true },
    { name: 'User', selector: row => row.user.full_name, sortable: true, visible: false },
    { name: 'Bank', selector: row => row.bank.nama_bank, sortable: true, visible: true },
    { name: 'Kategori Pembayaran', selector: row => `${row.kategoripayment.tipe} - ${row.kategoripayment.nama}`, sortable: true, visible: true },
    { 
      name: 'Actions', 
      cell: row => (
        <div>
          <FaTrash style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setCurrentTransaksi(row); toggleDeleteModal(); }} />
        </div>
      ), 
      sortable: false, 
      center: true, 
      grow: 0.1, 
      visible: true 
    },
  ]);

  // Function to fetch transaction history from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTransaksi();
        if (response.length === 0) {
          console.log('No transactions found');
          setTransaksi([]);
          setFilteredTransaksi([]);
        } else {
          setTransaksi(response);
          setFilteredTransaksi(response);
        }
      } catch (error) {
        //console.error('Error fetching transactions:', error);
        if (error.message !== "Transactions tidak ditemukan" && error.message !== "No transactions found") {
          //toast.error('Failed to fetch transaction data. Please try again.');
        }
      }
    };

    fetchData();
  }, []);

  // Function to handle deleting a transaction
  const handleDeleteTransaksi = async () => {
    try {
      await deleteTransaksi(currentTransaksi.id); // Adjust as per your API function
      setTransaksi(transaksi.filter(tx => tx.id !== currentTransaksi.id));
      setFilteredTransaksi(filteredTransaksi.filter(tx => tx.id !== currentTransaksi.id));
      toast.success('Transaction deleted successfully');
      toggleDeleteModal();
    } catch (error) {
      console.error('Failed to delete transaction:', error);
      toast.error('Failed to delete transaction. Please try again.');
    }
  };

  // Function to toggle delete confirmation modal
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  // Function to toggle column configuration modal
  const toggleConfigModal = () => setConfigModal(!configModal);

  

  // Function to handle search and filter
  const handleSearch = (text) => {
    setSearchText(text);
    applyFilters(text, selectedDateRange.startDate, selectedDateRange.endDate, selectedStatuses, selectedTypes, selectedBanks);
  };

  const handleFilter = (startDate, endDate, statuses, types, banks) => {
    setSelectedDateRange({ startDate, endDate });
    setSelectedStatuses(statuses);
    setSelectedTypes(types);
    setSelectedBanks(banks);
    applyFilters(searchText, startDate, endDate, statuses, types, banks);
  };

  const applyFilters = (searchText, startDate, endDate, statuses, types, banks) => {
    const filtered = transaksi.filter(item => {
      const matchesSearchText = item.nomor.toLowerCase().includes(searchText.toLowerCase()) ||
        item.nomor_transaksi.toLowerCase().includes(searchText.toLowerCase());
      const matchesDateRange = startDate && endDate ? moment(item.tanggal).isBetween(startDate, endDate, 'day', '[]') : true;
      const matchesStatus = statuses.length ? statuses.includes(item.status) : true;
      const matchesType = types.length ? types.includes(item.tipe) : true;
      const matchesBank = banks.length ? banks.includes(item.bank.nama_bank) : true;
      return matchesSearchText && matchesDateRange && matchesStatus && matchesType && matchesBank;
    });
    setFilteredTransaksi(filtered);
  };

  const visibleColumns = columns.filter(column => column.visible);

  return (
    <Container fluid={true}>
      <SearchAndFilter onSearch={handleSearch} onFilter={handleFilter} toggleConfig={toggleConfigModal} />
      <div className='table-responsive table-bordernone orderHistoryTable'>
        <DataTable
          pagination
          columns={visibleColumns}
          data={filteredTransaksi}
          highlightOnHover
          striped
          responsive
        />
      </div>
      <DeleteConfirmationModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        onConfirm={handleDeleteTransaksi}
        itemName={currentTransaksi ? currentTransaksi.nomor_transaksi : ''}
      />
      <ColumnConfigModal
        isOpen={configModal}
        toggle={toggleConfigModal}
        columns={columns}
        onSave={setColumns}
      />
      <style jsx>{`
        .table-responsive table th,
        .table-responsive table td {
          text-align: center;
          vertical-align: middle;
        }
      `}</style>
    </Container>
  );
};

export default RiwayatTransaksiTable;
