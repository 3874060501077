import React, { useState } from 'react';
import { Container, Row, Col, Input, FormGroup, Label, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerCustomStyles.css"; // Import the custom CSS file

const SearchAndFilter = ({ onSearch, onFilter, toggleConfig, products }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    onSearch(e.target.value);
  };

  const handleProductChange = (e) => {
    setSelectedProduct(e.target.value);
    onFilter(e.target.value, selectedDateRange.startDate, selectedDateRange.endDate);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates || [null, null];
    setSelectedDateRange({ startDate: start, endDate: end });
    onFilter(selectedProduct, start, end);
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="search">Search</Label>
            <Input
              type="text"
              name="search"
              id="search"
              placeholder="Kode Produksi, Name, Keterangan"
              value={searchText}
              onChange={handleSearchChange}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="productSelect">Select Product</Label>
            <Input
              type="select"
              name="productSelect"
              id="productSelect"
              value={selectedProduct}
              onChange={handleProductChange}
            >
              <option value="">All Products</option>
              {products.map(product => (
                <option key={product.id} value={product.name}>{product.name}</option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="dateRange">Date Range</Label>
            <DatePicker
              selected={selectedDateRange.startDate}
              onChange={handleDateChange}
              startDate={selectedDateRange.startDate}
              endDate={selectedDateRange.endDate}
              selectsRange
              placeholderText="Select date range"
              className="form-control"
            />
          </FormGroup>
        </Col>
        <Col md={3} className="d-flex align-items-end" style={{ marginBottom: '17px' }}>
          <Button color="primary" onClick={toggleConfig} className="w-100">Configure Columns</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchAndFilter;
