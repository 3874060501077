// src/Components/Orderan/FilterModal.jsx
import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';

const FilterModal = ({ isOpen, toggle, onApply }) => {
  const [statuses, setStatuses] = useState([]);
  const [types, setTypes] = useState([]);
  const [banks, setBanks] = useState([]);

  const handleApply = () => {
    onApply(statuses, types, banks);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Filter Options</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="status">Status</Label>
          <Input
            type="select"
            multiple
            id="status"
            onChange={(e) => setStatuses(Array.from(e.target.selectedOptions, option => option.value))}
          >
            <option value="Pending">Pending</option>
            <option value="Processed">Processed</option>
            <option value="Completed">Completed</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="types">Types</Label>
          <Input
            type="select"
            multiple
            id="types"
            onChange={(e) => setTypes(Array.from(e.target.selectedOptions, option => option.value))}
          >
            <option value="Type1">Type 1</option>
            <option value="Type2">Type 2</option>
            <option value="Type3">Type 3</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="banks">Banks</Label>
          <Input
            type="select"
            multiple
            id="banks"
            onChange={(e) => setBanks(Array.from(e.target.selectedOptions, option => option.value))}
          >
            <option value="Bank1">Bank 1</option>
            <option value="Bank2">Bank 2</option>
            <option value="Bank3">Bank 3</option>
          </Input>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleApply}>Apply</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default FilterModal;
