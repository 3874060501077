// StatusCard.jsx
import React from 'react';
import './StatusCard.css'; // Import CSS file for styling

const StatusCard = ({ title, count, icon }) => {
  return (
    <div className="status-card">
      <div className="icon-container">{icon}</div>
      <div className="status-content">
        <p className="status-count">{count.toLocaleString()}</p>
        <h4 className="status-title">{title}</h4>
      </div>
    </div>
  );
};

export default StatusCard;
