import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import { getProducts, deleteProduct } from '../../Rest/api-inventory';
import ColumnConfigModal from './ColumnConfigModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import SearchAndFilter from './SearchAndFilter';
import { FaEdit, FaTrash } from 'react-icons/fa';
import ImageDetailModal from './ImageDetailModal';

const defaultImage = "https://res.cloudinary.com/dtimsje1o/image/upload/v1720937403/inventory_photos/2024-07-14T06-09-59.691Z-ASDS.jpg.jpg";

const ProductTable = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [configModal, setConfigModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  
  // Define columns and setColumns state
  const [columns, setColumns] = useState([
    { name: 'No', selector: (row, index) => index + 1, sortable: false, center: true, grow: 0.1, visible: true },
    { 
      name: 'Product Photo', 
      selector: row => (
        <img 
          src={row.image || defaultImage} 
          onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }}
          alt={row.name} 
          width="50" 
          height="50" 
          style={{ cursor: 'pointer' }}
          onClick={() => handleImageClick(row.image || defaultImage)}
        />
      ), 
      sortable: false, 
      center: true, 
      grow: 0.1, 
      visible: true 
    },
    { name: 'Name', selector: row => row.name, sortable: true, visible: true },
    { name: 'SKU', selector: row => row.sku, sortable: true, visible: true },
    { name: 'Quantity', selector: row => row.quantity, sortable: true, visible: true },
    { name: 'HPP', selector: row => row.hpp, sortable: true, visible: true },
    { name: 'Selling Price', selector: row => row.sellingPrice, sortable: true, visible: false },
    { name: 'Inventory Value', selector: row => row.inventory_value, sortable: true, visible: false },
    { name: 'Category', selector: row => row.category, sortable: true, visible: true },
    { name: 'Location', selector: row => row.location, sortable: true, visible: true },
    { name: 'Description', selector: row => row.description, sortable: true, visible: false },
    { 
      name: 'Actions', 
      cell: row => (
        <div>
          <FaEdit style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => navigate(`${process.env.PUBLIC_URL}/update-inventory/${row.id}/Indo`)} />
          <FaTrash style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setCurrentProduct(row); toggleDeleteModal(); }} />
        </div>
      ), 
      sortable: false, 
      center: true, 
      grow: 0.1, 
      visible: true 
    },
  ]);

  const toggleConfig = () => setConfigModal(!configModal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleImageModal = () => setImageModal(!imageModal);

  const handleImageClick = (image) => {
    setCurrentImage(image);
    toggleImageModal();
  };

  const handleDelete = async () => {
    try {
      await deleteProduct(currentProduct.id);
      setData(data.filter(product => product.id !== currentProduct.id));
      setFilteredData(filteredData.filter(product => product.id !== currentProduct.id));
      toast.success('Product deleted successfully');
      toggleDeleteModal();
    } catch (error) {
      console.error('Failed to delete product:', error);
      toast.error('Failed to delete product. Please try again.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProducts();
        const data = response.map(product => ({
          id: product.id,
          image: product.product_photo || defaultImage,
          category: product.productCategory.name,
          name: product.name,
          sku: product.sku,
          quantity: `${product.quantity} ${product.satuan}`,
          hpp: parseFloat(product.hpp).toLocaleString('id-ID'),
          sellingPrice: parseFloat(product.selling_price).toLocaleString('id-ID'),
          inventory_value: parseFloat(product.inventory_value).toLocaleString('id-ID'),
          location: product.location.name,
          description: product.description,
        }));
        setData(data);
        setFilteredData(data);

        const uniqueCategories = [...new Set(data.map(item => item.category))];
        const uniqueLocations = [...new Set(data.map(item => item.location))];
        setCategories(uniqueCategories.map(name => ({ id: name, name })));
        setLocations(uniqueLocations.map(name => ({ id: name, name })));
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch product data. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleSearch = (searchText) => {
    setFilteredData(data.filter(item =>
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.sku.toLowerCase().includes(searchText.toLowerCase())
    ));
  };

  const handleFilter = (category, location) => {
    setFilteredData(data.filter(item =>
      (category ? item.category === category : true) &&
      (location ? item.location === location : true)
    ));
  };

  const tableColumns = columns.filter(col => col.visible);

  return (
    <Container fluid={true}>
      <SearchAndFilter
        onSearch={handleSearch}
        onFilter={handleFilter}
        categories={categories}
        locations={locations}
        toggleConfig={toggleConfig}
      />
      <div className='table-responsive table-bordernone orderHistoryTable product-table'>
        <DataTable
          pagination
          columns={tableColumns}
          data={filteredData}
          highlightOnHover
          striped
          responsive
        />
      </div>
      <ColumnConfigModal
        isOpen={configModal}
        toggle={toggleConfig}
        columns={columns}
        onSave={setColumns}
      />
      <DeleteConfirmationModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        onConfirm={handleDelete}
        productName={currentProduct ? currentProduct.name : ''}
      />
      <ImageDetailModal
        isOpen={imageModal}
        toggle={toggleImageModal}
        imageSrc={currentImage}
      />
      <style jsx>{`
        .table-responsive table th,
        .table-responsive table td {
          text-align: center;
          vertical-align: middle;
        }
        .table-responsive table img {
          border-radius: 4px;
        }
      `}</style>
    </Container>
  );
};

export default ProductTable;
