import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Card, CardBody, InputGroup, InputGroupText } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { addUser, uploadImage } from '../../../Rest/api-pengguna'; // Adjust the import path as necessary

const AddUserModal = ({ isOpen, toggle, onAddUser }) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('USER');
  const [active] = useState(true);
  const [profileImage, setProfileImage] = useState(null);
  const [file, setFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFile(Object.assign(file, { preview: URL.createObjectURL(file) }));
    },
  });

  const removeFile = () => {
    setFile(null);
    setProfileImage(null);
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let profileImageUrl = profileImage;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        const uploadResponse = await uploadImage(formData);
        profileImageUrl = uploadResponse.photoUrl; // Ensure this is correct based on your backend response
      }

      const newUser = {
        full_name: fullName,
        email: email,
        password: password,
        phone_number: phoneNumber,
        role: role,
        active: active,
        profile_image: profileImageUrl,
      };

      const createdUser = await addUser(newUser);
      toast.success('User added successfully');
      toggle();
      onAddUser(createdUser.data); // Call the onAddUser function with the created user data
    } catch (error) {
      console.error('Failed to add user:', error);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Failed to add user: ${error.response.data.message}`);
      } else {
        toast.error('Failed to add user. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add New User</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleAddUser}>
          <FormGroup>
            <Label for="fullName">Full Name</Label>
            <Input
              type="text"
              name="fullName"
              id="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Enter full name"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                required
              />
              <InputGroupText onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </InputGroupText>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="phoneNumber">Phone Number</Label>
            <Input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter phone number"
            />
          </FormGroup>
          <FormGroup>
            <Label for="role">Role</Label>
            <Input
              type="select"
              name="role"
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="USER">USER</option>
              <option value="ADMIN">ADMIN</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="profileImage">Profile Image</Label>
            <Card style={{ width: '100%', height: '170px', position: 'relative' }}>
              <CardBody style={{ padding: 0 }}>
                {!file && !profileImage && (
                  <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop an image here, or click to select an image</p>
                  </div>
                )}
                {(file || profileImage) && (
                  <div style={{ textAlign: 'center', height: '100%', position: 'relative' }}>
                    <img src={file ? file.preview : profileImage} alt="Profile" style={{ width: '170px', height: '170px', objectFit: 'cover' }} />
                    <Button
                      color="danger"
                      size="sm"
                      onClick={removeFile}
                      style={{ position: 'absolute', top: '10px', right: '10px' }}
                    >
                      &times;
                    </Button>
                  </div>
                )}
              </CardBody>
            </Card>
          </FormGroup>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? 'Adding...' : 'Add User'}
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AddUserModal;
