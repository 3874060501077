import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Badge } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import SingleVariantComponent from './SingleVariantComponent';
import MultipleVariantComponent from './MultipleVariantComponent';
import { getKategori, getGudang, getProdukById, updateProduct } from '../../../Rest/api-produk';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './add.css';

const ViewProduct = () => {
  const { id, layout } = useParams(); // Get the product ID and layout from the URL
  const [variantType, setVariantType] = useState('single');
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [productData, setProductData] = useState({
    name: '',
    item_type: 'Produk Fisik',
    is_inventory: false,
    description: '',
    option1_name: '',
    option2_name: '',
    kategori_id: '',
    location_ids: [],  // Ensure it's an array, not null or undefined
    variants: [],
    images: [],
  });
  const [singleVariantData, setSingleVariantData] = useState({
    price: '',
    weight: '',
    sku: '',
    hpp: '',
    images: [],
  });
  const [singleVariantDataNew, setsingleVariantDataNew] = useState({
    price: '',
    weight: '',
    sku: '',
    hpp: '',
    images: [],
  });
  
  const [multipleVariantData, setMultipleVariantData] = useState({
    variants: [],
    images: [],
  });
  const [multipleVariantDataNew, setMultipleVariantDataNew] = useState({
    variants: [],
    images: [],
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

   // Callback function to handle variant data changes
   const handleVariantChange = (updatedData) => {
    setsingleVariantDataNew(updatedData);
  };

  

  // Fetch product by ID when the component mounts
  useEffect(() => {
    
    const fetchProductById = async () => {
      try {
        const product = await getProdukById(id);
        setProductData(product); // Update the form with the fetched data
        setVariantType(product.variants.length > 1 ? 'multiple' : 'single');
        setSelectedWarehouses(product.locations || []); // Set selected warehouses

        // Pre-fill variant data for single or multiple variant component
        if (product.variants.length === 1) {
          // For single variant
          const singleVariant = product.variants[0];
          setSingleVariantData({
            id: singleVariant.id,
            price: singleVariant.price,
            weight: singleVariant.weight,
            sku: singleVariant.sku,
            hpp: singleVariant.hpp,
            images: product.images.map(image => image.url), // Map images to URL
          });
        } else {
          // For multiple variants
          setMultipleVariantData({
            variants: product.variants.map((variant) => ({
              ...variant,
              price: variant.price || '',
              weight: variant.weight || '',
              sku: variant.sku || '',
              hpp: variant.hpp || '',
            })),
            images: product.images, // Map the images to the variants
          });
        }
      } catch (error) {
        console.error('Error fetching product by ID:', error);
        toast.error('Error fetching product data.');
      }
    };

    fetchProductById(); // Call the function to fetch the product
  }, [id]);

  // Fetch categories and warehouses on component mount
  useEffect(() => {
    const fetchCategoriesAndWarehouses = async () => {
      try {
        const categoryData = await getKategori();
        setCategories(categoryData); // Set fetched categories
        const warehouseData = await getGudang();
        setWarehouses(warehouseData); // Set fetched warehouses
      } catch (error) {
        console.error('Error fetching categories or warehouses:', error);
      }
    };

    fetchCategoriesAndWarehouses();
  }, []);

// Handle warehouse selection
const handleAddWarehouse = (warehouse) => {
  // Check if the warehouse is already selected
  if (!selectedWarehouses.some((w) => w.id === warehouse.id)) {
    setSelectedWarehouses([...selectedWarehouses, warehouse]);
    setProductData((prev) => ({
      ...prev,
      location_ids: Array.isArray(prev.location_ids) ? [...prev.location_ids, warehouse.id] : [warehouse.id],  // Ensure it's an array
    }));
  }
};


// Handle removing warehouse
const handleRemoveWarehouse = (warehouse) => {
  const updatedWarehouses = selectedWarehouses.filter((w) => w.id !== warehouse.id);
  setSelectedWarehouses(updatedWarehouses);
  setProductData((prev) => ({
    ...prev,
    location_ids: updatedWarehouses.map((w) => w.id), // Remove warehouse from product data
  }));
};


  // Handle product data change (with safety check)
  const handleProductDataChange = (e) => {
    if (e && e.target && e.target.name) {
      const { name, value } = e.target;
      setProductData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Handle single variant data change (with safety check)
  const handleSingleVariantDataChange = (e) => {
    if (e && e.target && e.target.name) {
      const { name, value } = e.target;
      setSingleVariantData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleMultipleVariantChange = useCallback((multiVariants, optionNames, variantDetails) => {
    console.log('multiVariants:', multiVariants);
    console.log('optionNames:', optionNames);
    console.log('variantDetails:', variantDetails);

    // Extract images and id_varian for each variant using the correct key
    const variantsWithImages = multiVariants.map((variant) => {
      const variantKey = `${variant.option1_value}${variant.option2_value ? ' / ' + variant.option2_value : ''}`;
      console.log('variantKey:', variantKey);

      const variantImages = variantDetails[variantKey]?.images || [];
      console.log('variantImages for', variantKey, ':', variantImages);

      // Include id_varian in the variant object
      return {
        ...variant,
        id_varian: variantDetails[variantKey]?.id_varian || variant.id_varian || null, // Preserve id_varian or set to null
        images: variantImages.map((image) => ({
          id: image?.id || null,
          url: image?.url?.url || image?.url || '', // Check if `url` is nested or a string
        })),
      };
    });

    // Update the new state with extracted images and variants, including id_varian
    setMultipleVariantDataNew({
      variants: variantsWithImages.map((variant) => ({
        ...variant,
        id: variant.id_varian || null, // Include id_varian in state
      })),
      option1_name: optionNames.option1_name,
      option2_name: optionNames.option2_name,
      images: variantsWithImages.flatMap((variant) => variant.images),
    });

    // Optionally update the product data state as well
    setProductData((prev) => ({
      ...prev,
      variants: variantsWithImages,
      option1_name: optionNames.option1_name,
      option2_name: optionNames.option2_name,
    }));
}, []);


  
const handleSave = async () => {
  setLoading(true); // Start loading
  try {
    const updatedProductData = {
      id: productData.id,
      name: productData.name,
      item_type: productData.item_type,
      is_inventory: productData.is_inventory,
      description: productData.description,
      option1_name: productData.option1_name,
      option2_name: productData.option2_name || '',
      kategori_id: productData.kategori_id,
      location_ids: selectedWarehouses.map(warehouse => warehouse.id),

      // Variants mapping based on whether it's single or multiple variants
      variants: variantType === 'single'
        ? [{
            id: singleVariantDataNew.id || productData.variants[0]?.id || null, // Preserve variant ID if it exists or set to null
            option1_value: singleVariantDataNew.option1_value || 'Size',
            option2_value: singleVariantDataNew.option2_value || '',
            price: singleVariantDataNew.price,
            weight: singleVariantDataNew.weight,
            sku: singleVariantDataNew.sku,
            hpp: singleVariantDataNew.hpp,
            images: singleVariantDataNew.images.map(imageUrl => ({
              id: productData.images.find(img => img.url === imageUrl)?.id || null, // Preserve image ID if it exists
              url: imageUrl || '', // Ensure a valid image URL
            })),
          }]
        : multipleVariantDataNew.variants.map(variant => {
            // Find the existing variant if it exists
            const existingVariant = productData.variants.find(
              v => v.option1_value === variant.option1_value && v.option2_value === variant.option2_value
            );

            // Handle images: merge existing and new images properly
            const variantImages = (variant.images || []).map(image => ({
              id: image.id || null, // Preserve image ID if it exists
              url: image.url || '',  // Ensure the image URL is not empty
            }));

            // Ensure id_varian is used for both existing and new variants
            return {
              id: variant.id_varian || null, // Use id_varian if available, otherwise null for new
              option1_value: variant.option1_value,
              option2_value: variant.option2_value || '',
              price: variant.price,
              weight: variant.weight,
              sku: variant.sku || '',
              hpp: variant.hpp || '',
              images: variantImages, // Use processed images
            };
          }),
    };

    // Send the updated product data to the API
    await updateProduct(id, updatedProductData);

    toast.success('Produk berhasil diperbarui!');
    navigate(`${process.env.PUBLIC_URL}/product/${layout}`);
  } catch (error) {
    console.error('Error updating product:', error);
    toast.error('Error updating product.');
  } finally {
    setLoading(false); // Stop loading
  }
};

  
  
  
  

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Edit Produk" parent="Daftar Produk" title="Edit Produk" />

      <ToastContainer /> {/* Toast container for notifications */}

      <Container fluid={true}>
        <Row>
          {/* Left Form Section */}
          <Col sm="8">
            <Card className="square-card">
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label for="name">Nama Produk *</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Tulis nama produk ..."
                      value={productData.name}
                      onChange={handleProductDataChange}
                    />
                  </FormGroup>

                  {/* Variant Type Selection */}
                  <FormGroup>
                    <Label for="variantOption">Jumlah Varian</Label>
                    <div className="d-flex justify-content-between">
                      <Button
                        color={variantType === 'single' ? 'primary' : 'light'}
                        onClick={() => handleVariantChange('single')}
                      >
                        Satu Varian
                      </Button>
                      <Button
                        color={variantType === 'multiple' ? 'primary' : 'light'}
                        onClick={() => handleVariantChange('multiple')}
                      >
                        Beberapa Varian
                      </Button>
                    </div>
                  </FormGroup>

                  {/* Conditional rendering based on variant type */}
                  {variantType === 'single' ? (
                    <SingleVariantComponent initialData={singleVariantData} onVariantChange={handleVariantChange} />
                  ) : (
                    <MultipleVariantComponent initialData={multipleVariantData} onVariantsChange={handleMultipleVariantChange} />
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>

          {/* Right Settings Section */}
          <Col sm="4">
            <Card className="square-card">
              <CardBody>
                <FormGroup>
                  <Label for="item_type">Pilih Jenis Produk</Label>
                  <Input
                    type="select"
                    name="item_type"
                    id="item_type"
                    value={productData.item_type}
                    onChange={handleProductDataChange}
                  >
                    <option>Produk Fisik</option>
                    <option>Produk Digital</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="is_inventory">Pencatatan Inventori</Label>
                  <Input
                    type="select"
                    name="is_inventory"
                    id="is_inventory"
                    value={productData.is_inventory ? 'Dicatat di Inventori' : 'Tidak Dicatat di Inventori'}
                    onChange={(e) =>
                      setProductData((prev) => ({
                        ...prev,
                        is_inventory: e.target.value === 'Dicatat di Inventori',
                      }))
                    }
                  >
                    <option>Tidak Dicatat di Inventori</option>
                    <option>Dicatat di Inventori</option>
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="kategori_id">Pilih Kategori</Label>
                  <Input
                    type="select"
                    name="kategori_id"
                    id="kategori_id"
                    value={productData.kategori_id}
                    onChange={handleProductDataChange}
                  >
                    <option value="">Pilih Kategori</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                {/* Warehouse Selection */}
                <FormGroup>
                  <Label for="warehouse">Tersedia Pada Warehouse Berikut</Label>
                  <div>
                    {warehouses.map((warehouse) => (
                      <Button
                        key={warehouse.id}
                        color={selectedWarehouses.some((w) => w.id === warehouse.id) ? 'primary' : 'light'}
                        className="m-1"
                        onClick={() => handleAddWarehouse(warehouse)}
                      >
                        {warehouse.name}
                      </Button>
                    ))}
                  </div>
                  <div className="mt-3">
                    {selectedWarehouses.length > 0 && (
                      <div>
                        <Label>Warehouse yang Dipilih:</Label>
                        <div>
                          {selectedWarehouses.map((warehouse) => (
                            <Badge key={warehouse.id} color="secondary" className="mr-1">
                              {warehouse.name}
                              <Button close className="ml-2" onClick={() => handleRemoveWarehouse(warehouse)} />
                            </Badge>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </FormGroup>

                <Button color="primary" onClick={handleSave} disabled={loading}>
                  {loading ? 'Saving...' : 'Simpan'}
                </Button>
                <Button color="secondary" className="ml-2">
                  Batal
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ViewProduct;
