import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Table, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { getProduk, deleteProduct } from '../../Rest/api-produk'; // Import the getProduk and deleteProduct function
import { Link } from 'react-router-dom';

const ProductTable = () => {
  const [products, setProducts] = useState([]); // State for products
  const [dropdownOpen, setDropdownOpen] = useState(null); // State for dropdown
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete modal
  const [selectedProduct, setSelectedProduct] = useState(null); // State for selected product for deletion

  const navigate = useNavigate(); // Hook to navigate programmatically
  const { layout } = useParams(); // Extract the layout parameter from the URL

  // Fetch products when the component mounts
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productData = await getProduk(); // Fetch products from the API
        setProducts(productData); // Set products in the state
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleAddProductClick = () => {
    navigate(`/product/add/${layout}`); // Navigate to the add product page with dynamic layout parameter
  };

  // Toggle dropdown for the current product
  const toggleDropdown = (productId) => {
    setDropdownOpen(prevState => (prevState === productId ? null : productId));
  };

  // Toggle delete modal
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  // Function to open delete modal with the selected product
  const handleDeleteProductClick = (product) => {
    setSelectedProduct(product); // Set the product to be deleted
    toggleDeleteModal(); // Open the modal
  };

  // Handle the actual product deletion
  const handleDeleteProduct = async () => {
    try {
      await deleteProduct(selectedProduct.id); // Call API to delete product
      setProducts(products.filter((product) => product.id !== selectedProduct.id)); // Update product list after deletion
      toggleDeleteModal(); // Close the modal
    } catch (error) {
      console.error('Failed to delete product:', error);
    }
  };

  // Function to format the date as '03 Sep 2024, 16.06'
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('id-ID', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date).replace('.', ':'); // Adjust to match the desired format
  };

  // Function to get warehouse names
  const getWarehouseNames = (locations) => {
    if (!locations || locations.length === 0) return "Tidak tersedia di gudang";
    return locations.map(location => location.name).join(", ");
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Produk" parent="Dashboard" title="Daftar Produk" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Button color="primary" onClick={handleAddProductClick}>+ Tambah Produk</Button>
              <span>{products.length} Produk</span>
            </div>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Nama Produk</th>
                  <th>Varian</th>
                  <th>Kategori Produk</th> {/* Mengganti dari Jenis Produk ke Kategori Produk */}
                  <th>Terakhir Diubah</th>
                  <th>Dibuat Oleh</th>
                  <th>Tersedia di Gudang</th> {/* Mengganti dari Track Inventory ke Tersedia di Gudang */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products.length > 0 ? (
                  products.map((product) => (
                    <tr key={product.id}>
                      <td>
                        <Link to={`${process.env.PUBLIC_URL}/product/${product.id}/${layout}`}>
                          {product.name}
                        </Link>
                      </td>
                      <td>{product.variants.length} Varian</td>
                      <td>{product.kategori?.name || 'Tidak ada kategori'}</td> {/* Menampilkan kategori produk */}
                      <td>{formatDate(product.updatedAt)}</td> {/* Use formatDate function */}
                      <td>{product.creator.full_name}</td>
                      <td>{getWarehouseNames(product.locations)}</td> {/* Menampilkan gudang tersedia */}
                      <td>
                        <Dropdown isOpen={dropdownOpen === product.id} toggle={() => toggleDropdown(product.id)}>
                          <DropdownToggle color="link">
                            ...
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem onClick={() => navigate(`/product/edit/${product.id}/${layout}`)}>
                              Edit Produk
                            </DropdownItem>
                            <DropdownItem onClick={() => handleDeleteProductClick(product)} className="text-danger">
                              Hapus Produk
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">No Products Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Hapus Produk</ModalHeader>
        <ModalBody>
          Apakah Anda yakin ingin menghapus produk ini dan semua varian di dalamnya?
          <br />
          <strong>{selectedProduct?.name}</strong> akan dihapus dari sistem.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteModal}>Batal</Button>
          <Button color="danger" onClick={handleDeleteProduct}>Hapus</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ProductTable;
