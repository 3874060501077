import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  LogIn,  User } from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";
import { LI, UL, Image, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, LogOut } from "../../../Constant";
import { getCookie, removeCookie, setCookie } from "../../../Utils/cookies"; // Impor getCookie dan removeCookie

const UserHeader = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("Emay Walter");
  const { layoutURL } = useContext(CustomizerContext);
  
  // Memeriksa apakah cookie ada sebelum mengurai JSON-nya
  const authenticated = getCookie("authenticated") ? JSON.parse(getCookie("authenticated")) : false;
  const auth0_profile = getCookie("auth0_profile") ? JSON.parse(getCookie("auth0_profile")) : null;

  useEffect(() => {
    setProfile(getCookie("profile_image") || man);
    setName(getCookie("Name") ? getCookie("Name") : name);
  }, []);

  const Logout = () => {
    removeCookie("profile_image");
    removeCookie("token");
    removeCookie("auth0_profile");
    removeCookie("Name");
    setCookie("authenticated", false, { expires: 7, secure: true, sameSite: 'strict' });
    history(`${process.env.PUBLIC_URL}/login`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
        <Image
          attrImage={{
            className: "b-r-10 m-0",
            src: `${authenticated && auth0_profile ? auth0_profile.picture : profile}`,
            alt: "",
            style: { width: '35px', height: '35px' }, // Atur ukuran gambar
          }}
        />
        <div className="media-body">
          <span>{authenticated && auth0_profile ? auth0_profile.name : name}</span>
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            {Admin} <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/profile/${layoutURL}`),
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
