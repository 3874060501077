import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Correct import statement
import { getCookie } from '../Utils/cookies';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility function to get user_id from token
const getUserIdFromToken = () => {
  const token = getCookie('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  }
  return null;
};

// API calls

export const updateTransaksi = async (id, updatedTransaksi) => {
  try {
    const response = await api.put(`/transaksi/${id}`, updatedTransaksi, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update transaction:', error);
    throw error;
  }
};

export const deleteTransaksi = async (id) => {
  try {
    const response = await api.delete(`/transaksi/${id}`);  // Adjust the endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Failed to delete transaction:', error);
    throw error;
  }
};

export const getTransaksi = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/transaksi/user/${user_id}`);  // Adjust the endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Failed to fetch transactions:', error);
    throw error;
  }
};

export const uploadImage = async (userId, description, imageFile) => {
  const user_id = getUserIdFromToken();
  if (!user_id) throw new Error('User ID not found');
  const formData = new FormData();
  formData.append('userId', user_id);
  formData.append('description', description);
  formData.append('file', imageFile);

  try {
    const response = await api.post('/upload/payments', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};


// Fungsi untuk membuat transaksi baru
export const createTransaction = async (transactionData) => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const dataWithUserId = { ...transactionData, user_id };
    const response = await api.post('/transaksi', dataWithUserId);
    return response.data; // Mengembalikan data transaksi yang baru dibuat
  } catch (error) {
    console.error('Gagal menambahkan transaksi:', error);
    throw error;
  }
};

export const updateBank = async (id, banks) => {
  try {
    const response = await api.put(`/banks/${id}`, banks);
    return response.data; // Return the updated category
  } catch (error) {
    console.error('Failed to update category:', error);
    throw error;
  }
};

export const addBank = async (banks) => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.post('/banks/', { ...banks, user_id });
    return response.data; // Return the new category
  } catch (error) {
    console.error('Failed to add category:', error);
    throw error;
  }
};

export const getBanks = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/banks/user/${user_id}`);  // Adjust the endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Failed to fetch categories:', error);
    throw error;
  }
};

export const deleteBank = async (id) => {
  try {
    await api.delete(`/banks/${id}`);
    return { success: true }; // Return success status
  } catch (error) {
    console.error('Failed to delete category:', error);
    throw error;
  }
};

// Function to get categories
export const getCategories = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/kategori-payments/user/${user_id}`);  // Adjust the endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Failed to fetch categories:', error);
    throw error;
  }
};

// Function to add a new category
export const addCategory = async (category) => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.post('/kategori-payments/', { ...category, user_id });
    return response.data; // Return the new category
  } catch (error) {
    console.error('Failed to add category:', error);
    throw error;
  }
};

// Function to update an existing category
export const updateCategory = async (id, category) => {
  try {
    const response = await api.put(`/kategori-payments/${id}`, category);
    return response.data; // Return the updated category
  } catch (error) {
    console.error('Failed to update category:', error);
    throw error;
  }
};

// Function to delete a category
export const deleteCategory = async (id) => {
  try {
    await api.delete(`/kategori-payments/${id}`);
    return { success: true }; // Return success status
  } catch (error) {
    console.error('Failed to delete category:', error);
    throw error;
  }
};

export default api;
