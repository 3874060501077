import React, { useEffect, useState } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Row, Col
} from 'reactstrap';

const EditOrderModal = ({ isOpen, toggle, item, onSave }) => {
    const [formData, setFormData] = useState({});

  useEffect(() => {
    if (isOpen) {
      setFormData(item || {});
    }
  }, [item, isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    console.log('Data to save:', formData);
    onSave(formData);  // Call the callback with the new data
    toggle();          // Close the modal after saving
  };


  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Edit Order #{item?.id}</ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            {/* Input fields */}
            {/* Contoh untuk field nama */}

              <Col md={6}>
              <h5>Customer Data</h5>
              <FormGroup>
                <Label for="nama">Name *</Label>
                <Input type="text" name="nama" id="nama" value={formData.nama || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="nomer">Phone *</Label>
                <Input type="text" name="nomer" id="nomer" value={formData.nomer || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="alamat">Address *</Label>
                <Input type="textarea" name="alamat" id="alamat" value={formData.alamat || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="kecamatan">Sub-district</Label>
                <Input type="text" name="kecamatan" id="kecamatan" value={formData.kecamatan || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="kabupaten">City/District</Label>
                <Input type="text" name="kabupaten" id="kabupaten" value={formData.kabupaten || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="provinsi">Province</Label>
                <Input type="text" name="provinsi" id="provinsi" value={formData.provinsi || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="notes">Notes</Label>
                <Input type="textarea" name="notes" id="notes" value={formData.notes || ''} onChange={handleChange} />
              </FormGroup>
            </Col>
            {/* Order Summary */}
            <Col md={6}>
              <h5>Order Summary</h5>
              <FormGroup>
                <Label for="order">order</Label>
                <Input type="text" name="order" id="order" value={formData.order || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="numberOrder">order</Label>
                <Input type="text" name="numberOrder" id="numberOrder" value={formData.numberOrder || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="productName">Product Name</Label>
                <Input type="text" name="productName" id="productName" value={formData.productName || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="jumlah">Jumlah</Label>
                <Input type="text" name="jumlah" id="jumlah" value={formData.jumlah || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input type="text" name="status" id="status" value={formData.status || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="courier">Courier</Label>
                <Input type="text" name="courier" id="courier" value={formData.courier || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="paymentMethod">Payment Method</Label>
                <Input type="text" name="paymentMethod" id="paymentMethod" value={formData.paymentMethod || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="resi">Tracking Number</Label>
                <Input type="text" name="resi" id="resi" value={formData.resi || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="ongkir">ongkir</Label>
                <Input type="text" name="ongkir" id="ongkir" value={formData.ongkir || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="harga">harga</Label>
                <Input type="text" name="harga" id="harga" value={formData.harga || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="gross">gross</Label>
                <Input type="text" name="gross" id="gross" value={formData.gross || ''} onChange={handleChange} />
              </FormGroup>
              
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
        <Button color="primary" onClick={handleSave}>Update Order</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditOrderModal;
