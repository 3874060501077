import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Row, Col, Label } from 'reactstrap';
import './FilterModalStyles.css'; // Import the custom CSS file

const FilterModal = ({ isOpen, toggle, onApply, categories = [], selectedCategories = [], selectedStatuses = [], selectedMethods = [] }) => {
  const [localSelectedCategories, setLocalSelectedCategories] = useState([]);
  const [localSelectedStatuses, setLocalSelectedStatuses] = useState([]);
  const [localSelectedMethods, setLocalSelectedMethods] = useState([]);

  useEffect(() => {
    setLocalSelectedCategories(selectedCategories);
    setLocalSelectedStatuses(selectedStatuses);
    setLocalSelectedMethods(selectedMethods);
  }, [selectedCategories, selectedStatuses, selectedMethods]);

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    setLocalSelectedCategories(prev =>
      checked ? [...prev, value] : prev.filter(cat => cat !== value)
    );
  };

  const handleStatusChange = (e) => {
    const { value, checked } = e.target;
    setLocalSelectedStatuses(prev =>
      checked ? [...prev, value] : prev.filter(status => status !== value)
    );
  };

  const handleMethodChange = (e) => {
    const { value, checked } = e.target;
    setLocalSelectedMethods(prev =>
      checked ? [...prev, value] : prev.filter(method => method !== value)
    );
  };

  const handleApply = () => {
    onApply(localSelectedCategories, localSelectedStatuses, localSelectedMethods);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Filter</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Categories</Label>
          <Row>
            {categories.map(category => (
              <Col xs="6" md="4" key={category.id}>
                <div className="form-check custom-checkbox">
                  <Input
                    type="checkbox"
                    value={category.name}
                    id={`category-${category.id}`}
                    checked={localSelectedCategories.includes(category.name)}
                    onChange={handleCategoryChange}
                    className="form-check-input"
                  />
                  <Label for={`category-${category.id}`} className="form-check-label">{category.name}</Label>
                </div>
              </Col>
            ))}
          </Row>
        </FormGroup>
        <FormGroup>
          <Label>Statuses</Label>
          <Row>
            {['PROSES', 'DIKIRIM', 'SELESAI'].map(status => (
              <Col xs="6" md="4" key={status}>
                <div className="form-check custom-checkbox">
                  <Input
                    type="checkbox"
                    value={status}
                    id={`status-${status}`}
                    checked={localSelectedStatuses.includes(status)}
                    onChange={handleStatusChange}
                    className="form-check-input"
                  />
                  <Label for={`status-${status}`} className="form-check-label">{status}</Label>
                </div>
              </Col>
            ))}
          </Row>
        </FormGroup>
        <FormGroup>
          <Label>Methods</Label>
          <Row>
            {['TRANSFER', 'CASH', 'COD', 'LAINNYA'].map(method => (
              <Col xs="6" md="4" key={method}>
                <div className="form-check custom-checkbox">
                  <Input
                    type="checkbox"
                    value={method}
                    id={`method-${method}`}
                    checked={localSelectedMethods.includes(method)}
                    onChange={handleMethodChange}
                    className="form-check-input"
                  />
                  <Label for={`method-${method}`} className="form-check-label">{method}</Label>
                </div>
              </Col>
            ))}
          </Row>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleApply}>Apply Filters</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default FilterModal;
