import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Correct import statement
import { getCookie } from '../Utils/cookies';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility function to get user_id from token
const getUserIdFromToken = () => {
  const token = getCookie('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  }
  return null;
};

// API calls

export const createProductCategory = async (name) => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.post('/productcategories', { name, user_id });
    return response.data;
  } catch (error) {
    console.error('Error creating product category:', error);
    throw error;
  }
};

export const getProductCategories = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/productcategories/user/${user_id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch product categories:', error);
    throw error;
  }
};

export const updateProductCategory = async (categoryId, newName) => {
  try {
    const response = await api.put(`/productcategories/${categoryId}`, { name: newName });
    return response.data;
  } catch (error) {
    console.error(`Error updating product category ${categoryId}:`, error);
    throw error;
  }
};

export const deleteProductCategory = async (categoryId) => {
  try {
    const response = await api.delete(`/productcategories/${categoryId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting product category ${categoryId}:`, error);
    throw error;
  }
};

export const getProducts = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/inventories/user/${user_id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch products:', error);
    throw error;
  }
};

export const getProductById = async (id) => {
  try {
    const response = await api.get(`/inventories/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch product:', error);
    throw error;
  }
};

export const updateInventory = async (id, inventoryData) => {
  try {
    const response = await api.put(`/inventories/${id}`, inventoryData);
    return response.data;
  } catch (error) {
    console.error('Error updating inventory:', error);
    throw error;
  }
};

export const deleteProduct = async (id) => {
  try {
    const response = await api.delete(`/inventories/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete product:', error);
    throw error;
  }
};

export const uploadImage = async (userId, description, imageFile) => {
  const user_id = getUserIdFromToken();
  if (!user_id) throw new Error('User ID not found');
  const formData = new FormData();
  formData.append('userId', user_id);
  formData.append('description', description);
  formData.append('photo', imageFile);

  try {
    const response = await api.post('/upload/inventory', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};



export const createInventory = async (inventoryData) => {
    try {
      const user_id = getUserIdFromToken();
      if (!user_id) throw new Error('User ID not found');
      const dataWithUserId = { ...inventoryData, user_id };
      const response = await api.post('/inventories', dataWithUserId);
      return response.data;
    } catch (error) {
      console.error('Error creating inventory:', error);
      throw error;
    }
  };

export const updateWarehouse = async (warehouseId, newName) => {
  try {
    const response = await api.put(`/locations/${warehouseId}`, { name: newName });
    return response.data;
  } catch (error) {
    console.error(`Error updating warehouse ${warehouseId}:`, error);
    throw error;
  }
};

export const deleteWarehouse = async (warehouseId) => {
  try {
    const response = await api.delete(`/locations/${warehouseId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting warehouse ${warehouseId}:`, error);
    throw error;
  }
};

export const createWarehouse = async (warehouseName) => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.post('/locations', {
      name: warehouseName,
      user_id
    });
    return response.data;
  } catch (error) {
    console.error('Error creating warehouse:', error);
    throw error;
  }
};

export const getUserWarehouses = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/locations/user/${user_id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching locations for user :`, error);
    throw error;
  }
};

// Add more API calls as needed
export default api;
