import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import SidebarMenu from './SidebarMenu';
import { Link, useParams } from 'react-router-dom';
import { getAllLocations } from '../../Rest/api-warehouse'; // Import fungsi API untuk mendapatkan data gudang
import { toast } from 'react-toastify';

const Warehouse = () => {
  const { layout } = useParams(); // Destructure the 'layout' parameter from useParams
  const [warehouses, setWarehouses] = useState([]); // State untuk menyimpan data gudang
  const [loading, setLoading] = useState(true); // State untuk loader

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const data = await getAllLocations(); // Ambil data gudang dari API
        setWarehouses(data); // Simpan data di state
        setLoading(false); // Hentikan loader setelah data diambil
      } catch (error) {
        toast.error('Gagal mengambil data gudang!');
        setLoading(false); // Hentikan loader jika terjadi kesalahan
      }
    };

    fetchWarehouses();
  }, []); // Panggil sekali saat komponen dimuat

  // Function to format address
  const formatAddress = (alamat) => {
    if (!alamat) return "Alamat tidak tersedia";

    // Split alamat by ", " to separate initial part and detailed part
    const [initialPart, detailedPart] = alamat.split(', ');
    if (!detailedPart) return alamat; // Jika format tidak sesuai, kembalikan alamat asli

    // Split detailedPart by " - " to separate province, city, and district
    const parts = detailedPart.split(' - ');
    if (parts.length !== 3) return alamat; // Jika format tidak sesuai, kembalikan alamat asli

    const [provinsi, kota, kecamatan] = parts;

    // Format the final address
    return `${initialPart}, <br>Kec ${kecamatan.trim()}, Kab/Kota ${kota.trim()} Prov ${provinsi.trim()}`;
  };

  return (
    <Row>
      {/* Breadcrumbs */}
      <Col sm="12">
        <Breadcrumbs mainTitle="Pengaturan Gudang" parent="Settings" title="Warehouse" />
      </Col>

      {/* Sidebar */}
      <Col sm="3">
        <SidebarMenu />
      </Col>

      {/* Main Content */}
      <Col sm="9">
        <Card>
          <CardBody>
            {/* Header with "Tambah Gudang" */}
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4>Gudang</h4>
              <Link to={`/setting/warehouse/add/${layout}`} className="text-primary">+ Tambah Gudang</Link>
            </div>

            {/* Warehouse Information */}
            {loading ? (
              <p></p> // Menampilkan pesan loading saat data sedang diambil
            ) : (
              <Table className="table-borderless table-hover">
                <thead>
                  <tr>
                    <th>Nama Gudang</th>
                    <th>Lokasi Gudang</th>
                  </tr>
                </thead>
                <tbody>
                  {warehouses.map((warehouse) => (
                    <tr key={warehouse.id} className="bg-light">
                      <td>
                        <Link to={`/setting/warehouse/${warehouse.id}/${layout}`} className="text-primary">
                          {warehouse.name}
                        </Link>
                      </td>
                      <td dangerouslySetInnerHTML={{ __html: formatAddress(warehouse.alamat) }} />
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Warehouse;
