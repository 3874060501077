import React, { Fragment } from 'react';
import { Container, Row, Col,  } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { SmallWidgetsDataNew } from './DataRekap';
import SmallWidgets from './WidgetRekapPenjualan';
import OrderTableCard from './OrderTableCard';
import './styles.css';


const Penjualan = () => {

    const colStyle = {
        flex: '0 0 14.2857%',
        maxWidth: '14.2857%',
        marginBottom: '20px', // Tambahkan margin bottom sesuai kebutuhan
      };
      
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Penjualan" parent="Pages" title="Sample Page" />
      <Container fluid={true} className='general-widget'>
      <Row className='g-sm-3 height-equal-2 widget-charts'>
      {SmallWidgetsDataNew.map((data, i) => (
        <Col key={i} className="custom-col" style={colStyle} xs="12" sm="6" md="4" lg="3">
          <SmallWidgets mainClass='mb-sm-0' data={data} />
        </Col>
      ))}
    </Row>
      </Container>

      <OrderTableCard />
      
      
    </Fragment>
  );
};

export default Penjualan;