import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { uploadImage } from '../../../Rest/api-produk'; // Import the upload API
import { FaTrash } from 'react-icons/fa'; // Import the trash icon

const ImageUpload = ({ onUploadComplete, onDeleteImage = () => {} }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {
      setIsUploading(true); // Start loading
      const newImages = [];

      for (const file of acceptedFiles) {
        try {
          const response = await uploadImage(file); // Assuming response has the `photoUrl`
          const imageUrl = response.photoUrl;

          // Prevent adding duplicate images
          if (!uploadedImages.includes(imageUrl)) {
            newImages.push(imageUrl);
            onUploadComplete(imageUrl); // Pass the uploaded image URL back to the parent component
          }
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }

      setUploadedImages((prevImages) => [...prevImages, ...newImages]); // Update the state with new images
      setIsUploading(false); // End loading
    },
  });

  // Function to handle image deletion
  const handleDeleteImage = (imageUrl, index) => {
    const updatedImages = uploadedImages.filter((_, i) => i !== index); // Remove the image by index
    setUploadedImages(updatedImages); // Update the state
    onDeleteImage(imageUrl); // Call the delete function passed from parent
  };

  return (
    <div>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? 'active' : ''}`}
        style={{
          border: '2px dashed #cccccc',
          padding: '20px',
          textAlign: 'center',
          borderRadius: '5px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <input {...getInputProps()} />
        {isUploading ? (
          <Spinner color="primary" />
        ) : (
          <p>Drag and drop some files here, or click to select files</p>
        )}
      </div>

      {/* Preview uploaded images with delete button */}
     
    </div>
  );
};

export default ImageUpload;
