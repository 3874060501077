import React, { Fragment } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { Routes, Route, useParams } from 'react-router-dom';
import Business from './Business';
import Members from './Members';
import Warehouse from './Warehouse';
import Menu from './Menu';
import Permissions from './Permissions';
import SidebarMenu from './SidebarMenu'; // Import the new SidebarMenu component

const Settings = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Settings" parent="Dashboard" title="Settings" />
      <Container fluid={true}>
        <Row>
          <Col sm="3">
            {/* Sidebar Menu */}
            <SidebarMenu /> {/* Use the SidebarMenu component here */}
          </Col>

          <Col sm="9">
            {/* Main Content */}
            <Card>
              <Routes>
                <Route path="business/:layout" element={<Business />} />
                <Route path="members/:layout" element={<Members />} />
                <Route path="warehouse/:layout" element={<Warehouse />} />
                <Route path="menu/:layout" element={<Menu />} />
                <Route path="permissions/:layout" element={<Permissions />} />
                {/* Add more routes if needed */}
              </Routes>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Settings;
