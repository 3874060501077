import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from '../Utils/cookies';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility function to get user_id from token
const getUserIdFromToken = () => {
  const token = getCookie('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  }
  return null;
};

// API calls
export const getInventories = async () => {
    try {
      const user_id = getUserIdFromToken();
      if (!user_id) throw new Error('User ID not found');
      const response = await api.get(`/inventories/user/${user_id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch products:', error);
      throw error;
    }
  };

  // API calls
export const getDestination = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/destination`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch inventories:', error);
    throw error;
  }
};

// Function to create StokGudang
export const createStokGudang = async (stokGudangData) => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const dataWithUserId = { ...stokGudangData, user_id };
    const response = await api.post('/gudang', dataWithUserId);
    return response.data; // Return the new StokGudang
  } catch (error) {
    console.error('Failed to create StokGudang:', error);
    throw error;
  }
};

// Function to get all StokGudang entries for the user
export const getStokGudang = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/gudang`);  // Adjust the endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Failed to fetch StokGudang entries:', error);
    throw error;
  }
};

// Function to get a specific StokGudang by ID
export const getStokGudangById = async (id) => {
  try {
    const response = await api.get(`/gudang/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch StokGudang entry:', error);
    throw error;
  }
};

// Function to update a StokGudang entry
export const updateStokGudang = async (id, updatedStokGudang) => {
  try {
    const response = await api.put(`/gudang/${id}`, updatedStokGudang, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update StokGudang:', error);
    throw error;
  }
};

// Function to delete a StokGudang entry
export const deleteStokGudang = async (id) => {
  try {
    const response = await api.delete(`/gudang/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete StokGudang entry:', error);
    throw error;
  }
};

// Function to upload image
export const uploadImage = async (description, imageFile) => {
  const user_id = getUserIdFromToken();
  if (!user_id) throw new Error('User ID not found');
  const formData = new FormData();
  formData.append('userId', user_id);
  formData.append('description', description);
  formData.append('file', imageFile);

  try {
    const response = await api.post('/upload/gudang', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

// Function to create MutasiStok
export const createMutasiStok = async (mutasiStokData) => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const dataWithUserId = { ...mutasiStokData, user_id };
    const response = await api.post('/mutasi-stok', dataWithUserId);
    return response.data; // Return the new MutasiStok
  } catch (error) {
    console.error('Failed to create MutasiStok:', error);
    throw error;
  }
};

// Function to get all MutasiStok entries for a specific StokGudang
export const getMutasiStokByStokGudangId = async (stokGudangId) => {
  try {
    const response = await api.get(`/mutasi-stok/stok-gudang/${stokGudangId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch MutasiStok entries:', error);
    throw error;
  }
};

export default api;
