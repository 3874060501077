import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import { FaTrash, FaEdit } from 'react-icons/fa';
import ImageUpload from './ImageUpload'; // Import the ImageUpload component

const generateCombinations = (options) => {
  if (options.length === 0) return [];
  let result = options[0].values.map((value) => [value]);

  for (let i = 1; i < options.length; i++) {
    const currentOptionValues = options[i].values;
    const newResult = [];
    result.forEach((combination) => {
      currentOptionValues.forEach((value) => {
        newResult.push([...combination, value]);
      });
    });
    result = newResult;
  }
  return result;
};

const MultipleVariantComponent = ({ initialData, onVariantsChange }) => {
  const [options, setOptions] = useState([{ name: 'Size', values: ['Small'] }]);
  const [inputValue, setInputValue] = useState('');
  const [editIndex, setEditIndex] = useState({ optionIndex: null, valueIndex: null });
  const [editValue, setEditValue] = useState('');
  const [variantDetails, setVariantDetails] = useState({});

  useEffect(() => {
    if (initialData && initialData.variants) {
      const loadedOptions = [];
      const variantDetailsData = {};

      initialData.variants.forEach((variant) => {
        const option1Value = variant.option1_value || '';
        const option2Value = variant.option2_value || '';
        const variantKey = `${option1Value}${option2Value ? ' / ' + option2Value : ''}`;

        if (!loadedOptions[0]) {
          loadedOptions[0] = { name: initialData.option1_name || 'Size', values: [option1Value] };
        } else if (!loadedOptions[0].values.includes(option1Value)) {
          loadedOptions[0].values.push(option1Value);
        }

        if (option2Value) {
          if (!loadedOptions[1]) {
            loadedOptions[1] = { name: initialData.option2_name || 'Color', values: [option2Value] };
          } else if (!loadedOptions[1].values.includes(option2Value)) {
            loadedOptions[1].values.push(option2Value);
          }
        }

        const variantImages = initialData.images
          .filter((image) => image.variant_id === variant.id)
          .map((image) => ({ id: image.id, url: image.url })); // Store both id and url

        // Add variant id (id_varian) if it exists, or null if it doesn't
        variantDetailsData[variantKey] = {
          id_varian: variant.id || null,
          price: variant.price || '',
          weight: variant.weight || '',
          sku: variant.sku || '',
          hpp: variant.hpp || '',
          images: variantImages || [],
        };
      });

      setOptions(loadedOptions);
      setVariantDetails(variantDetailsData);
    }
  }, [initialData]);

  const handleOptionNameChange = (index, event) => {
    const updatedOptions = [...options];
    updatedOptions[index].name = event.target.value;
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    if (options.length < 2) {
      setOptions([...options, { name: '', values: [] }]);
    }
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const handleAddValue = (index, event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const updatedOptions = [...options];
      updatedOptions[index].values = [...updatedOptions[index].values, inputValue.trim()];
      setOptions(updatedOptions);
      setInputValue('');
    }
  };

  const handleRemoveValue = (optionIndex, valueIndex) => {
    const updatedOptions = [...options];
    updatedOptions[optionIndex].values.splice(valueIndex, 1);
    setOptions(updatedOptions);
  };

  const handleEditValue = (optionIndex, valueIndex, value) => {
    setEditIndex({ optionIndex, valueIndex });
    setEditValue(value);
  };

  const handleSaveEditValue = (optionIndex, valueIndex) => {
    const updatedOptions = [...options];
    updatedOptions[optionIndex].values[valueIndex] = editValue.trim();
    setOptions(updatedOptions);
    setEditIndex({ optionIndex: null, valueIndex: null });
  };

  const handleDetailChange = (variantKey, field, value) => {
    const updatedDetails = { ...variantDetails };
    if (!updatedDetails[variantKey]) updatedDetails[variantKey] = {};
    updatedDetails[variantKey][field] = value;
    setVariantDetails(updatedDetails);
  };

  const handleImageUpload = (variantKey, imageUrl, imageId = null) => {
    setVariantDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails };
      const existingImages = updatedDetails[variantKey]?.images || [];

      updatedDetails[variantKey] = {
        ...updatedDetails[variantKey],
        images: [...existingImages, { id: imageId, url: imageUrl }],
      };

      return updatedDetails;
    });
  };

  const handleImageDelete = (variantKey, imageIndex) => {
    setVariantDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails };

      if (updatedDetails[variantKey]?.images) {
        updatedDetails[variantKey].images = updatedDetails[variantKey].images.filter((_, idx) => idx !== imageIndex);
      }

      return updatedDetails;
    });
  };

  const generateVariantKey = (variantValues) => {
    return variantValues.join(' / ');
  };

  const variantCombinations = generateCombinations(options);

  useEffect(() => {
    const variants = variantCombinations.map((combination) => {
      const variantKey = generateVariantKey(combination);
      const option1_value = combination[0];
      const option2_value = combination[1] || '';

      return {
        option1_value,
        option2_value,
        id_varian: variantDetails[variantKey]?.id_varian || null, // Include id_varian if it exists, null otherwise
        price: variantDetails[variantKey]?.price || '',
        weight: variantDetails[variantKey]?.weight || '',
        sku: variantDetails[variantKey]?.sku || '',
        hpp: variantDetails[variantKey]?.hpp || '',
        images: variantDetails[variantKey]?.images || [],
      };
    });

    const optionNames = {
      option1_name: options[0]?.name || '',
      option2_name: options[1]?.name || '',
    };

    onVariantsChange(variants, optionNames, variantDetails);
  }, [variantDetails, options, onVariantsChange]);

  return (
    <div>
      {options.map((option, index) => (
        <div key={index} className="mb-4">
          <FormGroup>
            <Label>Opsi Produk *</Label>
            <Row>
              <Col md={11}>
                <Input
                  type="text"
                  placeholder="Contoh: Ukuran, Warna"
                  value={option.name}
                  onChange={(event) => handleOptionNameChange(index, event)}
                />
              </Col>
              <Col md={1} className="text-right">
                {options.length > 1 && (
                  <Button color="danger" size="sm" onClick={() => handleRemoveOption(index)}>
                    Hapus
                  </Button>
                )}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Label>Variasi</Label>
            <div className="d-flex flex-wrap">
              {option.values.map((value, valueIndex) => (
                <span key={valueIndex} className="badge badge-primary mr-2 mb-2 d-flex align-items-center">
                  {editIndex.optionIndex === index && editIndex.valueIndex === valueIndex ? (
                    <Input
                      type="text"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      onKeyPress={(e) => (e.key === 'Enter' ? handleSaveEditValue(index, valueIndex) : null)}
                    />
                  ) : (
                    <>
                      {value}{' '}
                      <FaEdit
                        className="mx-2"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleEditValue(index, valueIndex, value)}
                      />
                    </>
                  )}
                  <FaTrash
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                    onClick={() => handleRemoveValue(index, valueIndex)}
                  />
                </span>
              ))}
            </div>
            <Input
              type="text"
              placeholder="Tulis variasi opsi produk lalu tekan Enter"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(event) => handleAddValue(index, event)}
            />
            <small className="form-text text-muted">Pisahkan variasi opsi produk dengan Enter</small>
          </FormGroup>
        </div>
      ))}

      {options.length < 2 && (
        <Button color="primary" onClick={handleAddOption}>
          + Tambah Opsi Produk
        </Button>
      )}

      <div className="mt-4">
        <h5>Daftar Varian Produk</h5>
        {variantCombinations.map((combination, index) => {
          const variantKey = generateVariantKey(combination);
          return (
            <div key={index} className="mb-4 p-3 border rounded">
              <h6>{variantKey}</h6>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="price">Harga (Rp) *</Label>
                    <Input
                      type="number"
                      placeholder="Contoh: 10000"
                      value={variantDetails[variantKey]?.price || ''}
                      onChange={(e) => handleDetailChange(variantKey, 'price', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="weight">Berat (g) *</Label>
                    <Input
                      type="number"
                      placeholder="Contoh: 100"
                      value={variantDetails[variantKey]?.weight || ''}
                      onChange={(e) => handleDetailChange(variantKey, 'weight', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="sku">Kode SKU</Label>
                    <Input
                      type="text"
                      placeholder="Contoh: 000198LKJ"
                      value={variantDetails[variantKey]?.sku || ''}
                      onChange={(e) => handleDetailChange(variantKey, 'sku', e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="hpp">HPP (Rp)</Label>
                    <Input
                      type="number"
                      placeholder="Contoh: 5000"
                      value={variantDetails[variantKey]?.hpp || ''}
                      onChange={(e) => handleDetailChange(variantKey, 'hpp', e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <ImageUpload
                onUploadComplete={(url) => handleImageUpload(variantKey, url)}
              />

              <div className="mt-3 d-flex flex-wrap">
                {variantDetails[variantKey]?.images?.map((image, idx) => (
                  <div key={idx} className="position-relative img-thumbnail mr-2" style={{ maxWidth: '100px' }}>
                    {image.url ? (
                      <img
                        src={image.url}
                        alt={`Variant ${idx}`}
                        className="img-fluid"
                        style={{ maxWidth: '100px' }}
                      />
                    ) : (
                      <div>No Image</div>
                    )}
                    <Button
                      size="sm"
                      color="danger"
                      className="position-absolute"
                      style={{ top: '5px', right: '5px' }}
                      onClick={() => handleImageDelete(variantKey, idx)}
                    >
                      <FaTrash />
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultipleVariantComponent;
