import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const ImageModal = ({ isOpen, toggle, imageUrl }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalBody className="text-center">
        <img src={imageUrl} alt="Bukti" style={{ width: '100%', height: 'auto' }} />
      </ModalBody>
    </Modal>
  );
};

export default ImageModal;
