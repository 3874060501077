import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import { getBanks } from '../../../Rest/api-keuangan'; // Adjust the import as per your API function

const FilterModal = ({ isOpen, toggle, onApply }) => {
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await getBanks();
        setBanks(response);
      } catch (error) {
        console.error('Error fetching banks:', error);
      }
    };

    fetchBanks();
  }, []);

  const handleStatusChange = (status) => {
    const updatedStatuses = selectedStatuses.includes(status)
      ? selectedStatuses.filter(s => s !== status)
      : [...selectedStatuses, status];
    setSelectedStatuses(updatedStatuses);
  };

  const handleTypeChange = (type) => {
    const updatedTypes = selectedTypes.includes(type)
      ? selectedTypes.filter(t => t !== type)
      : [...selectedTypes, type];
    setSelectedTypes(updatedTypes);
  };

  const handleBankChange = (bank) => {
    const updatedBanks = selectedBanks.includes(bank)
      ? selectedBanks.filter(b => b !== bank)
      : [...selectedBanks, bank];
    setSelectedBanks(updatedBanks);
  };

  const handleApply = () => {
    onApply(selectedStatuses, selectedTypes, selectedBanks);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Filter Transactions</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Status</Label>
          {['Pending', 'Completed', 'Failed'].map((status, index) => (
            <div key={index}>
              <Input
                type="checkbox"
                checked={selectedStatuses.includes(status)}
                onChange={() => handleStatusChange(status)}
                style={{ backgroundColor: 'lightcoral' }}
              />{' '}
              {status}
            </div>
          ))}
        </FormGroup>
        <FormGroup>
          <Label>Type</Label>
          {['Debit', 'Kredit'].map((type, index) => (
            <div key={index}>
              <Input
                type="checkbox"
                checked={selectedTypes.includes(type)}
                onChange={() => handleTypeChange(type)}
                style={{ backgroundColor: 'lightcoral' }}
              />{' '}
              {type}
            </div>
          ))}
        </FormGroup>
        <FormGroup>
          <Label>Bank</Label>
          {banks.map((bank, index) => (
            <div key={index}>
              <Input
                type="checkbox"
                checked={selectedBanks.includes(bank.nama_bank)}
                onChange={() => handleBankChange(bank.nama_bank)}
                style={{ backgroundColor: 'lightcoral' }}
              />{' '}
              {bank.nama_bank}
            </div>
          ))}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleApply}>Apply</Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default FilterModal;
