import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Badge } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { getKategori, getGudang, getProdukById, updateProduct } from '../../../Rest/api-produk';
import './add.css';
import { FaTrash, FaEdit } from 'react-icons/fa';

// Helper function to generate combinations
const generateCombinations = (options) => {
  if (options.length === 0) return [];
  let result = options[0].values.map((value) => [value]);

  for (let i = 1; i < options.length; i++) {
    const currentOptionValues = options[i].values;
    const newResult = [];
    result.forEach((combination) => {
      currentOptionValues.forEach((value) => {
        newResult.push([...combination, value]);
      });
    });
    result = newResult;
  }
  return result;
};

const MultipleVariantComponent = ({ onVariantsChange, initialOptions, initialVariantDetails }) => {
  const [options, setOptions] = useState(initialOptions || [{ name: 'Size', values: ['Small'] }]);
  const [inputValue, setInputValue] = useState('');
  const [editIndex, setEditIndex] = useState({ optionIndex: null, valueIndex: null });
  const [editValue, setEditValue] = useState('');
  const [variantDetails, setVariantDetails] = useState(initialVariantDetails || {});

  const handleOptionNameChange = (index, event) => {
    const updatedOptions = [...options];
    updatedOptions[index].name = event.target.value;
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    if (options.length < 2) {
      setOptions([...options, { name: '', values: [] }]);
    }
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const handleAddValue = (index, event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const updatedOptions = [...options];
      updatedOptions[index].values = [...updatedOptions[index].values, inputValue.trim()];
      setOptions(updatedOptions);
      setInputValue('');
    }
  };

  const handleRemoveValue = (optionIndex, valueIndex) => {
    const updatedOptions = [...options];
    updatedOptions[optionIndex].values.splice(valueIndex, 1);
    setOptions(updatedOptions);
  };

  const handleEditValue = (optionIndex, valueIndex, value) => {
    setEditIndex({ optionIndex, valueIndex });
    setEditValue(value);
  };

  const handleSaveEditValue = (optionIndex, valueIndex) => {
    const updatedOptions = [...options];
    updatedOptions[optionIndex].values[valueIndex] = editValue.trim();
    setOptions(updatedOptions);
    setEditIndex({ optionIndex: null, valueIndex: null });
  };

  const handleDetailChange = (variantKey, field, value) => {
    const updatedDetails = { ...variantDetails };
    if (!updatedDetails[variantKey]) updatedDetails[variantKey] = {};
    updatedDetails[variantKey][field] = value;
    setVariantDetails(updatedDetails);
  };

  const handleImageUpload = (variantKey, imageUrl) => {
    setVariantDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails };
      if (!updatedDetails[variantKey]) {
        updatedDetails[variantKey] = { images: [] };
      }
      updatedDetails[variantKey].images = [...updatedDetails[variantKey].images, imageUrl];
      return updatedDetails;
    });
  };

  const generateVariantKey = (variantValues) => {
    return variantValues.join(' / ');
  };

  const variantCombinations = generateCombinations(options);

  useEffect(() => {
    const variants = variantCombinations.map((combination) => {
      const variantKey = generateVariantKey(combination);
      const option1_value = combination[0];
      const option2_value = combination[1] || '';

      return {
        option1_value,
        option2_value,
        price: variantDetails[variantKey]?.price || '',
        weight: variantDetails[variantKey]?.weight || '',
        sku: variantDetails[variantKey]?.sku || '',
        hpp: variantDetails[variantKey]?.hpp || '',
        images: variantDetails[variantKey]?.images || [],
      };
    });

    const optionNames = {
      option1_name: options[0]?.name || '',
      option2_name: options[1]?.name || '',
    };

    onVariantsChange(variants, optionNames);
  }, [variantDetails, options, onVariantsChange]);

  return (
    <div>
      {options.map((option, index) => (
        <div key={index} className="mb-4">
          <FormGroup>
            <Label>Opsi Produk *</Label>
            <Row>
              <Col md={11}>
                <Input
                  type="text"
                  placeholder="Contoh: Ukuran, Warna"
                  value={option.name}
                  onChange={(event) => handleOptionNameChange(index, event)}
                />
              </Col>
              <Col md={1} className="text-right">
                {options.length > 1 && (
                  <Button color="danger" size="sm" onClick={() => handleRemoveOption(index)}>
                    Hapus
                  </Button>
                )}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Label>Variasi</Label>
            <div className="d-flex flex-wrap">
              {option.values.map((value, valueIndex) => (
                <span key={valueIndex} className="badge badge-primary mr-2 mb-2 d-flex align-items-center">
                  {editIndex.optionIndex === index && editIndex.valueIndex === valueIndex ? (
                    <Input
                      type="text"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      onKeyPress={(e) => (e.key === 'Enter' ? handleSaveEditValue(index, valueIndex) : null)}
                    />
                  ) : (
                    <>
                      {value}{' '}
                      <FaEdit
                        className="mx-2"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleEditValue(index, valueIndex, value)}
                      />
                    </>
                  )}
                  <FaTrash
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                    onClick={() => handleRemoveValue(index, valueIndex)}
                  />
                </span>
              ))}
            </div>
            <Input
              type="text"
              placeholder="Tulis variasi opsi produk lalu tekan Enter"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(event) => handleAddValue(index, event)}
            />
            <small className="form-text text-muted">Pisahkan variasi opsi produk dengan Enter</small>
          </FormGroup>
        </div>
      ))}

      {options.length < 2 && (
        <Button color="primary" onClick={handleAddOption}>
          + Tambah Opsi Produk
        </Button>
      )}

      <div className="mt-4">
        <h5>Daftar Varian Produk</h5>
        {variantCombinations.map((combination, index) => {
          const variantKey = generateVariantKey(combination);
          return (
            <div key={index} className="mb-4 p-3 border rounded">
              <h6>{variantKey}</h6>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="price">Harga (Rp) *</Label>
                    <Input
                      type="number"
                      placeholder="Contoh: 10000"
                      value={variantDetails[variantKey]?.price || ''}
                      onChange={(e) => handleDetailChange(variantKey, 'price', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="weight">Berat (g) *</Label>
                    <Input
                      type="number"
                      placeholder="Contoh: 100"
                      value={variantDetails[variantKey]?.weight || ''}
                      onChange={(e) => handleDetailChange(variantKey, 'weight', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="sku">Kode SKU</Label>
                    <Input
                      type="text"
                      placeholder="Contoh: 000198LKJ"
                      value={variantDetails[variantKey]?.sku || ''}
                      onChange={(e) => handleDetailChange(variantKey, 'sku', e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="hpp">HPP (Rp)</Label>
                    <Input
                      type="number"
                      placeholder="Contoh: 5000"
                      value={variantDetails[variantKey]?.hpp || ''}
                      onChange={(e) => handleDetailChange(variantKey, 'hpp', e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const UpdateProduct = () => {
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [productData, setProductData] = useState({
    name: '',
    item_type: 'Produk Fisik',
    is_inventory: false,
    description: '',
    option1_name: '',
    option2_name: '',
    kategori_id: '',
    location_ids: [],
    variants: [],
    images: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryData = await getKategori();
        setCategories(categoryData);

        const warehouseData = await getGudang();
        setWarehouses(warehouseData);

        const product = await getProdukById(id);

        const variantDetails = product.variants.reduce((acc, variant) => {
          const variantKey = `${variant.option1_value} / ${variant.option2_value || ''}`;
          acc[variantKey] = {
            price: variant.price,
            weight: variant.weight,
            sku: variant.sku,
            hpp: variant.hpp,
          };
          return acc;
        }, {});

        const options = [
          { name: product.option1_name || 'Option 1', values: [...new Set(product.variants.map((v) => v.option1_value))] },
          { name: product.option2_name || 'Option 2', values: [...new Set(product.variants.map((v) => v.option2_value))] },
        ];

        setProductData({
          ...productData,
          name: product.name,
          item_type: product.item_type,
          is_inventory: product.is_inventory,
          description: product.description || '',
          option1_name: product.option1_name || '',
          option2_name: product.option2_name || '',
          kategori_id: product.kategori_id,
          location_ids: product.locations.map((loc) => loc.id),
          variants: product.variants,
          images: product.images,
        });

        setSelectedWarehouses(product.locations);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchData();
  }, [id]);

  const handleProductDataChange = (e) => {
    const { name, value } = e.target;
    setProductData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMultipleVariantChange = useCallback((multiVariants, optionNames) => {
    setProductData((prev) => ({
      ...prev,
      variants: multiVariants,
      option1_name: optionNames.option1_name,
      option2_name: optionNames.option2_name,
    }));
  }, []);

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await updateProduct(id, productData);
      console.log('Product updated successfully:', response);
      setLoading(false);
    } catch (error) {
      console.error('Error updating product:', error);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Update Produk" parent="Daftar Produk" title="Update Produk" />

      <Container fluid={true}>
        <Row>
          <Col sm="8">
            <Card className="square-card">
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label for="name">Nama Produk *</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Tulis nama produk ..."
                      value={productData.name}
                      onChange={handleProductDataChange}
                    />
                  </FormGroup>

                  <MultipleVariantComponent
                    onVariantsChange={handleMultipleVariantChange}
                    initialOptions={productData.variants.length ? [
                      { name: productData.option1_name || 'Option 1', values: [...new Set(productData.variants.map(v => v.option1_value))] },
                      { name: productData.option2_name || 'Option 2', values: [...new Set(productData.variants.map(v => v.option2_value))] },
                    ] : []}
                    initialVariantDetails={productData.variants.reduce((details, variant) => {
                      const key = `${variant.option1_value} / ${variant.option2_value || ''}`;
                      details[key] = {
                        price: variant.price,
                        weight: variant.weight,
                        sku: variant.sku,
                        hpp: variant.hpp,
                      };
                      return details;
                    }, {})}
                  />
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col sm="4">
            <Card className="square-card">
              <CardBody>
                <FormGroup>
                  <Label for="item_type">Pilih Jenis Produk</Label>
                  <Input
                    type="select"
                    name="item_type"
                    id="item_type"
                    value={productData.item_type}
                    onChange={handleProductDataChange}
                  >
                    <option>Produk Fisik</option>
                    <option>Produk Digital</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="is_inventory">Pencatatan Inventori</Label>
                  <Input
                    type="select"
                    name="is_inventory"
                    id="is_inventory"
                    value={productData.is_inventory ? 'Dicatat di Inventori' : 'Tidak Dicatat di Inventori'}
                    onChange={(e) =>
                      setProductData((prev) => ({
                        ...prev,
                        is_inventory: e.target.value === 'Dicatat di Inventori',
                      }))
                    }
                  >
                    <option>Tidak Dicatat di Inventori</option>
                    <option>Dicatat di Inventori</option>
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="kategori_id">Pilih Kategori</Label>
                  <Input
                    type="select"
                    name="kategori_id"
                    id="kategori_id"
                    value={productData.kategori_id}
                    onChange={handleProductDataChange}
                  >
                    <option value="">Pilih Kategori</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <Button color="primary" onClick={handleSave} disabled={loading}>
                  {loading ? 'Saving...' : 'Simpan'}
                </Button>
                <Button color="secondary" className="ml-2">
                  Batal
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UpdateProduct;
