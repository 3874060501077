import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from '../Utils/cookies';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility function to get user_id from token
const getUserIdFromToken = () => {
  const token = getCookie('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  }
  return null;
};

export const getKategori = async () => {
    try {
      const user_id = getUserIdFromToken();
      if (!user_id) throw new Error('User ID not found');
      const response = await api.get(`/productcategories/user/${user_id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch product categories:', error);
      throw error;
    }
  };

  export const getProduk  = async () => {
    try {
      const user_id = getUserIdFromToken();
      if (!user_id) throw new Error('User ID not found');
      const response = await api.get(`/product/user/${user_id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch product :', error);
      throw error;
    }
  };
  
  // Update product function with the correct method and dynamic ID
  export const updateProduct = async (id, updatedProductData) => {
    try {
      const user_id = getUserIdFromToken(); // Ensure this function returns a valid user_id
      if (!user_id) throw new Error('User ID not found');
  
      // Include user_id in the updatedProductData if it's required by the backend
      const updatedDataWithUserId = {
        ...updatedProductData,
        user_id: user_id, // Add user_id to the payload
      };
  
      // Sending a PUT request to update the product
      const response = await api.put(`/product/${id}`, updatedDataWithUserId);
      return response.data;
    } catch (error) {
      console.error('Failed to update product:', error);
      throw error;
    }
  };

  // Delete product function with dynamic ID
export const deleteProduct = async (id) => {
  try {
    const user_id = getUserIdFromToken(); // Ensure this function returns a valid user_id
    if (!user_id) throw new Error('User ID not found');

    // Sending a DELETE request to remove the product
    const response = await api.delete(`/product/${id}`, {
      data: { user_id: user_id }, // Send user_id in request body
    });
    return response.data;
  } catch (error) {
    console.error('Failed to delete product:', error);
    throw error;
  }
};

  

  export const getProdukById = async (productId) => {
    try {
      const response = await api.get(`/product/${productId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch product:', error);
      throw error;
    }
  };
  
  export const getGudang = async () => {
    try {
      const user_id = getUserIdFromToken();
      if (!user_id) throw new Error('User ID not found');
      const response = await api.get(`/locations/user/${user_id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching locations for user :`, error);
      throw error;
    }
  };

  export const uploadImage = async (imageFile) => {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const formData = new FormData();
    formData.append('userId', user_id);
    formData.append('description', "upload produk");
    formData.append('photo', imageFile);
  
    try {
      const response = await api.post('/upload/inventory', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  // Function to create a new product
export const createProduct = async (productData) => {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
  
    const payload = {
      ...productData,
      user_id, // Ensure the user ID is passed
    };
  
    try {
      const response = await api.post('/product', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error creating product:', error);
      throw error;
    }
  };

export default api;
