import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import AsyncSelect from 'react-select/async'; // Import AsyncSelect from react-select/async
import { Breadcrumbs } from '../../../AbstractElements';
import SidebarMenu from './../SidebarMenu';
import { useNavigate, useParams } from 'react-router-dom';
import './WarehouseDetailView.css';
import { getLocationById, updateLocation, getDestination, deleteLocation } from '../../../Rest/api-warehouse'; // Import delete API function
import { toast } from 'react-toastify';

const WarehouseDetailView = () => {
  const { id } = useParams(); // Get the warehouse ID from the URL parameter
  const [warehouse, setWarehouse] = useState({
    name: '',
    address: '',
    district: '',
    updatedAt: '',
  });
  const [isEditing, setIsEditing] = useState(false); // State for edit mode
  const [loading, setLoading] = useState(false); // State for loading during save
  const [dataLoaded, setDataLoaded] = useState(false); // State to control data loading
  const [destinations, setDestinations] = useState([]); // State for destination data
  const [selectedDestination, setSelectedDestination] = useState(null); // State for selected district
  const [deleteModal, setDeleteModal] = useState(false); // State for delete confirmation modal
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const fetchWarehouse = async () => {
      try {
        const data = await getLocationById(id); // Fetch warehouse data by ID from the API
        
        // Split the address into address and district
        const [addressPart, districtPart] = data.alamat.split(',').map(part => part.trim());
        
        setWarehouse({
          name: data.name,
          address: addressPart || '',  // Set address as the part before the comma
          district: districtPart || '', // Set district as the part after the comma
          updatedAt: data.updatedAt, // Set updatedAt
        });

        setDataLoaded(true); // Set data loaded to true once data is fetched
      } catch (error) {
        toast.error('Gagal mengambil data gudang!');
        setDataLoaded(true); // Set data loaded to true even if there's an error to show the UI
      }
    };

    fetchWarehouse();
  }, [id]);

  const handleEditClick = () => {
    setIsEditing(true); // Enable edit mode
  };

  const handleCancelClick = () => {
    setIsEditing(false); // Disable edit mode and revert data
  };

  const handleSaveClick = async () => {
    setLoading(true);
    try {
      await updateLocation(id, { name: warehouse.name, alamat: `${warehouse.address}, ${warehouse.district}` }); // Update warehouse data on the server
      toast.success('Gudang berhasil diperbarui!');
      setIsEditing(false);
    } catch (error) {
      toast.error('Gagal memperbarui gudang!');
    } finally {
      setLoading(false);
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal); // Toggle the delete confirmation modal
  };

  const handleDeleteClick = async () => {
    setLoading(true);
    try {
      await deleteLocation(id); // Call delete API
      toast.success('Gudang berhasil dihapus!');
      setDeleteModal(false); // Close the modal

      // Extract layout from URL and use it for navigation
      const currentURL = window.location.href;
      const urlParts = currentURL.split('/');
      const layoutURL = urlParts[urlParts.length - 1];

      navigate(`${process.env.PUBLIC_URL}/setting/warehouse/${layoutURL}`); // Navigate to the "Gudang" page with the layout
    } catch (error) {
      toast.error('Gagal menghapus gudang!');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWarehouse((prevWarehouse) => ({
      ...prevWarehouse,
      [name]: value,
    }));
  };

  const handleDistrictChange = (selectedOption) => {
    setSelectedDestination(selectedOption);
    setWarehouse((prevWarehouse) => ({
      ...prevWarehouse,
      district: selectedOption ? selectedOption.label : '',
    }));
  };

  const filterDestinations = (inputValue) => {
    return destinations
      .filter((destination) =>
        destination.province?.toLowerCase().includes(inputValue.toLowerCase()) ||
        destination.city?.toLowerCase().includes(inputValue.toLowerCase()) ||
        destination.subdistrict?.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((destination) => ({
        value: destination.id,
        label: `${destination.province} - ${destination.city} - ${destination.subdistrict}`,
        ...destination,
      }));
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterDestinations(inputValue));
    }, 1000);
  };

  useEffect(() => {
    getDestination().then(setDestinations).catch(() => toast.error('Gagal mendapatkan destinasi!'));
  }, []);

  // Function to format date to Jakarta timezone
  const formatDateToJakartaTime = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('id-ID', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Asia/Jakarta',
    }).format(date);
  };

  return (
    <Row>
      {/* Breadcrumbs */}
      <Col sm="12">
        <Breadcrumbs
          mainTitle="Pengaturan Gudang"
          parent="Settings"
          title="Warehouse"
        />
      </Col>

      {/* Sidebar */}
      <Col sm="3">
        <SidebarMenu /> {/* Include SidebarMenu for consistent navigation */}
      </Col>

      {/* Main Content */}
      <Col sm="9">
        {dataLoaded ? (
          <Card className="warehouse-detail-card">
            <CardBody>
              {/* Header with Title and Edit Button */}
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4>Detil Gudang: {warehouse.name}</h4>
                {isEditing ? (
                  <>
                    <Button color="success" onClick={handleSaveClick} disabled={loading}>
                      {loading ? <Spinner size="sm" color="light" /> : 'Simpan'}
                    </Button>
                    <Button color="secondary" onClick={handleCancelClick} className="ml-2" disabled={loading}>
                      Batal
                    </Button>
                  </>
                ) : (
                  <Button color="primary" onClick={handleEditClick}>
                    Edit
                  </Button>
                )}
              </div>

              {/* Warehouse Detail Form */}
              <FormGroup>
                <Label for="gudang">Nama Gudang</Label>
                <Input
                  type="text"
                  name="name"
                  id="gudang"
                  value={warehouse.name}
                  onChange={handleInputChange}
                  readOnly={!isEditing} // Editable only in edit mode
                  style={{ background: isEditing ? '#ffffff' : '#e4e4e4' }} // Change background in edit mode
                />
              </FormGroup>

              <FormGroup>
                <Label for="alamat">Alamat</Label>
                <Input
                  type="textarea"
                  name="address"
                  id="alamat"
                  value={warehouse.address}
                  onChange={handleInputChange}
                  readOnly={!isEditing} // Editable only in edit mode
                  style={{ background: isEditing ? '#ffffff' : '#e4e4e4' }} // Change background in edit mode
                  rows="3"
                />
              </FormGroup>

              {isEditing && (
                <FormGroup>
                  <Label for="district">Kecamatan</Label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions={false}
                    value={selectedDestination}
                    onChange={handleDistrictChange}
                    isClearable
                    noOptionsMessage={() => 'Ketik kecamatan atau kota'}
                  />
                </FormGroup>
              )}

              {/* Footer with Last Updated and Delete Button */}
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div>
                  <p className="text-muted mb-0">Terakhir Diperbaharui</p>
                  <p className="mb-0">{warehouse.name}</p> {/* Adjust this line to display the correct user */}
                  <p className="mb-0">{formatDateToJakartaTime(warehouse.updatedAt)}</p> {/* Format updatedAt with Jakarta time */}
                </div>
                {!isEditing && (
                  <Button color="danger" onClick={toggleDeleteModal}>
                    Hapus Gudang
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        ) : (
          <div>Loading data...</div>
        )}
      </Col>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Konfirmasi Hapus</ModalHeader>
        <ModalBody>
          Apakah Anda yakin ingin menghapus gudang ini?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteClick} disabled={loading}>
            {loading ? <Spinner size="sm" color="light" /> : 'Hapus Gudang'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleDeleteModal} disabled={loading}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default WarehouseDetailView;
