import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from '../Utils/cookies';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility function to get user_id from token
const getUserIdFromToken = () => {
  const token = getCookie('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  }
  return null;
};

// API calls
export const getInventories = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/product/user/${user_id}`);
    
    // Filter the inventories to only include those with a "Produk Jadi" category
    const produkJadiInventories = response.data.filter(
      inventory => inventory.kategori.name === "Produk Jadi"
    );
    
    return produkJadiInventories;
  } catch (error) {
    console.error('Failed to fetch Produk Jadi:', error);
    throw error;
  }
};


// API calls
export const getDestination = async () => {
    try {
      const user_id = getUserIdFromToken();
      if (!user_id) throw new Error('User ID not found');
      const response = await api.get(`/destination`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch inventories:', error);
      throw error;
    }
  };

// Function to create an order
export const createOrder = async (orderData) => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const dataWithUserId = { ...orderData, user_id };
    const response = await api.post('/orders', dataWithUserId);
    return response.data; // Return the new order
  } catch (error) {
    console.error('Failed to create order:', error);
    throw error;
  }
};


export default api;
