import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from '../Utils/cookies';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility function to get user_id from token
const getUserIdFromToken = () => {
  const token = getCookie('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  }
  return null;
};

// API calls

  // API calls
export const getDestination = async () => {
  try {
    const user_id = getUserIdFromToken();
    if (!user_id) throw new Error('User ID not found');
    const response = await api.get(`/destination`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch inventories:', error);
    throw error;
  }
};

// Membuat lokasi baru dengan menyertakan user_id
export const createLocation = async (locationData) => {
    console.log('createLocation called with:', locationData); // Tambahkan log ini
    try {
      const user_id = getUserIdFromToken(); // Mendapatkan user_id dari token
      if (!user_id) throw new Error('User ID not found');
  
      const dataWithUserId = { ...locationData, user_id };
  
      const response = await api.post('/locations', dataWithUserId); // Pastikan rutenya sesuai dengan backend Anda
      return response.data;
    } catch (error) {
      console.error('Failed to create location:', error);
      throw error;
    }
  };
  
  // Mendapatkan semua lokasi
  export const getAllLocations = async () => {
    try {
      const user_id = getUserIdFromToken();
      const response = await api.get(`/locations/user/${user_id}`); // Pastikan rutenya sesuai dengan backend Anda
      return response.data;
    } catch (error) {
      console.error('Failed to fetch locations:', error);
      throw error;
    }
  };
  
  // Mendapatkan satu lokasi berdasarkan ID
  export const getLocationById = async (id) => {
    try {
      const response = await api.get(`/locations/${id}`); // Pastikan rutenya sesuai dengan backend Anda
      return response.data;
    } catch (error) {
      console.error('Failed to fetch location by ID:', error);
      throw error;
    }
  };
  
  // Mendapatkan semua lokasi untuk pengguna tertentu
  export const getLocationByUserId = async (userId) => {
    try {
      const response = await api.get(`/locations/user/${userId}`); // Pastikan rutenya sesuai dengan backend Anda
      return response.data;
    } catch (error) {
      console.error('Failed to fetch locations by user ID:', error);
      throw error;
    }
  };
  
  // Memperbarui lokasi
  export const updateLocation = async (id, locationData) => {
    try {
      const user_id = getUserIdFromToken();
      if (!user_id) throw new Error('User ID not found');
  
      const dataWithUserId = { ...locationData, user_id };
  
      const response = await api.put(`/locations/${id}`, dataWithUserId); // Pastikan rutenya sesuai dengan backend Anda
      return response.data;
    } catch (error) {
      console.error('Failed to update location:', error);
      throw error;
    }
  };
  
  // Menghapus lokasi
  export const deleteLocation = async (id) => {
    try {
      const response = await api.delete(`/locations/${id}`); // Pastikan rutenya sesuai dengan backend Anda
      return response.data;
    } catch (error) {
      console.error('Failed to delete location:', error);
      throw error;
    }
  };

  
export default api;