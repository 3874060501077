import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt, FaPen, FaPlus, FaBox } from 'react-icons/fa';
import { Breadcrumbs } from '../../../AbstractElements';
import { getUserWarehouses, createWarehouse, deleteWarehouse, updateWarehouse } from '../../../Rest/api-inventory';

const WarehouseCard = ({ warehouse, onDelete, onEdit }) => (
  <Card className="mb-3" style={{ minWidth: '250px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', position: 'relative' }}>
    <CardBody className="d-flex align-items-center justify-content-between" style={{ padding: '20px' }}>
      <div>
        <h6 className="mb-1" style={{ fontSize: '0.9rem' }}>Gudang</h6>
        <h5 className="mb-0" style={{ fontWeight: 'bold' }}>{warehouse.name}</h5>
      </div>
      <div className="d-flex align-items-center">
        <FaPen className="mr-3" style={{ cursor: 'pointer', fontSize: '1.2rem', color: '#6c757d' }} onClick={() => onEdit(warehouse)} title="Edit" />
        <div style={{ width: '10px' }}></div> {/* Spacer */}
        <FaTrashAlt style={{ cursor: 'pointer', fontSize: '1.2rem', color: '#dc3545' }} onClick={() => onDelete(warehouse.id)} title="Delete" />
      </div>
    </CardBody>
    <div style={{ position: 'absolute', top: '20px', right: '20px', fontSize: '3rem', color: '#e0e0e0', zIndex: '-1' }}>
      <FaBox />
    </div>
  </Card>
);

const CreateWarehouseModal = ({ isOpen, toggle, onSave }) => {
  const [warehouseName, setWarehouseName] = useState('');

  const handleSave = () => {
    if (!warehouseName.trim()) {
      toast.error("Warehouse name cannot be empty.");
      return;
    }
    onSave(warehouseName);
    setWarehouseName(''); // Reset the form
    toggle(); // Close modal
  };

  const handleCancel = () => {
    setWarehouseName('');
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCancel}>
      <ModalHeader toggle={handleCancel}>Create New Warehouse</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="warehouseName">Warehouse Name</Label>
          <Input
            id="warehouseName"
            name="name"
            placeholder="Enter warehouse name"
            value={warehouseName}
            onChange={(e) => setWarehouseName(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>Save</Button>
        <Button color="secondary" onClick={handleCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

const EditWarehouseModal = ({ isOpen, toggle, onSave, initialName }) => {
  const [warehouseName, setWarehouseName] = useState(initialName);

  useEffect(() => {
    setWarehouseName(initialName);
  }, [initialName]);

  const handleSave = () => {
    if (!warehouseName.trim()) {
      toast.error("Warehouse name cannot be empty.");
      return;
    }
    onSave(warehouseName);
    toggle(); // Close modal
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Warehouse</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="warehouseName">Warehouse Name</Label>
          <Input
            id="warehouseName"
            name="name"
            placeholder="Enter new warehouse name"
            value={warehouseName}
            onChange={(e) => setWarehouseName(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>Save Changes</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

const ConfirmDeleteModal = ({ isOpen, toggle, onConfirmDelete }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirm Deletion</ModalHeader>
      <ModalBody>Are you sure you want to delete this warehouse?</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onConfirmDelete}>Delete</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

const WarehouseList = () => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    fetchWarehouses();
  }, []);

  const fetchWarehouses = async () => {
    try {
      const data = await getUserWarehouses(1); // Assuming user ID 1
      setWarehouses(data);
    } catch (error) {
      //console.error('Failed to fetch warehouses:', error);
      //toast.error('Failed to fetch warehouses.');
    }
  };

  const handleCreateWarehouse = async (name) => {
    try {
      await createWarehouse(name);
      fetchWarehouses(); // Refresh the list
      toast.success("Warehouse created successfully!");
    } catch (error) {
      console.error('Error creating a new warehouse:', error);
      toast.error('Error creating warehouse.');
    }
  };

  const handleEditWarehouse = async (newName) => {
    try {
      await updateWarehouse(selectedWarehouse.id, newName);
      fetchWarehouses(); // Refresh the list
      toast.success("Warehouse updated successfully!");
    } catch (error) {
      console.error('Error updating warehouse:', error);
      toast.error('Error updating warehouse.');
    }
    setEditModal(false); // Close the edit modal
  };

  const showEditModal = (warehouse) => {
    setSelectedWarehouse(warehouse);
    setEditModal(true);
  };

  const showDeleteModal = (warehouseId) => {
    setSelectedWarehouse({ id: warehouseId });
    setDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteWarehouse(selectedWarehouse.id);
      setWarehouses(warehouses.filter(warehouse => warehouse.id !== selectedWarehouse.id)); // Update local state immediately
      toast.success("Warehouse deleted successfully!");
    } catch (error) {
      console.error('Failed to delete warehouse:', error);
      toast.error('Failed to delete warehouse.');
    }
    setDeleteModal(false); // Close the delete modal
  };

  return (
    <Fragment>
      <ToastContainer />
      <Breadcrumbs mainTitle="Manage Warehouses" parent="Dashboard" title="Warehouses" />
      <Container fluid={true} className='general-widget'>
        <Row className='g-sm-3 height-equal-2 widget-charts'>
          <Col md="12">
            <Button color="success" className="mb-3" onClick={() => setModal(true)}><FaPlus /> Add Warehouse</Button>
          </Col>
          {warehouses.map((warehouse) => (
            <Col md="6" lg="4" key={warehouse.id}>
              <WarehouseCard warehouse={warehouse} onDelete={showDeleteModal} onEdit={showEditModal} />
            </Col>
          ))}
        </Row>
      </Container>
      <CreateWarehouseModal isOpen={modal} toggle={() => setModal(false)} onSave={handleCreateWarehouse} />
      <EditWarehouseModal isOpen={editModal} toggle={() => setEditModal(false)} onSave={handleEditWarehouse} initialName={selectedWarehouse.name || ''} />
      <ConfirmDeleteModal isOpen={deleteModal} toggle={() => setDeleteModal(false)} onConfirmDelete={handleDelete} />
    </Fragment>
  );
};

export default WarehouseList;
