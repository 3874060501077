import axios from 'axios';
import { getCookie } from '../Utils/cookies';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
  
});



// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to delete an order by ID
export const deleteOrder = async (orderId) => {
  try {
    const response = await api.delete(`/orders/${orderId}`); // Adjust the endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Error deleting order:', error);
    throw error;
  }
};

// Example API call
export const getOrders = async () => {
  try {
    const response = await api.get('/orders');
    return response.data;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

export const getProducts = async () => {
  try {
    const response = await api.get('/inventories/user/1');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch products:', error);
    throw error;
  }
};

export const getProductById = async (id) => {
  try {
    const response = await api.get(`/inventories/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch product:', error);
    throw error;
  }
};

export const updateInventory = async (id, inventoryData) => {
  try {
    const response = await api.put(`/inventories/${id}`, inventoryData);
    return response.data;
  } catch (error) {
    console.error('Error updating inventory:', error);
    throw error;
  }
};

export const deleteProduct = async (id) => {
  try {
    const response = await api.delete(`/inventories/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete product:', error);
    throw error;
  }
};

export const uploadImage = async (userId, description, imageFile) => {
  const formData = new FormData();
  formData.append('userId', userId);
  formData.append('description', description);
  formData.append('photo', imageFile);

  try {
    const response = await api.post('/upload/inventory', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const createInventory = async (inventoryData) => {
  try {
    const response = await api.post('/inventories', inventoryData);
    return response.data;
  } catch (error) {
    console.error('Error creating inventory:', error);
    throw error;
  }
};


// Add these to your API utility file
export const getProductCategories = async () => {
  try {
    const response = await api.get('/productcategories');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch product categories:', error);
    throw error;
  }
};

export const createProductCategory = async (name) => {
  try {
    const response = await api.post('/productcategories', { name });
    return response.data;
  } catch (error) {
    console.error('Error creating product category:', error);
    throw error;
  }
};

export const updateProductCategory = async (categoryId, newName) => {
  try {
    const response = await api.put(`/productcategories/${categoryId}`, { name: newName });
    return response.data;
  } catch (error) {
    console.error(`Error updating product category ${categoryId}:`, error);
    throw error;
  }
};

export const deleteProductCategory = async (categoryId) => {
  try {
    const response = await api.delete(`/productcategories/${categoryId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting product category ${categoryId}:`, error);
    throw error;
  }
};


// In your API utility file
export const updateWarehouse = async (warehouseId, newName) => {
  try {
    const response = await api.put(`/locations/${warehouseId}`, { name: newName });
    return response.data; // Assuming the server responds with the updated warehouse data
  } catch (error) {
    console.error(`Error updating warehouse ${warehouseId}:`, error);
    throw error;
  }
};



export const deleteWarehouse = async (warehouseId) => {
  try {
    const response = await api.delete(`/locations/${warehouseId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting warehouse ${warehouseId}:`, error);
    throw error;
  }
};


// Function to create a new warehouse
export const createWarehouse = async (warehouseName) => {
  try {
    //const userId = getCookie('user_id'); // Assuming user_id is stored in a cookie
    const response = await api.post('/locations', {
      name: warehouseName,
      user_id: 1
    });
    return response.data;
  } catch (error) {
    console.error('Error creating warehouse:', error);
    throw error;
  }
};

// Fetch locations for a specific user
export const getUserWarehouses = async (userId) => {
  try {
    const response = await api.get(`/locations/user/${userId}`);
    return response.data; // Returns the list of locations
  } catch (error) {
    console.error(`Error fetching locations for user ${userId}:`, error);
    throw error;
  }
};



// API call to update order
export const updateOrder = async (orderId, updatedData) => {
    const formattedData = {
        order_number: updatedData.numberOrder,
        customer: {
            name: updatedData.nama,
            phone_number: updatedData.nomer
        },
        detail: {
            name: updatedData.productName,
            jumlah: updatedData.jumlah
        },
        address: {
            address: updatedData.alamat,
            sub_district: updatedData.kecamatan,
            district: updatedData.kabupaten,
            province: updatedData.provinsi
        },
        courier: updatedData.courier,
        status: updatedData.status,
        payment_method: updatedData.paymentMethod,
        tracking_number: updatedData.resi,
        notes: updatedData.notes,
        shipping_cost: parseFloat(updatedData.ongkir.replace(/Rp|,/g, '')), // assuming 'ongkir' is formatted as 'RpX,XXX,XXX'
        product_price: parseFloat(updatedData.harga.replace(/Rp|,/g, '')), // same assumption for 'harga'
        gross_amount: parseFloat(updatedData.gross.replace(/Rp|,/g, '')) // same assumption for 'gross'
    };

    try {
      const response = await api.put(`/orders/${orderId}`, formattedData);
      return response.data; // Return the updated data
    } catch (error) {
      console.error(`Error updating order ${orderId}:`, error);
      throw error;
    }
};

// Add more API calls as needed
export default api;
