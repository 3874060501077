import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { addCategory } from '../../../Rest/api-keuangan'; // Ensure this import path is correct

const AddCategoryForm = ({ fetchData }) => {
    const [categoryData, setCategoryData] = useState({ tipe: '', nama: '' });

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await addCategory(categoryData);
            toast.success("Category added successfully!");
            setCategoryData({ tipe: '', nama: '' });
            fetchData(); // Refresh the data after adding a new category
        } catch (error) {
            console.error('Error adding category:', error);
            toast.error('Error adding category');
        }
    };

    return (
        <CardBody>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="type">Type</Label>
                    <Input type="select" name="type" id="type" value={categoryData.tipe} onChange={e => setCategoryData({ ...categoryData, tipe: e.target.value })} required>
                        <option value="">Select Type</option>
                        <option value="PEMASUKAN">PEMASUKAN</option>
                        <option value="PENGELUARAN">PENGELUARAN</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="name">Name</Label>
                    <Input type="text" name="name" id="name" placeholder="Enter category name" value={categoryData.nama} onChange={e => setCategoryData({ ...categoryData, nama: e.target.value })} required />
                </FormGroup>
                <Button type="submit" color="primary">Add Category</Button>
            </Form>
        </CardBody>
    );
};

export default AddCategoryForm;
