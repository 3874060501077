import React, { useState } from 'react';
import { Container, Row, Col, Input, FormGroup, Label, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerCustomStyles.css"; // Import the custom CSS file
import FilterModal from './FilterModal';

const SearchAndFilter = ({ onSearch, onFilter, toggleConfig }) => {
  const [searchText, setSearchText] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    onSearch(e.target.value);
  };

  const toggleFilterModal = () => setFilterModalOpen(!filterModalOpen);

  const handleApplyFilters = (statuses, types, banks) => {
    setSelectedStatuses(statuses);
    setSelectedTypes(types);
    setSelectedBanks(banks);
    onFilter(selectedDateRange.startDate, selectedDateRange.endDate, statuses, types, banks);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setSelectedDateRange({ startDate: start, endDate: end });
    onFilter(start, end, selectedStatuses, selectedTypes, selectedBanks);
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="search">Search</Label>
            <Input
              type="text"
              name="search"
              id="search"
              placeholder="Name or Transaction Number"
              value={searchText}
              onChange={handleSearchChange}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="dateRange">Date Range</Label>
            <DatePicker
              selected={selectedDateRange.startDate}
              onChange={handleDateChange}
              startDate={selectedDateRange.startDate}
              endDate={selectedDateRange.endDate}
              selectsRange
              placeholderText="Select date range"
              className="form-control"
            />
          </FormGroup>
        </Col>
        <Col md={3} className="d-flex align-items-end" style={{ marginBottom: '17px' }}>
          <Button color="primary" onClick={toggleFilterModal} className="w-100">Filter</Button>
        </Col>
        <Col md={3} className="d-flex align-items-end" style={{ marginBottom: '17px' }}>
          <Button color="primary" onClick={toggleConfig} className="w-100">Configure Columns</Button>
        </Col>
      </Row>
      <FilterModal
        isOpen={filterModalOpen}
        toggle={toggleFilterModal}
        onApply={handleApplyFilters}
      />
    </Container>
  );
};

export default SearchAndFilter;
