import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Input, Label } from 'reactstrap';

const FilterDropdown = ({ dropdownOpen, toggleDropdown, filterOptions, handleStatusChange, handlePaymentMethodChange, paymentOptions }) => {
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} style={{ width: '100%' }}>
      <DropdownToggle
        caret
        style={{
          backgroundColor: 'white',
          color: 'black',
          border: '1px solid #ced4da',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 15px',
          borderRadius: '0.25rem',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' // subtle shadow
        }}
      >
        <span style={{ marginRight: 'auto' }}>Filter</span>
      </DropdownToggle>
      <DropdownMenu style={{ width: '100%', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.05)' }}>
        <DropdownItem header style={{ color: '#495057' }}>Status</DropdownItem>
        {['Belum Dikirim', 'Dikirim', 'Sampai', 'Retur', 'Retur Diterima', 'Pencairan'].map((status) => (
          <DropdownItem key={status} toggle={false} style={{ padding: '8px 20px' }}>
            <FormGroup check style={{ margin: 0 }}>
              <Label >
                <Input
                  type="checkbox"
                  checked={filterOptions.status.includes(status)}
                  onChange={() => handleStatusChange(status)}
                  style={{ backgroundColor: 'lightcoral' }}
                />
                {status}
              </Label>
            </FormGroup>
          </DropdownItem>
        ))}
        <DropdownItem divider />
        <DropdownItem header style={{ color: '#495057' }}>Payment Method</DropdownItem>
        {paymentOptions.map((option) => (
          <DropdownItem key={option.value} toggle={false} style={{ padding: '8px 20px' }}>
            <FormGroup check style={{ margin: 0 }}>
              <Label >
                <Input
                  type="checkbox"
                  checked={filterOptions.paymentMethods.includes(option.value)}
                  onChange={() => handlePaymentMethodChange(option.value)}
                  style={{ backgroundColor: 'lightcoral' }}
                />
                {option.label}
              </Label>
            </FormGroup>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default FilterDropdown;
