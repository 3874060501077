import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
// import { Container, Row, Col, Button } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
// import { useNavigate } from 'react-router-dom';
import OrderanTable from './OrderanTable';

const Orderan = () => {
  // const navigate = useNavigate();

  // const handleCreateProduct = () => {
  //   const currentURL = window.location.href;
  //   const urlParts = currentURL.split('/');
  //   const layoutURL = urlParts[urlParts.length - 1];
  //   navigate(`${process.env.PUBLIC_URL}/createproduct/${layoutURL}`);
  // };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Penjualan" parent="Dashboard" title="Penjualan List" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {/* <Col md="12">
              <Button color="success" className="mb-3" onClick={handleCreateProduct}>Create Product</Button>
            </Col> */}
                <OrderanTable />

          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Orderan;
