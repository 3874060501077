import React, { Fragment } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import RiwayatKeuanganTable from './RiwayatKeuanganTable';
import { useNavigate } from 'react-router-dom';



const RiwayatKeuangan = () => {
  const navigate = useNavigate();

  const handleCreate = () => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const layoutURL = urlParts[urlParts.length - 1];
    navigate(`${process.env.PUBLIC_URL}/create-transaksi/${layoutURL}`);
  }
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Riwayat Keuangan" parent="Dashboard" title="Riwayat Keuangan" />
      <Container fluid={true}>
        <Row>
 
          <Col sm="12">
            <Button color="success" className="mb-3" onClick={handleCreate} style={{ marginLeft: '10px' }}>
              Buat Transaksi
            </Button>
            <RiwayatKeuanganTable />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RiwayatKeuangan;
