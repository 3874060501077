import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Input, Button } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import { getStokGudang, deleteStokGudang } from '../../Rest/api-gudang';
import { FaTrash } from 'react-icons/fa';
import moment from 'moment';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ColumnConfigModal from './ColumnConfigModal';
import ImageModal from './ImageModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';


const StokTable = () => {
    
  const navigate = useNavigate();

  const handleCreate = () => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const layoutURL = urlParts[urlParts.length - 1];
    navigate(`${process.env.PUBLIC_URL}/input-stok/${layoutURL}`);
  }

  const [stokGudang, setStokGudang] = useState([]);
  const [filteredStokGudang, setFilteredStokGudang] = useState([]);
  const [currentStok, setCurrentStok] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [configModal, setConfigModal] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filterType, setFilterType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [columns, setColumns] = useState([
    { name: 'No', selector: (row, index) => index + 1, sortable: false, center: true, grow: 0.1, visible: true },
    { name: 'Tanggal', selector: row => moment(row.tanggal).format('DD-MM-YYYY'), sortable: true, visible: true },
    { 
      name: 'Produk', 
      selector: row => `${row.inventory.name}`, 
      sortable: true, 
      visible: true 
    },
    { name: 'Jenis Transaksi', selector: row => row.jenis_transaksi, sortable: true, visible: true },
    { name: 'Jumlah', selector: row => row.jumlah, sortable: true, visible: true },
    { name: 'Nomor Resi / Surat Jalan', selector: row => row.nomor_resi_surat_jalan, sortable: true, visible: true },
    { 
      name: 'Bukti Foto', 
      selector: row => row.bukti_foto ? (
        <img 
          src={row.bukti_foto} 
          alt="Bukti Foto" 
          style={{ width: '50px', height: '50px', cursor: 'pointer' }} 
          onClick={() => { setImageUrl(row.bukti_foto); setImageModalOpen(true); }} 
        />
      ) : 'No Image', 
      sortable: false,
      wrap: true, 
      visible: true 
    },
    {
      name: 'Keterangan',
      selector: row => row.mutasi && row.mutasi.length > 0 
        ? `Update Stok ${row.mutasi[0].jenis_transaksi === 'masuk' ? 'Masuk' : 'Keluar'} Dari Sebelum ${row.mutasi[0].stok_sebelum} menjadi ${row.mutasi[0].stok_setelah}`
        : 'N/A',
      sortable: false,
      visible: true,
      wrap: true
    },
    { 
        name: 'Catatan', 
        selector: row => row.catatan, // Add this line
        sortable: false, 
        visible: false, 
        wrap: true 
      },
    { 
      name: 'Actions', 
      cell: row => (
        <div>
          <FaTrash style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setCurrentStok(row); toggleDeleteModal(); }} />
        </div>
      ), 
      sortable: false, 
      center: true, 
      grow: 0.1, 
      visible: true 
    },
  ]);

  // Function to fetch stok data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getStokGudang();
        //console.log("Fetched data:", response); 

        if (response && !Array.isArray(response)) {
          setStokGudang([response]);
          setFilteredStokGudang([response]);
        } else if (Array.isArray(response)) {
          setStokGudang(response);
          setFilteredStokGudang(response);
        } else {
          //throw new Error('Fetched data is not valid');
        }
      } catch (error) {
        //console.error('Error fetching stok:', error);
        //toast.error('Failed to fetch stok data. Please try again.');
      }
    };

    fetchData();
  }, []);

  // Function to handle deleting a stok entry
  const handleDeleteStok = async () => {
    try {
      await deleteStokGudang(currentStok.id);
      setStokGudang(stokGudang.filter(tx => tx.id !== currentStok.id));
      setFilteredStokGudang(filteredStokGudang.filter(tx => tx.id !== currentStok.id));
      toast.success('Stok entry deleted successfully');
      toggleDeleteModal();
    } catch (error) {
      console.error('Failed to delete stok entry:', error);
      toast.error('Failed to delete stok entry. Please try again.');
    }
  };

  // Function to toggle delete confirmation modal
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  // Function to toggle column configuration modal
  const toggleConfigModal = () => setConfigModal(!configModal);

  // Function to toggle image modal
  const toggleImageModal = () => setImageModalOpen(!imageModalOpen);

  // Function to handle column configuration change
  const handleConfigChange = (updatedColumns) => {
    setColumns(updatedColumns);
  };

  // Function to handle search and filter
  const handleSearch = (e) => {
    const text = e.target.value;
    setSearchText(text);
    applyFilters(text, filterType, startDate, endDate);
  };

  const handleFilterTypeChange = (e) => {
    const type = e.target.value;
    setFilterType(type);
    applyFilters(searchText, type, startDate, endDate);
  };

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    applyFilters(searchText, filterType, start, end);
  };

  const applyFilters = (text, type, start, end) => {
    const filtered = stokGudang.filter(item => {
      const matchesSearchText = item.inventory.name.toLowerCase().includes(text.toLowerCase());
      const matchesFilterType = type ? item.jenis_transaksi === type : true;
      const matchesDateRange = start && end ? moment(item.tanggal).isBetween(start, end, 'day', '[]') : true;
      return matchesSearchText && matchesFilterType && matchesDateRange;
    });
    setFilteredStokGudang(filtered);
  };

  const visibleColumns = columns.filter(column => column.visible);

  return (
    <Container fluid={true}>
      <ToastContainer />
      <Row className="mb-3">
        <Col md={3}>
          <Button color="success" onClick={handleCreate}>Tambah/Kurang Stok</Button>
        </Col>
        <Col md={9}>
          <Row>
            <Col md={3}>
              <Input 
                type="text" 
                placeholder="Search by product name..." 
                value={searchText}
                onChange={handleSearch}
                style={{ width: '100%' }}
              />
            </Col>
            <Col md={3}>
              <DatePicker
                selected={startDate}
                onChange={handleDateRangeChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                isClearable
                placeholderText="Select date range"
                className="form-control"
              />
            </Col>
            <Col md={3}>
              <Input 
                type="select"
                value={filterType}
                onChange={handleFilterTypeChange}
                style={{ width: '100%' }}
              >
                <option value="">All Transactions</option>
                <option value="masuk">Masuk</option>
                <option value="keluar">Keluar</option>
              </Input>
            </Col>
            
            <Col md={3} className="d-flex justify-content-end">
              <Button color="primary" onClick={toggleConfigModal} style={{ width: '100%' }}>Configure Columns</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='table-responsive table-bordernone orderHistoryTable'>
        <DataTable
          pagination
          columns={visibleColumns}
          data={filteredStokGudang}
          highlightOnHover
          striped
          responsive
        />
      </div>
      <DeleteConfirmationModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        onConfirm={handleDeleteStok}
        itemName={currentStok ? currentStok.inventory.name : ''}
      />
      <ColumnConfigModal
        isOpen={configModal}
        toggle={toggleConfigModal}
        columns={columns}
        onSave={handleConfigChange}
      />
      <ImageModal 
        isOpen={imageModalOpen} 
        toggle={toggleImageModal} 
        imageUrl={imageUrl} 
      />
      <style jsx>{`
        .table-responsive table th,
        .table-responsive table td {
          text-align: center;
          vertical-align: middle;
        }
      `}</style>
    </Container>
  );
};

export default StokTable;
