import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from '../Utils/cookies';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = getCookie('token'); // Assuming the token is stored in a cookie named 'token'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility function to get user_id from token
const getUserIdFromToken = () => {
  const token = getCookie('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  }
  return null;
};

// API calls for MenuItem
export const getMenuItems = async () => {
  try {
    const response = await api.get('/menu');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch menu items:', error);
    throw error;
  }
};

// Get menus by user ID (from token)
export const getMenusByUserId = async () => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error('User ID not found');
  
      const response = await api.get(`/usermenu/users/${userId}/menus`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch menus by user ID:', error);
      throw error;
    }
  };
export const createMenuItem = async (menuItemData) => {
  try {
    const response = await api.post('/menu', menuItemData);
    return response.data;
  } catch (error) {
    console.error('Failed to create menu item:', error);
    throw error;
  }
};

export const updateMenuItem = async (id, updatedMenuItem) => {
  try {
    const response = await api.put(`/menu/${id}`, updatedMenuItem, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update menu item:', error);
    throw error;
  }
};

export const deleteMenuItem = async (id) => {
  try {
    const response = await api.delete(`/menu/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete menu item:', error);
    throw error;
  }
};

// API calls for MenuChild
export const getMenuChildren = async (menuItemId) => {
  try {
    const response = await api.get(`/menu/${menuItemId}/children`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch menu children:', error);
    throw error;
  }
};

export const createMenuChild = async (menuItemId, menuChildData) => {
  try {
    const response = await api.post(`/menu/${menuItemId}/children`, menuChildData);
    return response.data;
  } catch (error) {
    console.error('Failed to create menu child:', error);
    throw error;
  }
};

export const updateMenuChild = async (menuItemId, childId, updatedMenuChild) => {
  try {
    const response = await api.put(`/menu/${menuItemId}/children/${childId}`, updatedMenuChild, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update menu child:', error);
    throw error;
  }
};

export const deleteMenuChild = async (menuItemId, childId) => {
  try {
    const response = await api.delete(`/menu/${menuItemId}/children/${childId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete menu child:', error);
    throw error;
  }
};



export const getMenuChild = async () => {
  try {
    const response = await api.get(`/menu/all`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch menu children:', error);
    throw error;
  }
};

export default api;
