import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { uploadImage, createInventory } from '../../Rest/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ManageInventory = () => {
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [formData, setFormData] = useState({
    product_category_id: '',
    sku: '',
    name: '',
    hpp: '',
    product_photo: '',
    quantity: '',
    description: '',
    location_id: '',
    selling_price: '',
    inventory_value: '',
    user_id: 1, // Assuming the user ID is statically set for this example
    active: true
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch categories
    axios.get('http://localhost:5000/api/productcategories').then(response => {
      setCategories(response.data);
    }).catch(error => {
      console.error('Error fetching categories:', error);
    });

    // Fetch warehouses
    axios.get('http://localhost:5000/api/locations').then(response => {
      setWarehouses(response.data);
    }).catch(error => {
      console.error('Error fetching locations:', error);
    });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let productPhotoUrl = formData.product_photo;

      if (file) {
        const uploadResponse = await uploadImage(formData.user_id, formData.description, file);
        productPhotoUrl = uploadResponse.photoUrl;
      }

      const inventoryValue = formData.hpp * formData.quantity;

      const inventoryData = {
        ...formData,
        product_photo: productPhotoUrl,
        inventory_value: inventoryValue
      };

      const response = await createInventory(inventoryData);
      console.log('Inventory created successfully:', response);
    } catch (error) {
      console.error('Error creating inventory:', error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`Failed to save inventory: ${error.response.data.error}`);
      } else {
        toast.error('Failed to save inventory. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1
  });

  useEffect(() => () => {
    if (file) {
      URL.revokeObjectURL(file.preview);
    }
  }, [file]);

  const removeFile = () => {
    setFile(null);
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Manage Inventory" parent="Dashboard" title="Inventory" />
      <Container fluid={true} className="general-widget">
        <ToastContainer />
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="product_photo">Product Photo</Label>
                <Card style={{ width: '100%', height: '340px', position: 'relative' }}>
                  <CardBody style={{ padding: 0 }}>
                    {!file && (
                      <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop an image here, or click to select an image</p>
                      </div>
                    )}
                    {file && (
                      <div style={{ textAlign: 'center', height: '100%', position: 'relative' }}>
                        <img src={file.preview} alt={file.name} style={{ width: '340px', height: '340px', objectFit: 'cover' }} />
                        <Button
                          color="danger"
                          size="sm"
                          onClick={removeFile}
                          style={{ position: 'absolute', top: '10px', right: '10px' }}
                        >
                          &times;
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </FormGroup>
              <FormGroup>
                <Label for="product_category_id">Category</Label>
                <Input type="select" name="product_category_id" id="product_category_id" value={formData.product_category_id} onChange={handleChange}>
                  <option>Select Category</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" name="name" id="name" value={formData.name} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="sku">SKU</Label>
                <Input type="text" name="sku" id="sku" value={formData.sku} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input type="number" name="quantity" id="quantity" value={formData.quantity} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="hpp">HPP</Label>
                <Input type="number" name="hpp" id="hpp" value={formData.hpp} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="selling_price">Selling Price</Label>
                <Input type="number" name="selling_price" id="selling_price" value={formData.selling_price} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="location_id">Location</Label>
                <Input type="select" name="location_id" id="location_id" value={formData.location_id} onChange={handleChange}>
                  <option>Select Location</option>
                  {warehouses.map(warehouse => (
                    <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input type="textarea" name="description" id="description" value={formData.description} onChange={handleChange} />
          </FormGroup>
          <Button type="submit" color="primary" disabled={loading}>
            {loading ? <Spinner size="sm" /> : 'Save Inventory Item'}
          </Button>
        </Form>
      </Container>
    </Fragment>
  );
};

export default ManageInventory;
