import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Card, CardBody } from 'reactstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Breadcrumbs } from '../../../AbstractElements';
import { getAllLocations, getProduk, uploadImage, createTransaction } from '../../../Rest/api-inventori';
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const AddInventoryForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    kategori: '',
    gudang: '',
    produk: '',
    varian: '',
    jumlah: '',
    waktu: getCurrentDate(),
    catatan: '',
    buktiFoto: null
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state for image upload and form submission
  const [gudangOptions, setGudangOptions] = useState([]);
  const [produkOptions, setProdukOptions] = useState([]);
  const [variantOptions, setVariantOptions] = useState([]);

  const kategoriOptions = [
    { value: 'Inventori Baru', label: 'Inventori Baru' },
    { value: 'Penyesuaian Manual', label: 'Penyesuaian Manual' },
    { value: 'Konversi Produk', label: 'Konversi Produk' },
    { value: 'Perpindahan Antar-Gudang', label: 'Perpindahan Antar-Gudang' },
    { value: 'Lainnya', label: 'Lainnya' }
  ];

  useEffect(() => {
    const fetchGudangData = async () => {
      try {
        const locations = await getAllLocations();
        const options = locations.map(location => ({
          value: location.id,
          label: location.name
        }));
        setGudangOptions(options);
      } catch (error) {
        toast.error('Error fetching Gudang data'); // Show toast on error
        console.error('Error fetching Gudang data:', error);
      }
    };
    fetchGudangData();
  }, []);

  useEffect(() => {
    const fetchProdukData = async () => {
      try {
        const products = await getProduk();
        const options = products.map(product => ({
          value: product.id,
          label: product.name,
          variants: product.variants
        }));
        setProdukOptions(options);
      } catch (error) {
        toast.error('Error fetching Produk data'); // Show toast on error
        console.error('Error fetching Produk data:', error);
      }
    };
    fetchProdukData();
  }, []);

  const handleChange = (field, value) => {
    if (field === 'produk') {
      setFormData({
        ...formData,
        produk: value,
        varian: ''
      });
      setVariantOptions([]);
      const selectedProduct = produkOptions.find(product => product.value === value);
      if (selectedProduct) {
        const options = selectedProduct.variants.map(variant => ({
          value: variant.id,
          label: variant.option1_value
        }));
        setVariantOptions(options);
        if (options.length === 1) {
          setFormData({
            ...formData,
            produk: value,
            varian: options[0].value
          });
        }
      }
    } else {
      setFormData({
        ...formData,
        [field]: value
      });
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*'
  });

  const removeFile = () => {
    setFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let uploadedImageUrl = null;

    try {
      // If there's an image file, upload it first
      if (file) {
        const uploadResult = await uploadImage(formData.id_user, 'Inventory Image', file);
        uploadedImageUrl = uploadResult.url;
      }

      const transactionData = {
        ...formData,
        buktiFoto: uploadedImageUrl || null
      };

      const response = await createTransaction(transactionData);
      toast.success('Transaction created successfully!'); // Show success toast

      setTimeout(() => {
        const currentURL = window.location.href;
        const urlParts = currentURL.split('/');
        const layoutURL = urlParts[urlParts.length - 1];
        navigate(`${process.env.PUBLIC_URL}/inventory/${layoutURL}`); // Navigate after success
      }, 2000); // Add a small delay to allow toast to display before navigation
    } catch (error) {
      toast.error('Error creating transaction'); // Show error toast
      console.error('Error creating transaction:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid={true}>
      <Breadcrumbs mainTitle="Tambah Inventory" parent="Management" title="Tambah Inventory" />

      <Row>
        <Col md="8" className="mx-auto">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="kategori">Kategori *</Label>
                  <Select
                    options={kategoriOptions}
                    onChange={(selectedOption) => handleChange('kategori', selectedOption.value)}
                    placeholder="Pilih Kategori"
                    isSearchable
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="gudang">Gudang *</Label>
                  <Select
                    options={gudangOptions}  // Options are dynamically loaded from API
                    onChange={(selectedOption) => handleChange('gudang', selectedOption.value)}
                    placeholder="Pilih Gudang"
                    isSearchable
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="produk">Produk *</Label>
                  <Select
                    options={produkOptions}  // Options are dynamically loaded from API
                    onChange={(selectedOption) => handleChange('produk', selectedOption.value)}
                    placeholder="Pilih Produk"
                    isSearchable
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="varian">Varian *</Label>
                  <Select
                    options={variantOptions}  // Options dynamically change based on selected product
                    onChange={(selectedOption) => handleChange('varian', selectedOption.value)}
                    placeholder="Pilih Varian"
                    isSearchable
                    required
                    isDisabled={!variantOptions.length} // Disable if no variants available
                    value={variantOptions.find(opt => opt.value === formData.varian) || null} // Keep variant in sync with the form data
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="jumlah">Jumlah *</Label>
                  <Input
                    type="number"
                    name="jumlah"
                    placeholder="Contoh: 100"
                    value={formData.jumlah}
                    onChange={(e) => handleChange('jumlah', e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="waktu">Waktu Inventori Ditambahkan *</Label>
                  <Input
                    type="date"
                    name="waktu"
                    value={formData.waktu}
                    onChange={(e) => handleChange('waktu', e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* Image Upload Section */}
            <FormGroup>
              <Label for="buktiFoto">Bukti Foto</Label>
              <Card style={{ width: '100%', height: '200px', position: 'relative' }}>
                <CardBody style={{ padding: 0 }}>
                  {!file && (
                    <div
                      {...getRootProps({ className: 'dropzone' })}
                      style={{
                        border: '2px dashed #007bff',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}
                    >
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop an image here, or click to select an image</p>
                    </div>
                  )}
                  {file && (
                    <div style={{ textAlign: 'center', height: '100%', position: 'relative' }}>
                      <img 
                        src={file.preview} 
                        alt={file.name} 
                        style={{ 
                          maxWidth: '100%', 
                          height: 'auto', 
                          maxHeight: '200px', 
                          objectFit: 'contain' 
                        }} 
                      />
                      <Button
                        color="danger"
                        size="sm"
                        onClick={removeFile}
                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                      >
                        &times;
                      </Button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </FormGroup>

            {/* Add notes or catatan section here */}
            <FormGroup>
              <Label for="catatan">Catatan</Label>
              <Input
                type="textarea"
                name="catatan"
                id="catatan"
                placeholder="Tambahkan catatan jika diperlukan"
                value={formData.catatan}
                onChange={(e) => handleChange('catatan', e.target.value)}
              />
            </FormGroup>

            {/* Submit Button */}
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? 'Processing...' : 'Tambah Inventori'}
            </Button>
          </Form>
        </Col>
      </Row>

      {/* Toast container */}
      <ToastContainer />
    </Container>
  );
};

export default AddInventoryForm;
