import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from 'reactstrap';
import AsyncSelect from 'react-select/async'; // Impor AsyncSelect untuk kecamatan
import { Breadcrumbs } from '../../../AbstractElements';
import SidebarMenu from './../SidebarMenu';
import { useNavigate } from 'react-router-dom';
import './WarehouseDetailView.css';
import { getDestination, createLocation } from '../../../Rest/api-warehouse';
import { toast } from 'react-toastify';

const AddWarehouse = () => {
  const [warehouse, setWarehouse] = useState({
    name: '',
    address: '',
    district: '', // memastikan bahwa district adalah bagian dari state
  });
  const [loading, setLoading] = useState(false); // State untuk loader
  const [destinations, setDestinations] = useState([]); // State untuk data destinasi
  const [selectedDestination, setSelectedDestination] = useState(null);
  const navigate = useNavigate(); // Hook untuk navigasi

  useEffect(() => {
    // Mendapatkan data destinasi dari API
    getDestination()
      .then((data) => {
        setDestinations(data); // Simpan data destinasi untuk digunakan di AsyncSelect
        console.log('Data destinasi:', data);
      })
      .catch((error) => {
        toast.error('Gagal mendapatkan destinasi!');
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWarehouse((prevWarehouse) => ({
      ...prevWarehouse,
      [name]: value,
    }));
  };

  const handleAddressChange = (selectedOption) => {
    setSelectedDestination(selectedOption);
    setWarehouse((prevWarehouse) => ({
      ...prevWarehouse,
      district: selectedOption ? selectedOption.label : '',
    }));
  };

  const validateForm = () => {
    const { name, address, district } = warehouse;
    if (!name || !address || !district) {
      toast.error('Semua kolom wajib diisi!');
      return false;
    }
    return true;
  };

  const handleSaveClick = async (e) => {
    e.preventDefault(); // Mencegah reload halaman

    if (!validateForm()) return; // Validasi form sebelum mengirim

    setLoading(true); // Tampilkan loader saat simpan

    // Menggabungkan `district` dengan `address`
    const fullAddress = `${warehouse.address}, ${warehouse.district}`;

    console.log('Data yang dikirim:', { ...warehouse, address: fullAddress }); // Debugging untuk melihat data yang akan dikirim

    try {
      await createLocation({ ...warehouse, alamat: fullAddress }); // Fungsi API untuk membuat lokasi baru
      toast.success('Gudang berhasil ditambahkan!');

      // Ekstrak layout dari URL dan gunakan untuk navigasi
      const currentURL = window.location.href;
      const urlParts = currentURL.split('/');
      const layoutURL = urlParts[urlParts.length - 1];

      navigate(`${process.env.PUBLIC_URL}/setting/warehouse/${layoutURL}`); // Pindah ke halaman "Gudang" dengan layout
    } catch (error) {
      console.error('API Error:', error); // Tambahkan log error untuk debugging
      toast.error('Gagal menambahkan gudang!'); // Notifikasi jika terjadi kesalahan
    } finally {
      setLoading(false); // Sembunyikan loader
    }
  };

  const filterDestinations = (inputValue) => {
    return destinations
      .filter((destination) =>
        destination.province?.toLowerCase().includes(inputValue.toLowerCase()) ||
        destination.city?.toLowerCase().includes(inputValue.toLowerCase()) ||
        destination.subdistrict?.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((destination) => ({
        value: destination.id,
        label: `${destination.province} - ${destination.city} - ${destination.subdistrict}`,
        ...destination,
      }));
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterDestinations(inputValue));
    }, 1000);
  };

  return (
    <Row>
      <Col sm="12">
        <Breadcrumbs
          mainTitle="Tambah Gudang"
          parent="Gudang"
          title="Tambah Gudang"
        />
      </Col>

      <Col sm="3">
        <SidebarMenu />
      </Col>

      <Col sm="9">
        <Card className="warehouse-detail-card">
          <CardBody>
            <Form onSubmit={handleSaveClick}>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4>Informasi Gudang</h4>
                <Button type="submit" color="primary" disabled={loading}>
                  {loading ? <Spinner size="sm" color="light" /> : 'Simpan'}
                </Button>
              </div>

              {/* Warehouse Detail Form */}
              <FormGroup>
                <Label for="name">Nama Gudang <span className="text-danger">*</span></Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Tulis Nama Gudang..."
                  value={warehouse.name}
                  onChange={handleInputChange}
                  style={{ background: '#ffffff' }}
                />
              </FormGroup>

              <FormGroup>
                <Label for="address">Alamat <span className="text-danger">*</span></Label>
                <Input
                  type="textarea"
                  name="address"
                  id="address"
                  placeholder="Tulis alamat lengkap"
                  value={warehouse.address}
                  onChange={handleInputChange}
                  style={{ background: '#ffffff' }}
                  rows="3"
                />
              </FormGroup>

              {/* Kecamatan dengan AsyncSelect */}
              <FormGroup>
                <Label for="district">Kecamatan <span className="text-danger">*</span></Label>
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions={false}
                  value={selectedDestination}
                  onChange={handleAddressChange}
                  isClearable
                  noOptionsMessage={() => 'ketik kec atau kota'}
                />
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AddWarehouse;
