import React, { useState, useEffect } from 'react';
import { Container, Button } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { getBanks, deleteBank, addBank, updateBank } from '../../../Rest/api-keuangan'; // Ensure correct import for bank API functions
import DeleteConfirmationModal from './DeleteConfirmationModal'; // Adjust as per your project structure
import AddBankModal from './AddBankModal'; // Import the AddBankModal component
import EditBankModal from './EditBankModal'; // Import the EditBankModal component
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';

const BankTable = () => {
  const [banks, setBanks] = useState([]);
  const [filteredBanks, setFilteredBanks] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentBank, setCurrentBank] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBanks();
        if (response.length === 0) {
          console.log('No banks found');
          setBanks([]);
          setFilteredBanks([]);
        } else {
          setBanks(response);
          setFilteredBanks(response);
        }
      } catch (error) {
        console.error('Error fetching banks:', error);
        if (error.message !== "Banks tidak ditemukan" && error.message !== "No banks found") {
          //toast.error('Failed to fetch bank data. Please try again.');
        }
      }
    };
  
    fetchData();
  }, []);

  const handleDelete = async () => {
    try {
      await deleteBank(currentBank.id); // Adjust as per your API function
      setBanks(banks.filter(bank => bank.id !== currentBank.id));
      setFilteredBanks(filteredBanks.filter(bank => bank.id !== currentBank.id));
      toast.success('Bank deleted successfully');
      toggleDeleteModal();
    } catch (error) {
      console.error('Failed to delete bank:', error);
      toast.error('Failed to delete bank. Please try again.');
    }
  };

  const handleAddBank = async (newBank) => {
    try {
      const response = await addBank(newBank); // Adjust as per your API function
      setBanks([...banks, response]);
      setFilteredBanks([...filteredBanks, response]);
      toast.success('Bank added successfully');
    } catch (error) {
      console.error('Failed to add bank:', error);
      toast.error('Failed to add bank. Please try again.');
    }
  };

  const handleEditBank = async (updatedBank) => {
    try {
      const response = await updateBank(updatedBank.id, updatedBank); // Adjust as per your API function
      setBanks(banks.map(bank => bank.id === updatedBank.id ? response : bank));
      setFilteredBanks(filteredBanks.map(bank => bank.id === updatedBank.id ? response : bank));
      toast.success('Bank updated successfully');
    } catch (error) {
      console.error('Failed to update bank:', error);
      toast.error('Failed to update bank. Please try again.');
    }
  };

  const columns = [
    { name: 'No', selector: (row, index) => index + 1, sortable: false, center: true, grow: 0.1 },
    { name: 'Nama Bank', selector: row => row.nama_bank, sortable: true, center: true },
    { name: 'Nomor Rekening', selector: row => row.nomor_rekening, sortable: true, center: true },
    { name: 'Pemilik Rekening', selector: row => row.pemilik_rekening, sortable: true, center: true },
    { name: 'Saldo', selector: row => row.saldo, sortable: true, center: true },
    {
      name: 'Actions',
      cell: row => (
        <div>
          <FaEdit style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => { setCurrentBank(row); toggleEditModal(); }} />
          <FaTrash style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setCurrentBank(row); toggleDeleteModal(); }} />
        </div>
      ),
      sortable: false,
      center: true,
      grow: 0.1,
    },
  ];

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleAddModal = () => setAddModal(!addModal);
  const toggleEditModal = () => setEditModal(!editModal);

  return (
    <Container fluid={true}>
      <div className="d-flex justify-content-end mb-2">
        <Button color="primary" onClick={toggleAddModal} className="add-bank-button">
          <FaPlus /> Add Bank
        </Button>
      </div>
      <div className='table-responsive'>
        <DataTable
          columns={columns}
          data={filteredBanks}
          pagination
          highlightOnHover
          striped
          responsive
        />
      </div>
      <DeleteConfirmationModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        onConfirm={handleDelete}
        itemName={currentBank ? currentBank.nama_bank : ''}
      />
      <AddBankModal
        isOpen={addModal}
        toggle={toggleAddModal}
        onAdd={handleAddBank}
      />
      <EditBankModal
        isOpen={editModal}
        toggle={toggleEditModal}
        onEdit={handleEditBank}
        currentBank={currentBank}
      />
      <style jsx>{`
        .table-responsive table th,
        .table-responsive table td {
          text-align: center;
          vertical-align: middle;
        }
      `}</style>
    </Container>
  );
};

export default BankTable;
