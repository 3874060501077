import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import SidebarMenu from './SidebarMenu';
import { getMenuChild, createMenuItem, createMenuChild, deleteMenuItem, deleteMenuChild, updateMenuItem, updateMenuChild } from '../../Rest/api-menu'; // Adjusted import
import { FaEdit, FaTrashAlt, FaChevronDown, FaChevronRight, FaPlus } from 'react-icons/fa'; // Icons for edit, delete, and collapse

import './menulist.css';

const Menu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [newMenuItem, setNewMenuItem] = useState({
    title: '',
    icon: '',
    type: 'sub', // Default to 'sub'
    badge: '',
    badgetxt: '',
    menu_order: 1,
    path: '', // Add path to the state
  });
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [expandedMenuIds, setExpandedMenuIds] = useState([]); // Track expanded menus

  const [isChildModalOpen, setChildModalOpen] = useState(false); // Modal for adding child menu
  const [isEditModalOpen, setEditModalOpen] = useState(false); // Modal for editing menu item
  const [isEditChildModalOpen, setEditChildModalOpen] = useState(false); // Modal for editing sub-menu item

  const [childFormData, setChildFormData] = useState({
    title: '',
    type: 'link',
    path: '',
    child_order: 1, // Use child_order for sub-menus
  });
  const [editMenuData, setEditMenuData] = useState({
    title: '',
    icon: '',
    type: 'link',
    badge: '',
    badgetxt: '',
    menu_order: 1,
    path: '',
  });

  const [selectedMenuItem, setSelectedMenuItem] = useState(null); // Track the selected menu item for editing
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState(null); // Track the selected sub-menu item for editing

  const [parentMenuId, setParentMenuId] = useState(null); // Track the parent ID for the child menu

  // Fetch menu items when the component is mounted
  useEffect(() => {
    const fetchMenuItems = async () => {
      const response = await getMenuChild(); // Fetch menu items from the API
      setMenuItems(response); // assuming response contains menu data
    };
    fetchMenuItems();
  }, []);

  const toggleAddModal = () => {
    setAddModalOpen(!isAddModalOpen);
  };

  const toggleChildModal = (parentId) => {
    setParentMenuId(parentId); // Set parent ID for the child menu
    setChildModalOpen(!isChildModalOpen);
  };

  const toggleEditModal = (menuItem) => {
    setSelectedMenuItem(menuItem); // Set the selected menu item for editing
    setEditMenuData(menuItem); // Populate the modal form with existing data
    setEditModalOpen(!isEditModalOpen);
  };

  const toggleEditChildModal = (childItem, parentId) => {
    setSelectedSubMenuItem(childItem); // Set the selected sub-menu item for editing
    setChildFormData(childItem); // Populate the modal form with existing sub-menu data
    setParentMenuId(parentId); // Set the parent menu ID correctly when opening the modal
    setEditChildModalOpen(!isEditChildModalOpen);
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMenuItem({ ...newMenuItem, [name]: value });
  };

  const handleChildInputChange = (e) => {
    const { name, value } = e.target;
    setChildFormData({ ...childFormData, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditMenuData({ ...editMenuData, [name]: value });
  };

  const handleAddMenu = async (e) => {
    e.preventDefault(); // Prevent form submission reload
    await createMenuItem(newMenuItem); // Call API to create new menu item
    setAddModalOpen(false);
    const response = await getMenuChild(); // Refetch menu items after adding
    setMenuItems(response);
    // Reset form data
    setNewMenuItem({
      title: '',
      icon: '',
      type: 'sub', // Reset to default
      badge: '',
      badgetxt: '',
      menu_order: 1,
      path: '', // Reset path
    });
  };

  const handleAddChildMenu = async (e) => {
    e.preventDefault(); // Prevent form submission reload
    if (parentMenuId) {
      await createMenuChild(parentMenuId, childFormData); // Call API to create new child menu
      setChildModalOpen(false);
      const response = await getMenuChild(); // Refetch menu items after adding
      setMenuItems(response);
      // Reset form data
      setChildFormData({
        title: '',
        type: 'link',
        path: '',
        child_order: 1,
      });
    }
  };

  const handleEditMenu = async (e) => {
    e.preventDefault(); // Prevent form submission reload
    await updateMenuItem(selectedMenuItem.id, editMenuData); // Call API to update menu item
    setEditModalOpen(false);
    const response = await getMenuChild(); // Refetch menu items after updating
    setMenuItems(response);
  };

  const handleEditSubMenu = async (e) => {
    e.preventDefault(); // Prevent form submission reload

    // Check if parentMenuId is properly set
    if (!parentMenuId || !selectedSubMenuItem?.id) {
        console.error('Parent menu ID or sub-menu ID is missing.');
        return;
    }

    try {
        // Pass both parentMenuId (menuItemId) and selectedSubMenuItem.id (childId)
        await updateMenuChild(parentMenuId, selectedSubMenuItem.id, {
            title: childFormData.title,
            type: childFormData.type,
            path: childFormData.path,
            child_order: childFormData.child_order,
        }); // Call API to update sub-menu item
        
        setEditChildModalOpen(false);
        const response = await getMenuChild(); // Refetch menu items after updating
        setMenuItems(response);
    } catch (error) {
        console.error('Failed to update sub-menu:', error);
    }
};



  const handleDeleteMenu = async (menuId) => {
    await deleteMenuItem(menuId); // Call API to delete menu
    const response = await getMenuChild(); // Refetch menu items after deletion
    setMenuItems(response);
  };

  const handleDeleteChild = async (childId) => {
    await deleteMenuChild(parentMenuId, childId); // Call API to delete child menu
    const response = await getMenuChild(); // Refetch menu items after deletion
    setMenuItems(response);
  };

  const toggleExpand = (id) => {
    setExpandedMenuIds((prev) =>
      prev.includes(id) ? prev.filter((menuId) => menuId !== id) : [...prev, id]
    );
  };

  return (
    <Row>
      {/* Breadcrumbs */}
      <Col sm="12">
        <Breadcrumbs mainTitle="Pengaturan Menu" parent="Settings" title="Menu" />
      </Col>
  
      {/* Sidebar */}
      <Col sm="3">
        <SidebarMenu />
      </Col>
  
      {/* Main Content */}
      <Col sm="9" style={{ position: 'relative' }}>
        <Card>
          <CardBody>
            <h4>Pengaturan Menu</h4>
            <Button color="primary" className="add-menu-btn" onClick={toggleAddModal}>
              Tambah Menu
            </Button>
            <ul className="menu-list">
              {menuItems.map((item) => (
                <li key={item.id} className="menu-item">
                  <div className="menu-item-header">
                    <Button
                      className="menu-toggle-btn"
                      color="link"
                      onClick={() => toggleExpand(item.id)}
                    >
                      {expandedMenuIds.includes(item.id) ? <FaChevronDown /> : <FaChevronRight />}
                    </Button>
                    {/* Display icon, title, type, and menu_order */}
                    <i className={`menu-icon ${item.icon}`}></i>
                    <strong>{item.title}</strong>
                    <span className="menu-type">[{item.type}]</span>
                    <span className="menu-order">Order: {item.menu_order}</span>
                    <div className="menu-actions">
                      {item.type === 'sub' && (
                        <Button color="success" size="sm" onClick={() => toggleChildModal(item.id)}>
                          <FaPlus /> Sub Menu
                        </Button>
                      )}
                      <FaEdit className="menu-edit" onClick={() => toggleEditModal(item)} />
                      <FaTrashAlt className="menu-delete" onClick={() => handleDeleteMenu(item.id)} />
                    </div>
                  </div>
                  {expandedMenuIds.includes(item.id) && (
                    <ul className="menu-children">
                      {item.childrenItems.map((child) => (
                        <li key={child.id} className="submenu-item">
                          <i className="menu-child-icon"></i> {child.title} (Order: {child.child_order}) {/* Use child_order for sub-menus */}
                          <div className="menu-actions">
                            <FaEdit className="menu-edit" onClick={() => toggleEditChildModal(child, item.id)} />
                            <FaTrashAlt className="menu-delete" onClick={() => handleDeleteChild(child.id)} />
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </CardBody>
        </Card>
      </Col>
  
      {/* Add Menu Modal */}
      <Modal isOpen={isAddModalOpen} toggle={toggleAddModal}>
        <ModalHeader toggle={toggleAddModal}>Tambah Menu</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleAddMenu}>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input type="text" name="title" id="title" value={newMenuItem.title} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="icon">Icon</Label>
              <Input type="text" name="icon" id="icon" value={newMenuItem.icon} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="type">Type</Label>
              <Input type="select" name="type" id="type" value={newMenuItem.type} onChange={handleInputChange}>
                <option value="sub">Sub</option>
                <option value="link">Link</option>
              </Input>
            </FormGroup>
            {newMenuItem.type === 'link' && (
              <FormGroup>
                <Label for="path">Path</Label>
                <Input type="text" name="path" id="path" value={newMenuItem.path} onChange={handleInputChange} required />
              </FormGroup>
            )}
            <FormGroup>
              <Label for="badge">Badge</Label>
              <Input type="text" name="badge" id="badge" value={newMenuItem.badge} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="badgetxt">Badge Text</Label>
              <Input type="text" name="badgetxt" id="badgetxt" value={newMenuItem.badgetxt} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="menu_order">Order</Label>
              <Input type="number" name="menu_order" id="menu_order" value={newMenuItem.menu_order} onChange={handleInputChange} required />
            </FormGroup>
            <Button type="submit" color="primary">Save Menu Item</Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleAddModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
  

      {/* Edit Menu Modal */}
      <Modal isOpen={isEditModalOpen} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit Menu</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleEditMenu}>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input type="text" name="title" id="title" value={editMenuData.title} onChange={handleEditInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="icon">Icon</Label>
              <Input type="text" name="icon" id="icon" value={editMenuData.icon} onChange={handleEditInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="type">Type</Label>
              <Input type="select" name="type" id="type" value={editMenuData.type} onChange={handleEditInputChange}>
                <option value="sub">Sub</option>
                <option value="link">Link</option>
              </Input>
            </FormGroup>
            {editMenuData.type === 'link' && ( // Conditionally render the path field if type is 'link'
              <FormGroup>
                <Label for="path">Path</Label>
                <Input type="text" name="path" id="path" value={editMenuData.path} onChange={handleEditInputChange} required />
              </FormGroup>
            )}
            <FormGroup>
              <Label for="badge">Badge</Label>
              <Input type="text" name="badge" id="badge" value={editMenuData.badge} onChange={handleEditInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="badgetxt">Badge Text</Label>
              <Input type="text" name="badgetxt" id="badgetxt" value={editMenuData.badgetxt} onChange={handleEditInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="menu_order">Order</Label>
              <Input type="number" name="menu_order" id="menu_order" value={editMenuData.menu_order} onChange={handleEditInputChange} required />
            </FormGroup>
            <Button type="submit" color="primary">Save Changes</Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setEditModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Edit Sub-Menu Modal */}
      <Modal isOpen={isEditChildModalOpen} toggle={toggleEditChildModal}>
        <ModalHeader toggle={toggleEditChildModal}>Edit Sub-Menu</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleEditSubMenu}>
            <FormGroup>
              <Label for="child_title">Title</Label>
              <Input type="text" name="title" id="child_title" value={childFormData.title} onChange={handleChildInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="child_type">Type</Label>
              <Input type="select" name="type" id="child_type" value={childFormData.type} onChange={handleChildInputChange}>
                <option value="link">Link</option>
                <option value="sub">Sub</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="path">Path</Label>
              <Input type="text" name="path" id="path" value={childFormData.path} onChange={handleChildInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="child_order">Order</Label>
              <Input type="number" name="child_order" id="child_order" value={childFormData.child_order} onChange={handleChildInputChange} required />
            </FormGroup>
            <Button type="submit" color="primary">Save Sub-Menu</Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setEditChildModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Add Child Menu Modal */}
      <Modal isOpen={isChildModalOpen} toggle={toggleChildModal}>
        <ModalHeader toggle={toggleChildModal}>Tambah Sub-Menu</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleAddChildMenu}>
            <FormGroup>
              <Label for="child_title">Title</Label>
              <Input type="text" name="title" id="child_title" value={childFormData.title} onChange={handleChildInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="child_type">Type</Label>
              <Input type="select" name="type" id="child_type" value={childFormData.type} onChange={handleChildInputChange}>
                <option value="link">Link</option>
                <option value="sub">Sub</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="path">Path</Label>
              <Input type="text" name="path" id="path" value={childFormData.path} onChange={handleChildInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="child_order">Order</Label>
              <Input type="number" name="child_order" id="child_order" value={childFormData.child_order} onChange={handleChildInputChange} required />
            </FormGroup>
            <Button type="submit" color="primary">Save Sub-Menu</Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setChildModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default Menu;
